import i18n from '../../../i18n';
import { runStaticQuery } from '../../query/run.staticQuery';


export const translationReload = async (params: any) => {

  runStaticQuery('translation', { user: params.user })
    .then((response: any) => {
      localStorage.setItem('translations', JSON.stringify(response.translations));
      const lang = localStorage.getItem('i18nextLng');

      if (lang === 'lng1') {
        i18n.changeLanguage('lng2');
      } else {
        i18n.changeLanguage('lng1');
      }
      window.location.href = '/app';
    })
    .catch((error: any) => console.log(error));
};
