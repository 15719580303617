import React, { useEffect, useRef, useState } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { getData } from './services/getDataImage';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WidgetContainerStyled, WidgetContentStyled } from '../../../../../styles';

const ImageGrid = ({ setSelectedElement, tabType, params }) => {
  const [gridData, setGridData] = useState(null);
  const gridInstance = useRef(null);
  const { t } = useTranslation();


  const gridRenderData = {
    columns: [
      { name: 'target.ID', label: 'ID', field: 'target.ID', hide: false, minWidth: 50 },
      { name: 'target.id', label: 'id', field: 'target.id', hide: true, minWidth: 50 },
      { name: 'text', label: 'name', field: 'text', hide: false, minWidth: 150 },
      { name: 'thumbnail', label: 'image', field: 'thumbnail', hide: false, minWidth: 50 },
      { name: 'preview', label: 'preview', field: 'preview', hide: true },
    ],
    type: 'link',
    rowSelection: 'single',
  };

  useEffect(() => {
    getData(setGridData, params, tabType);
  }, []);

  useEffect(() => {
    if (tabType !== 'new') {
      getData(setGridData, params, tabType);
    }
  }, [tabType]);

  const rowSelected = (args) => {
    setSelectedElement(args.data);
  };

  const rowSelecting = () => {
    gridInstance.current.clearSelection();
  };


  if (!gridData) return null;

  return (
    <WidgetContainerStyled>
      <WidgetContentStyled>
        <GridComponent
          ref={gridInstance}
          height={'550px'}
          rowHeight={28}
          className={' e-link-grid'}
          dataSource={gridData}
          allowFiltering={true}
          allowSelection={true}
          rowSelected={rowSelected}
          rowSelecting={rowSelecting}
        >
          <ColumnsDirective>
            <ColumnDirective
              type='checkbox'
              width='24px'
              allowResizing={false}
            />
            {gridRenderData.columns.map((column: any) => {
              switch (column.type) {
                case 'boolean':
                  return null;
                case 'code':
                  return (
                    <ColumnDirective
                      key={`field-${column.id}`}
                      headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                      width={column.minWidth}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      textAlign={'Center'}
                    />
                  );
                default:
                  return (
                    <ColumnDirective
                      key={`field-${column.id}`}
                      field={column.name}
                      width={column.minWidth}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                      visible={!column.hide}
                      disableHtmlEncode={false}
                      isPrimaryKey={column.name === 'id'}
                      type={'string'}
                    />
                  );
              }
            })}
          </ColumnsDirective>
        </GridComponent>
      </WidgetContentStyled>
    </WidgetContainerStyled>
  );
};

ImageGrid.propTypes = {
  tabType: PropTypes.string,
  setSelectedElement: PropTypes.func,
  params: PropTypes.any
};


export default connect()(ImageGrid);
