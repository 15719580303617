import { run as runActions } from '../../../../../store/workspaces/workspaces/run';


export  const handleItemClick = (e: any, context, currentDataID) => {
  const eventApi = context.events.find(event => event.type === 'onClick');
  const parameters = { id: currentDataID };
  const { modifiers, key } = eventApi;

  if (!eventApi) {
    return null;
  }

  window.dispatchEvent(
    new CustomEvent(
      'custom-mousedown',
      {
        detail: {
          key: key,
          id: currentDataID,
          modifiers: modifiers,
          event: e
        }
      }
    )
  );

  if (!e.ctrlKey) {
    runActions(eventApi?.key, currentDataID, parameters);
  }
};