import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PolicyButtonBar = ({ setPolicyDialogOpen, setPolicyContent, setCookiesDialogOpen, setCookiesContent }) => {
  const [buttons, setButtons] = useState(null);

  const handleButtonClick = (content, id, setDialogVisiblePolicy) => {

    if (id === 'cookies') {
      setCookiesContent(content);
      setCookiesDialogOpen(true);
      return;
    }
    setPolicyContent(content);
    setDialogVisiblePolicy(true);
  };

  useEffect(() => {
    const privacyPolicy = JSON.parse(localStorage.getItem('privacyPolicy'));
    const legalNotice = JSON.parse(localStorage.getItem('legalNotice'));
    const cookieSettings = JSON.parse(localStorage.getItem('cookieSettings'));
    setButtons([privacyPolicy, legalNotice, { ...cookieSettings, id: 'cookies' }]);
  }, []);

  if (!buttons) return null;

  return (
    <div className='policy-button-container'>
      {buttons.map((button, index) => (
        <button
          key={index}
          className='policy-button'
          onClick={() => handleButtonClick(button.data, button.id, setPolicyDialogOpen)}
          id={button.id}
        >
          {button.name}
        </button>
      ))}
    </div>
  );
};

PolicyButtonBar.propTypes = {
  setPolicyDialogOpen: PropTypes.func.isRequired,
  setPolicyContent: PropTypes.func.isRequired,
  setCookiesDialogOpen: PropTypes.func.isRequired,
  setCookiesContent: PropTypes.func.isRequired,
};

export default PolicyButtonBar;
