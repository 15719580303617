import React, { FC, useContext } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { LoginContext } from '../../../context/Login.context';
import '../../../styles.css';
import { handleSubmit } from './services/handleSubmit';

const ResetDialogPassword: FC<any> = () => {

  const {
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    setNewPasswordDialogOpen,
    newPasswordDialogOpen,
    user,
    setResetToken,
    setResetTokenAPI
  } = useContext(LoginContext);

  const handleCancel = () => {
    setNewPasswordDialogOpen(false);
    setResetToken('');
    setResetTokenAPI('');
  };

  const dialogButtons = [
    { click: () => handleSubmit(newPassword, confirmNewPassword, user, setNewPasswordDialogOpen), buttonModel: { content: 'Confirm', cssClass: 'custom-dialog-send-btn'} }
  ];

  return (
    <DialogComponent
      visible={newPasswordDialogOpen}
      width="350px"
      height={'350px'}
      header="Reset Password"
      buttons={dialogButtons}
      showCloseIcon={true}
      close={() => handleCancel}
    >
      <TextBoxComponent
        className='login-form-input'
        value={newPassword}
        input={(e) => {
          setNewPassword(e.value);
        }}
        placeholder="New Password"
        floatLabelType="Always"
        data-msg-containerid="error-username"
      />
      <div id="error-username" />
      <TextBoxComponent
        placeholder='Confirm New Password'
        type="text"
        className='login-form-input'
        value={confirmNewPassword}
        input={(e) => { setConfirmNewPassword(e.value); }}
        floatLabelType="Always"
        data-msg-containerid="error-keyAnswer" />
    </DialogComponent>
  );
};

export default ResetDialogPassword;
