import request from '../../../request/request';
import { toast } from '../../../utils/notification';

export const logout = async () => {
  const response = await request(
    true,
    'POST',
    'public/user/logout'
  );
  
  if (response.error)
    toast.error('', response.message);

  localStorage.setItem('logout', JSON.stringify(true));
  window.location.href = '/login';
};
