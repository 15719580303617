export const matchFileType = (stringValue) => {
  if (stringValue) {
    const check_png = stringValue.toLowerCase().includes('.png');
    const check_jpg = stringValue.toLowerCase().includes('.jpg');
    const check_jpeg = stringValue.toLowerCase().includes('.jpeg');
    const check_gif = stringValue.toLowerCase().includes('.gif');
    switch (true) {
      case check_png:
        return 'png';
      case check_jpg:
        return 'jpg';
      case check_jpeg:
        return 'jpeg';
      case check_gif:
        return 'gif';
    }
  }
};