import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const CenterLoaderCover = styled.div`
  margin: 0;
  padding: 0;
  position: fixed;
  cursor: wait;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  animation: ${fadeIn};
  animation-fill-mode: forwards;
  animation-delay: 300ms !important;
  background-color: rgba(254, 255, 255, 0.9);
  opacity: 0.5 !important;
  overflow: none !important;
`;

export const CenterLoaderSpinner = styled.div`
  border: 5px solid #a4a3a3;
  border-radius: 50%;
  border-top: 5px solid rgb(86, 85, 85);
  width: 50px;
  z-index: 9999;
  height: 50px;
  animation: ${spin} 2s linear infinite;
  top: 45%;
  position: absolute;
  left: 50%;
`;
