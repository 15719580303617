import request from 'src/request/request';

export  const loadSystem = async (setIsPolicyDataReady, setDialogContentCookies, setDialogVisibleCookies) => {
  const language = navigator.language && navigator.language === 'de-DE' ? 'german' : 'english';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await request<any, any>(false, 'GET', 'public/services/compliance/content', { language });
  const {
    privacyPolicy,
    legalNotice,
    cookieSettings
  } = response.data.response;

  localStorage.setItem('privacyPolicy', JSON.stringify(privacyPolicy || {}));
  localStorage.setItem('legalNotice', JSON.stringify(legalNotice || {}));
  localStorage.setItem('cookieSettings', JSON.stringify(cookieSettings || {}));
  setIsPolicyDataReady(true);

  const cookieAccepted = JSON.parse(localStorage.getItem('cookieAccepted'));

  if(!cookieAccepted){
    setDialogContentCookies(cookieSettings?.data);
    setTimeout(() => setDialogVisibleCookies(true), 200);
  }
};