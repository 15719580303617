export const addSignature = (args, setSignatures) => {
  setSignatures((prevSignatures) => [...prevSignatures, args]);
};
    
export const moveSignature = (args, setSignatures) => {
  setSignatures((prevSignatures) => updateArray(prevSignatures, args, 'id'));
};
    
export const removeSignature = (args, setSignatures) => {
  setSignatures((prevSignatures) => prevSignatures.filter((element) => element.id !== args.id));
};
    
export const resizeSignature = (args, setSignatures) => {
  setSignatures((prevSignatures) => updateArray(prevSignatures, args, 'id'));
};
    
export const signaturePropertiesChange = (args, setSignatures) => {
  setSignatures((prevSignatures) => updateArray(prevSignatures, args, 'id'));
};
  
const updateArray = (prevArray, args, key) => {
  return prevArray.map((element) => (element[key] === args[key] ? args : element));
};
  