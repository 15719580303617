import { useContext, useEffect } from 'react';
import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { ViewTextContext } from '../context/View.text.context';
import { marked } from 'marked';
import { setClickEventLink } from '../service/setClickEventLink';
import Handlebars from 'handlebars';

export const useFetchdata = () => {

  const {
    navData,
    actionsState,
    setContent
  } = useContext(ViewTextContext);
    

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
  
        switch (response.type) {
          case 'HTML': {
            const template = Handlebars.compile(response?.content ?? ' ');
            const result = template(response?.data);
            setContent(result);
            break;
          }
          case 'Markdown':
            setContent(response.content ? await marked(response.content) : ' ');
            break;
          default:
            setContent(response?.content ?? ' ');
            break;
        }
        setClickEventLink(navData);
      } catch (error) {
        console.log(`error fetch view data: ${error}`);
      }
    };

    fetchData();

  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);
};
