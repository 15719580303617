const setClassNames = (args) => {
  const getClassNames = (input) => {
    if (Array.isArray(input)) {
      if (input.every(item => typeof item === 'string')) {
        return input;
      } else {
        return [];
      }
    } else if (typeof input === 'string') {
      return input.split(/[,\s;]/);
    } else {
      return [];
    }
  };

  if (args.data['classNames'] !== null && args.data['classNames'] !== undefined) {
    const classNames: Array<string> = getClassNames(args.data['classNames']);
    classNames.forEach(
      (className: string) => {
        args.row.classList.add(className);
      }
    );
  }
};

export default setClassNames;