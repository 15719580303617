import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { fullPreviewMarkdown } from './fullPreviewMarkdown';
import { marked } from 'marked';

export const markdownConversion = (rteObjRef) => {
  const rteObj = rteObjRef.current;
  if (rteObj.editorMode === 'Markdown' && rteObj.contentModule) {
    const htmlPreview = rteObj.element.querySelector('.e-pre-source');
    if (htmlPreview) {
      htmlPreview.innerHTML = marked(rteObj.value);
    }
  }
};

export const convertMarkdown = (e, rteObjRef) => {
  const rteObj = rteObjRef.current;
  const mdsource = rteObj.element.querySelector('#preview-code');

  const handleMinimize = () => {
    rteObj.contentModule.getEditPanel().style.display = 'block';
    rteObj.contentModule.getEditPanel().style.width = '100%';
    const htmlPreview = rteObj.element.querySelector('.e-pre-source');
    if (htmlPreview) htmlPreview.style.display = 'none';
    mdsource.classList.remove('e-active');
    mdsource.parentElement.nextElementSibling?.classList.remove('e-active');
  };

  if (rteObj.editorMode === 'Markdown' && mdsource) {
    if (e.targetItem === 'Maximize' && isNullOrUndefined(e.args)) {
      fullPreviewMarkdown({ mode: true, type: '' }, rteObjRef);
    } else if (!mdsource.parentElement.classList.contains('e-overlay')) {
      if (e.targetItem === 'Minimize') {
        handleMinimize();
      }
      markdownConversion(rteObjRef);
    }
    rteObj.toolbarModule.refreshToolbarOverflow();
  }
};
