import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const ContextMenuContext = createContext(null);

export const ContextMenuProvider = ({ children, commonProps }) => {


  return (
    <ContextMenuContext.Provider
      value={{
        ...commonProps
      }}
    >
      {children}
    </ContextMenuContext.Provider>
  );
};

ContextMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
  commonProps: PropTypes.object
};
