import { createElement } from '@syncfusion/ej2-base';
import { marked } from 'marked';

export const fullPreviewMarkdown = (e, instanceEdit) => {
  const id = `${instanceEdit.getID()}html-preview`;
  const div = createElement('div', { className: 'e-content', id });
  instanceEdit.contentModule.getPanel().appendChild(div);
  const htmlPreview = instanceEdit.element.querySelector(`#${id}`);
  const mdsource = instanceEdit.element.querySelector('#preview-code');

  const setEditPanelStyle = (display, width) => {
    instanceEdit.contentModule.getEditPanel().style.display = display;
    instanceEdit.contentModule.getEditPanel().style.width = width;
  };

  const toggleMarkdownPreview = () => {
    if (mdsource.classList.contains('e-active') && e.mode) {
      mdsource.classList.remove('e-active');
      mdsource.parentElement.title = 'Preview';
      setEditPanelStyle('block', '100%');
      if (htmlPreview) htmlPreview.style.display = 'none';
    } else {
      mdsource.classList.add('e-active');
      setEditPanelStyle(e.type === 'preview' ? 'none' : 'block', e.type === 'preview' ? '100%' : '50%');
      if (htmlPreview) {
        htmlPreview.style.display = 'block';
        htmlPreview.innerHTML = marked(instanceEdit.value);
        htmlPreview.classList.toggle('e-pre-source', e.type === 'preview');
      }
      mdsource.parentElement.title = 'Code View';
    }
  };

  if (instanceEdit.editorMode === 'Markdown' && mdsource) {
    toggleMarkdownPreview();
  }
};
