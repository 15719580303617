import store from '../../../store/store';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import {
  goBackBreadcrumbAction,
  updateBreadcrumbItems,
} from '../../../store/workspaces/breadcrumb/actions';

export const historyBack = async (params) => {
  const itemIndex = parseInt(params?.step) || 0;
  const breadcrumbHistoryPages = store.getState().workspaces.instances.find(instance => instance.isActive).breadcrumb.historyPages;
  const newHistory = await breadcrumbHistoryPages.slice(0, -itemIndex);
  const newCurrentPage =
    itemIndex > 0
      ? newHistory[newHistory.length - 1]
      : breadcrumbHistoryPages[breadcrumbHistoryPages.length - 1];

  if (!newCurrentPage) {
    return null;
  }

  if (itemIndex > 0) {    
    await store.dispatch(updateBreadcrumbItems(newHistory, newCurrentPage));
    await store.dispatch(goBackBreadcrumbAction(itemIndex));
  }

  const { key, id, source, relation, target } = newCurrentPage;
  runActions(key, id, { source, relation, target });
};
