import { setLoaderVisible } from '../../../../store/loader/actions';
import { getPostsFiltered } from '../../../../api/services/chat/post.get.filtered';
import store from '../../../../store/store';
import { setPosts } from '../../../../store/workspaces/chat/actions';

export const getPostsDataFiltered = async (workspaceActions, filterValue) => {

  const params = {
    id: workspaceActions?.id,
    filterValue: filterValue
  };

  try {
    await store.dispatch(setLoaderVisible(true));
    const response = await getPostsFiltered(params);
    await store.dispatch(setPosts(response?.message || []));
    await store.dispatch(setLoaderVisible(false));
  } catch (error) {
    console.log('Error getting posts: ', error);
  }
};
