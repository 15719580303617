import React from 'react';
import { ColumnDirective } from '@syncfusion/ej2-react-kanban';

export const renderColumns = (navData) => {
  return navData.widgetData.states.map((state, index) => (
    <ColumnDirective
      key={`column-${state.id}-${index}`}
      headerText={state.title}
      keyField={state.keyField}
      showItemCount={true}
      transitionColumns={state.transitionColumns}
      allowToggle={true}
    />
  ));
};
