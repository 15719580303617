// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-container .list-item {
  display: flex;
  align-items: center;
}

.dialog-container .list-item .thumbnail {
  width: 50px;
  height: 40px;
}

.dialog-container .list-item .thumbnail img {
  width: 100%;
  margin: 5px 5px 5px 0;
  padding: 0 5px 0 0;
}

.dialog-container .list-item span {
  line-height: normal;
}


.list-content {
  margin-left: 10px !important;
  margin-right: 10px !important;
  overflow-y: auto;
  overflow-x: hidden;
  display: block !important;
  padding-bottom: 10px;
}

.list-content>input {
  margin-top: 5px !important;
  margin-bottom: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/widgets/form/fields/richtext/dialog/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;;AAGA;EACE,4BAA4B;EAC5B,6BAA6B;EAC7B,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,0BAA0B;EAC1B,8BAA8B;AAChC","sourcesContent":[".dialog-container .list-item {\n  display: flex;\n  align-items: center;\n}\n\n.dialog-container .list-item .thumbnail {\n  width: 50px;\n  height: 40px;\n}\n\n.dialog-container .list-item .thumbnail img {\n  width: 100%;\n  margin: 5px 5px 5px 0;\n  padding: 0 5px 0 0;\n}\n\n.dialog-container .list-item span {\n  line-height: normal;\n}\n\n\n.list-content {\n  margin-left: 10px !important;\n  margin-right: 10px !important;\n  overflow-y: auto;\n  overflow-x: hidden;\n  display: block !important;\n  padding-bottom: 10px;\n}\n\n.list-content>input {\n  margin-top: 5px !important;\n  margin-bottom: 15px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
