import request from '../../../request/request';
import { log } from '../../../utils/notification';

export const serviceFilePreview = async (url) => {

  if (!url)
    return null;

  const apiURL = process.env.REACT_APP_BACKEND_URL + url.substring(1);

  try {
    const response = await request<any, any>(true, 'GET', apiURL);
    return response;
  } catch (error) {
    log('Request (type: serviceFilePreview)', { response: error.message }, 'Error');
  }
};

export const filePreview = async (path) => {
  if (!path)
    return null;

  const apiURL = `${process.env.REACT_APP_BACKEND_URL}services/file/preview?absolutePath=${path}`;

  try {
    const response = await request<any, any>(true, 'GET', apiURL);
    return response;
  } catch (error) {
    log('Request (type: serviceFilePreview)', { response: error.message }, 'Error');
  }
};
