import types from '../types';
  
export const setChatOpen = (isOpen, id) => {
  return {
    type: types.CHAT_SET_OPEN,
    isOpen: isOpen,
    id: id
  };
};
  
  
export const setPosts = (posts) => {
  return {
    type: types.CHAT_SET_POSTS,
    posts: posts
  };
};
  
export const addPostsScrollTop = (loadedPosts) => {
  return {
    type: types.CHAT_ADD_POSTS_TOP,
    loadedPosts: loadedPosts
  };
};
  
export const addIncomingPosts = (loadedPosts) => {
  return {
    type: types.CHAT_ADD_POSTS_BOTTOM,
    loadedPosts: loadedPosts
  };
};
  
  
export const setFilterMode = (filterMode) => {
  return {
    type: types.CHAT_SET_FILTER_MODE,
    filterMode: filterMode
  };
};
  
export const setBadgeVisible = (isVisible, content) => {
  return {
    type: types.BADGE_SET_VISIBLE,
    isVisible: isVisible,
    content: content
  };
};
  
  
  
  
  
  