export const annotationAdd = (args, setAnnotations) => {
  setAnnotations((prevAnnotations) => [...prevAnnotations, args]);
};
    
export const annotationMove = (args, setAnnotations) => {
  setAnnotations((prevAnnotations) => updateArray(prevAnnotations, args, 'annotationId'));
};
    
export const annotationRemove = (args, setAnnotations) => {
  setAnnotations((prevAnnotations) =>
    prevAnnotations.filter((element) => element.annotationId !== args.annotationId)
  );
};
    
export const annotationResize = (args, setAnnotations) => {
  setAnnotations((prevAnnotations) => updateArray(prevAnnotations, args, 'annotationId'));
};
  
  
const updateArray = (prevArray, args, key) => {
  return prevArray.map((element) => (element[key] === args[key] ? args : element));
};
  