import cytoscape from 'cytoscape';

const makeSvg: any = (node: any) => {
  const svg: string = node['_private']?.data['backgroundImage'] ?? null;
  const uri = svg !== null ? 'data:image/svg+xml;utf8,' + encodeURIComponent(svg) : null;

  return { svg: uri };
};

const cytoscapeStyle = (config) => {
  return cytoscape.stylesheet()
    //.selector('node[type = "foo"]')
    .selector('node')
    .css({
      'background-color': 'data(backgroundColor)',
      'background-image': function (node) { return makeSvg(node).svg; },
      'width': 'data(width)',
      'height': 'data(height)',
      'color': 'data(color)',
      'border-color': 'data(borderColor)',
      'border-width': 'data(borderWidth)',
      'border-opacity': 0.5,
      'content': 'data(label)',
      'text-valign': 'center',
      'shape': 'data(shape)',
      'text-wrap': 'wrap',
      'text-max-width': 'data(width)',
      'font-size': config.style.font.size,
      'font-family': config.style.font.name
    })
    .selector('node.label-hidden')
    .css({
      'content': ''
    })
    .selector('node.background-hidden')
    .css({
      'width': 50,
      'height': 50,
      'shape': 'ellipse',
      'background-opacity': 0
    })
    .selector('node.border-hidden')
    .css({
      'border-opacity': 0
    })
    .selector('edge')
    .css({
      'width': 1,
      'line-color': 'data(lineColor)', //'grey',
      'target-arrow-color': 'data(targetArrowColor)', //'grey',
      'target-arrow-shape': config.style.edge.arrow ? 'data(targetArrowShape)' : 'none', //config.style.edge.arrow ? 'triangle' : 'none',
      'curve-style': 'bezier',
      'label': config.style.edge.label ? 'data(label)' : null,
      'edgeLength': '50px'
    })
    .selector('.eh-handle')
    .css({
      'border-width': 12, // makes the handle easier to hit
    })
    .selector('.eh-source')
    .css({
      'border-width': 2,
      'border-color': 'red'
    })
    .selector('.eh-target')
    .css({
      'border-width': 2,
      'border-color': 'red'
    })
    .selector('.eh-preview, .eh-ghost-edge')
    .css({
      'background-color': 'red',
      'line-color': 'red',
      'target-arrow-color': 'red',
      'source-arrow-color': 'red'
    })
    .selector('.eh-ghost-edge.eh-preview-active')
    .css({
      'opacity': 0
    })
    .selector('edge[label]')
    .css({
      //'opacity': 0,
      'font-size': '8px',
      'text-background-color': 'white',
      'text-background-opacity': 1,
      'text-background-padding': '2px',
      'text-border-color': 'black',
      'text-border-style': 'solid',
      'text-border-width': 0.5,
      'text-border-opacity': 1
    })
    .selector('node.selected')
    .css({
      'border-opacity': 0.7,
      'border-color': '#e12b38',
      'border-width': 3
    })
    .selector('node.selected.border-hidden')
    .css({
      'border-opacity': 0,
      'border-width': 0
    })
    .selector('edge.selected')
    .css({
      'line-opacity': 0.7,
      'width': 1,
      'text-border-color': '#e12b38',
      'text-border-width': 1,
      'text-border-opacity': 0.7,
      'line-color': '#e12b38',
      'target-arrow-color': '#e12b38'
    })
    .selector('node.hover')
    .css({
      'border-opacity': 0.7,
      'border-color': '#e12b38',
      'border-width': 3
    })
    .selector('node.hover.border-hidden')
    .css({
      'border-opacity': 0,
      'border-width': 0
    })
    .selector('edge.hover')
    .css({
      'line-opacity': 0.7,
      'width': 1,
      'text-border-color': '#e12b38',
      'text-border-width': 1,
      'text-border-opacity': 0.7,
      'line-color': '#e12b38',
      'target-arrow-color': '#e12b38'
    })
    .selector('core')
    .css({
      // 'active-bg-color': '#a1a1a1', // Active background indicator color
      'active-bg-opacity': 0, // Active background indicator opacity
      // 'active-bg-size': 10, // Active background indicator size
      // 'selection-box-color': 'rgba(244, 244, 244, 0.5)', // Selection box background color
      // 'selection-box-border-color': '#cacaca', // Selection box border color
      // 'selection-box-border-width': 2, // Selection box border width
      // 'selection-box-opacity': 0.7, // Selection box opacity
      // 'outside-texture-bg-color': '#cccccc', // Outside texture background color
      // 'outside-texture-bg-opacity': 0.5 // Outside texture background opacity
    });
};

export default cytoscapeStyle;