export const onRowDrag = (args: any, gridData, setGridEditData) => {

  const dropEventArg: any = args;
  const fromIndex = dropEventArg?.fromIndex;
  const toIndex = dropEventArg?.dropIndex;

  gridData?.sort((x, y) => {
    const obj1 = x['sequence'];
    const obj2 = y['sequence'];

    if (obj1 < obj2) {
      return -1;
    }
    if (obj1 > obj2) {
      return 1;
    }
    return 0;
  });
  gridData?.splice(toIndex, 0, gridData.splice(fromIndex, 1)[0]);

  const dragAndDropData = gridData?.map(element => {
    const index = gridData?.indexOf(element);
    element.sequence = index;
    return element;
  });

  setGridEditData(dragAndDropData);
};