import React  from 'react';
import { Helmet } from 'react-helmet';

const AttachWebvis: React.FC = () => {
  const HUB_URL = process.env.REACT_APP_3DHUB_URL;
  const src = `${HUB_URL}/repo/webvis/webvis.js?next`;

  if (localStorage.getItem('webvis-api-initialized') === JSON.stringify(true)) return;
  localStorage.setItem('webvis-api-initialized', JSON.stringify(true));

  return (
    <Helmet>
      <script
        type="text/javascript"
        id="webvisjs"
        src={src}
        async={true}
      ></script>
    </Helmet>
  );
};

export default AttachWebvis;
 