import React, { FC, useEffect, useState } from 'react';
import { removeModalWidget } from '../../store/workspaces/widgets/actions';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setIsDialogOpen } from '../../store/workspaces/modals/actions';
import { runDynamicQuery } from '../../api/query/run.dynamicQuery';
import store from '../../store/store';
import './styles.css';

const ModalDialog: FC<any> = ({ navData }) => {
  const [contentData, setContentData] = useState(null);
  const workspaceActions = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive)?.data);
  const { t } = useTranslation();
  const dispatch = useDispatch();


  useEffect(
    () => {
      dispatch(setIsDialogOpen(true));
      runDynamicQuery({ ...workspaceActions, widgetKey: navData.renderID }, navData.widgetData)
        .then(
          (response) => {
            setContentData(response.targets[0]);
          }
        );
      return () => {
        dispatch(setIsDialogOpen(false));
      };
    },
    [navData.widgetData]
  );


  const getButtons = () => {

    const buttons: any = [];

    navData.widgetData.menu.elements.forEach(element => {
      buttons.push({
        buttonModel: {
          content: t(element.label),
          cssClass: 'e-custom modal-dialog-btn'
        },
        'click': () => {
          const eventKey = element.events.find(event => event.type === 'onClick').key;
          runActions(eventKey, navData.widgetData.dataID, workspaceActions);
        }
      });
    });

    buttons.push({
      buttonModel: {
        content: 'Cancel',
        cssClass: 'e-custom modal-dialog-btn'
      },
      'click': () => {
        store.dispatch(removeModalWidget(navData.widgetData.key, navData.widgetData.dataID));
      }
    });
    return buttons;
  };


  if (!contentData) {
    return null;
  }

  return (
    <DialogComponent
      isModal={true}
      height={navData.widgetData.height}
      width={navData.widgetData.width}
      enableResize={false}
      resizeHandles={[]}
      allowDragging={false}
      showCloseIcon={false}
      buttons={getButtons()}
      close={() => {
        store.dispatch(removeModalWidget(navData.widgetData.key, navData.widgetData.dataID));
      }}
      header={contentData.header}
      content={contentData.content}
    >
    </DialogComponent>
  );

};

export default ModalDialog;