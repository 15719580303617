
const tapNode = async (cy: any, widgetEvents: any, runActions: any, actionsState: any) => {
  return await cy.on('tap', 'node', async function (e) {
    const element = e.target;

    if(e.originalEvent.ctrlKey) {
      const selectedElements = JSON.parse(localStorage.getItem('selectedElements')) || [];
      selectedElements.push({ ...element.data() });
      // selectedElements.push({ ...element.data(), position: element.data().position() });
      localStorage.setItem('selectedElements', JSON.stringify(selectedElements));
      element.addClass('selected');
      return; 
    } 

    element.addClass('selected');
    cy.elements()
      .difference(element)
      .removeClass('selected');

    const id = parseInt(element.data().id);
    const key = widgetEvents.find(event => event.type === 'onClick').key;

    if (!key) {
      alert('kein event gefunden');
      return null;
    }

    runActions(key, id, { ...actionsState, element: 'node' }, null);
  });

};

export default tapNode;
