import request from 'src/request/request';
import { toast } from 'src/utils/notification';

export const handleSubmit = async (
  newPassword: string,
  confirmNewPassword: string,
  user: string,
  setNewPasswordDialogOpen: (isOpen: boolean) => void
) => {
  try {

    if (confirmNewPassword.length < 5) {
      toast.error('', 'Your password must have at least 5 characters');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast.error('', 'Passwords do not match');
      return;
    }

    const response = await request<Request.NewPassword, any>(false, 'POST', 'public/auth/reset-password', {
      user,
      password: confirmNewPassword,
    });

    if (response?.error) {
      toast.error('', response.message);
      return;
    }
    toast.success('', response.message);
    setNewPasswordDialogOpen(false);
    
  } catch (error) {
    toast.error('', 'An error occurred while setting the new password.');
  }
};
