import React from 'react';
import LayoutWidget1 from '../page.layout';
import Modal from '../../../components/modals/Modal';
import PropTypes from 'prop-types';
import './styles.css';
import { DynamicRowStyled, InnerColumnStyled } from './styles';

const WorkspaceComponent = ({ workspace }) => {

  const getColumnClass = (width) => (width > 50) ? 'dynamicColumn spacy' : 'dynamicColumn tight';
  const getWidget = (columnIndex, rowIndex) => workspace?.widgets?.widgets?.find(widget => widget.position.column === columnIndex && widget.position.row === rowIndex);
  const getModalWidget = workspace?.widgets?.widgets?.find(widget => widget.modal === true);

  return (
    <>
      {workspace?.widgets?.layout && workspace?.widgets?.layout.columns && workspace?.widgets?.layout?.columns.map((column, columnIndex) => (
        <div 
          className={`main-content ${getColumnClass(column.width)}`} 
          style={{ width: `${column.width}%` }} 
          key={`dynamicColumn-${columnIndex}`}
        >
          <InnerColumnStyled>
            {column.rows?.map((row, rowIndex) => {
              const widget = getWidget(columnIndex + 1, rowIndex + 1);
              return (
                <DynamicRowStyled 
                  key={`dynamicRow-${row.position}-${column.position}`} 
                  rowheight={`${row.height}%`}
                >
                  {widget ? (
                    <LayoutWidget1
                      workspaceActions={workspace.data}
                      widget={widget}
                    />
                  ) : null}
                </DynamicRowStyled>
              );
            })}
          </InnerColumnStyled>
        </div>
      ))}
      {getModalWidget ? <Modal widget={getModalWidget} /> : null}
    </>
  );
};

WorkspaceComponent.propTypes = {
  workspace: PropTypes.object
};

export default WorkspaceComponent;
