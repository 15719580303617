import { useContext, useEffect } from 'react';
import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { KanbanContext } from '../context/Kanban.context';
import { toast } from 'src/utils/notification';

export const useFetchdata = () => {

  const {
    setKanbanData,
    navData,
    actionsState
  } = useContext(KanbanContext);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ widgetKey: navData.widgetData.key, ...actionsState }, navData.widgetData);
        setKanbanData(response);
      } catch (error) {
        toast.error('Error fetching kanban data:', error.message);
      }
    };
    fetchData();
  }, [navData.widgetData.dataID, actionsState.key, actionsState.timestamp]);
};
