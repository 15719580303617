import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import { clearBreadcrumb } from '../../../store/workspaces/breadcrumb/actions';
import { NodeAnimationSettingsModel, TreeViewComponent, NodeSelectEventArgs } from '@syncfusion/ej2-react-navigations';
import { useTranslation } from 'react-i18next';
import { TreeviewContainerStyled } from './styles';

const ModulesTreeView: FC<any> = ({workspace}) => {
  const [treeData, setTreeData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let treeviewInstance: TreeViewComponent;
  const animateSetting: NodeAnimationSettingsModel = {
    expand: { effect: 'SlideDown', duration: 30, easing: 'linear' },
    collapse: { effect: 'SlideUp', duration: 30, easing: 'linear' },
  };

  useEffect(() => {
    if (workspace?.navigation.treeData.elements) {
      const dropdownItems = workspace?.navigation.treeData.elements.map(mapToTreeNode);
      setTreeData(dropdownItems);
    }
  }, [workspace?.navigation.treeData?.elements]);

  const mapToTreeNode = (element: any) => {
    const eventKey = element.events ? element.events?.find((event) => event?.type === 'onClick')?.key : null;
    return {
      nodeId: `${element.key}.${eventKey}`,
      iconCss: element.icon.name !== '' ? `e-icons ${element.icon.name}` : null,
      nodeText: t(element.label),
      nodeChild: element.elements[0] !== null ? element.elements.map(mapToTreeNode) : null,
    };
  };

  const nodeClicked = (args: any) => {
    const treeviewItemData: any = treeviewInstance.getNode(args.node);
    const eventKey = treeviewItemData.id.split('.')[1];

    if (treeviewItemData.hasChildren === true || !eventKey) {
      return;
    }

    window.dispatchEvent(
      new CustomEvent('custom-mousedown', {
        detail: {
          key: eventKey,
          id: null,
          modifiers: null,
          event: { ctrlKey: args.event.ctrlKey, shiftKey: args.event.shiftKey },
          context: 'modules',
        },
      })
    );

    if (!args.event.ctrlKey) {
      dispatch(clearBreadcrumb());
      runActions(eventKey, null, null);
    }
  };

  const nodeSelect = (args: NodeSelectEventArgs) => {

    const treeData = treeviewInstance.getTreeData();
    const collapse = (ids) => {treeviewInstance.collapseAll(ids);};
    
    if (args.node.classList.contains('e-level-1')) {
      treeData.forEach((node: any)=> {
        collapse([node.nodeId]);
      });
    }
    if (args.node.classList.contains('e-level-2')) {
      treeData.forEach((node: any)=> {
        if(node.nodeChild){
          node.nodeChild.forEach(element => {
            collapse([element.nodeId]);
          });
        }
      });
    }
  };

  return (
    <TreeviewContainerStyled>
      <TreeViewComponent
        ref={(g) => (treeviewInstance = g)}
        animation={animateSetting}
        cssClass={'accordiontree'}
        className='tree-view'
        id='main-treeview'
        fields={{ 
          dataSource: treeData, 
          id: 'nodeId', 
          text: 'nodeText', 
          child: 'nodeChild', 
          iconCss: 'iconCss' 
        }}
        expandOn='Click'
        nodeSelected={nodeSelect}
        nodeClicked={nodeClicked}
      />
    </TreeviewContainerStyled>
  );
};

export default ModulesTreeView;
