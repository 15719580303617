export const linkElements = (gridData: any, setGridEditData, navData, args: any, link: boolean, gridLinkInstance: any) => {
  const { rowSelection } = navData.widgetData;

  if (!gridData || !gridLinkInstance) return null;

  if (rowSelection === 'single' && args.name === 'rowDeselected' && args.isInteracted) {
    return setGridEditData([]);
  }

  if (args.isHeaderCheckboxClicked) {
    return setGridEditData(args.name === 'rowDeselected' ? [] : gridData?.map(row => ({...row, selected: true})));
  }

  if (rowSelection === 'single' || !args.isInteracted) {
    return args.cancel = true;
  }

  const row: any = (args.target.closest('tr'));
  const cell = row.querySelector('td.id-cell');
  const id = Number.parseFloat(cell.innerHTML);

  const index = gridData.findIndex((element) => element.id === id);
  const element = gridData[index];
  element.selected = link;
  gridData[index] = element;

  setGridEditData(prevprops => {
    if (link) {
      return [...prevprops, { ...args.data, selected: true }];
    } else {
      return prevprops.filter(item => item.id !== args.data.id);
    }
  });
};