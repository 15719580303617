import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import PasswordStrengthBar from 'react-password-strength-bar';
import PropTypes from 'prop-types';

const PasswordEditField = ({ setNewValue }) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [newPassword, setNewPassword] = useState('');

  return (
    <div className="col-lg-12 control-section">
      <div className="content-wrapper check1">
        <div className="form-title"></div>
        <div id="formId" className="form-horizontal" noValidate="">
          <div className="form-group form-input-container">
            <label>New Password</label>
            <div className="form-input-element password-field">
              <span className="e-input-group e-control-wrapper e-valid-input">
                <input
                  className="form-input-element"
                  type={isPasswordHidden ? 'password' : 'text'}
                  id="pass"
                  name="Pass"
                  required
                  data-msg-containerid="passError"
                  data-required-message="*Password Required"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setNewValue(e.target.value);
                  }}
                />
              </span>
              <Icon
                name="lock"
                onClick={() => setIsPasswordHidden(!isPasswordHidden)}
                inverted
                circular
                link
              />
              <span className="e-float-line" />
            </div>
            <div id="passError" />
            <div className="password-strength-bar">
              <PasswordStrengthBar password={newPassword} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordEditField.propTypes = {
  setNewValue: PropTypes.func,
};

export default PasswordEditField;
