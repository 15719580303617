// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open-navigation-icon,
.close-navigation-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: var(--basic) !important;
}

.open-navigation-icon:hover,
.close-navigation-icon:hover {
    cursor: pointer !important;
}

.open-navigation-icon {
    margin: 5px !important;
}

.open-navigation-icon::before {
    content: "\\e941";
}

.close-navigation-icon {
    margin: 4px 6px !important;
}

.close-navigation-icon::before {
    content: "\\e7fc";
    font-size: 16px;
}

.icon {
    height: 50px;
}

.icon img {
    margin: 15px 5px 15px 5px;
    max-width: 20px;
}

.e-sidebar {
    margin-top: 45px !important; 
    height: calc(100% - 45px) !important; 
    overflow: hidden !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/modules/navigation/styles.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,8BAA8B;AAClC;;AAEA;;IAEI,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,oCAAoC;IACpC,2BAA2B;AAC/B","sourcesContent":[".open-navigation-icon,\n.close-navigation-icon {\n    width: 20px;\n    height: 20px;\n    font-size: 20px;\n    color: var(--basic) !important;\n}\n\n.open-navigation-icon:hover,\n.close-navigation-icon:hover {\n    cursor: pointer !important;\n}\n\n.open-navigation-icon {\n    margin: 5px !important;\n}\n\n.open-navigation-icon::before {\n    content: \"\\e941\";\n}\n\n.close-navigation-icon {\n    margin: 4px 6px !important;\n}\n\n.close-navigation-icon::before {\n    content: \"\\e7fc\";\n    font-size: 16px;\n}\n\n.icon {\n    height: 50px;\n}\n\n.icon img {\n    margin: 15px 5px 15px 5px;\n    max-width: 20px;\n}\n\n.e-sidebar {\n    margin-top: 45px !important; \n    height: calc(100% - 45px) !important; \n    overflow: hidden !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
