// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui.vertical.menu {
  border: none !important;
  background-color: rgb(233, 233, 233);
}

.ui.vertical.menu .menu {
  display: flex;
  border: solid rgb(237, 237, 238) !important;
  border-width: 1px !important;
}

.ui.vertical.menu .menu:hover {
  background: #f9f9f9 !important;
}

.ui.vertical.menu .menu .item {
  display: flex;
  padding: 5px !important;
  font-size: 13px !important;
}

.ui.vertical.menu .menu .item,
.ui.vertical.menu .menu .item .label-icon::before {
  color: var(--basic) !important;
}

.ui.vertical.menu .menu .item.disabled,
.ui.vertical.menu .menu .item.disabled .label-icon::before {
  color: var(--basic) !important;
}

.ui.vertical.menu .menu .menu-item-context {
  margin-left: 7px;
  flex: 1 1;
  color: var(--basic) !important;
}

.ui.vertical.menu .menu .right.item {
  margin-right: 3px;
  flex: 0 1;
}

.ui.vertical.menu .item>i.icon {
  float: right !important;
}

.ui.vertical.menu .menu .menu-item-counter.empty {
  color: #9b9c9b !important;
}

.ui.vertical.menu .menu .menu-item-icon {
  width: 27px;
}`, "",{"version":3,"sources":["webpack://./src/components/widgets/contextMenu/styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,2CAA2C;EAC3C,4BAA4B;AAC9B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;;EAEE,8BAA8B;AAChC;;AAEA;;EAEE,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,SAAO;EACP,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,SAAO;AACT;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".ui.vertical.menu {\n  border: none !important;\n  background-color: rgb(233, 233, 233);\n}\n\n.ui.vertical.menu .menu {\n  display: flex;\n  border: solid rgb(237, 237, 238) !important;\n  border-width: 1px !important;\n}\n\n.ui.vertical.menu .menu:hover {\n  background: #f9f9f9 !important;\n}\n\n.ui.vertical.menu .menu .item {\n  display: flex;\n  padding: 5px !important;\n  font-size: 13px !important;\n}\n\n.ui.vertical.menu .menu .item,\n.ui.vertical.menu .menu .item .label-icon::before {\n  color: var(--basic) !important;\n}\n\n.ui.vertical.menu .menu .item.disabled,\n.ui.vertical.menu .menu .item.disabled .label-icon::before {\n  color: var(--basic) !important;\n}\n\n.ui.vertical.menu .menu .menu-item-context {\n  margin-left: 7px;\n  flex: 1;\n  color: var(--basic) !important;\n}\n\n.ui.vertical.menu .menu .right.item {\n  margin-right: 3px;\n  flex: 0;\n}\n\n.ui.vertical.menu .item>i.icon {\n  float: right !important;\n}\n\n.ui.vertical.menu .menu .menu-item-counter.empty {\n  color: #9b9c9b !important;\n}\n\n.ui.vertical.menu .menu .menu-item-icon {\n  width: 27px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
