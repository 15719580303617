import {
  SET_NAVIGATION_OPEN
} from './types';
  
  
export const setNavigationOpen = (isOpen: boolean) => {
  return {
    type: SET_NAVIGATION_OPEN,
    isOpen
  };
};
  