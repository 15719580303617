import React, { useRef } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormInputContainerStyled, FormInputLabelContainerStyled, ValidationLabelStyled } from './styles';
import { setFieldLabel } from './utilis/setFieldLabel';

const FloatField = ({ field, isLocked, value, setNewValue, renderData }) => {
  const textboxInstance = useRef(null);
  const { t } = useTranslation();

  const onChange = (args) => {
    if (args.value) {
      setNewValue(args.value.toString().replace(/[^0-9.-]+/, ''));
    }
  };

  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>
        {setFieldLabel(field, renderData, value, t)}
      </FormInputLabelContainerStyled>
      <ValidationLabelStyled id={`validation-label-${field.attribute.key}`}></ValidationLabelStyled>
      <div className='form-input-element'>
        <TextBoxComponent
          ref={textboxInstance}
          className='form-input-element'
          value={value}
          change={onChange}
          enabled={!isLocked}
          type="number"
          name={field.name}
          data-msg-containerid={`error-${field.attribute.key}`}
        />
      </div>
      <div id={`error-${field.attribute.key}`} />
    </FormInputContainerStyled>
  );
};

FloatField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any,
  renderData: PropTypes.object
};

export default FloatField;
