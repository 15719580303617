import { toast } from '../../../utils/notification';
import request from '../../../request/request';


export const setWorkspacesSequenceAPI = async (pinnedWorkspacesUuids) => {
  try {
    return await request<Request.SequenceWorkspace, any>(true, 'POST', 'services/workspaces/set-pinned-sequence',  { pinnedWorkspacesUuids });
  } catch (error) {
    toast.error('setWorkspacesPinnedAPI:',  error.message);
  }
};
