import { useContext, useEffect } from 'react';
import { GridViewContext } from '../context/Grid.view.context';
import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { toast } from 'src/utils/notification';

export const useFetchdata = () => {

  const {
    setGridEditData,
    navData,
    actionsState
  } = useContext(GridViewContext);
    

  useEffect(() => {

    const fetchData = async () => {
      setGridEditData(null);
      if (actionsState) {
        try {
          const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.widgetData.key },navData.widgetData);
          setGridEditData(response.targets);
        } catch (error) {
          toast.error('Error fetching grid data:', error.message);
          throw error;
        }
      }
    };
  
    fetchData();

    return () => {
      setGridEditData(null);
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);
};
