/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from '../../../../components/MenuBar/MenuBar';
import PropTypes from 'prop-types';
import { log } from '../../../../utils/notification';
import { runDynamicQuery } from '../../../../api/query/run.dynamicQuery';
import { addTrailingSlash } from '../../../../utils/url';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import AttachWebvis from './attachWebvis';
import request from '../../../../request/request';

// interface Icons {
//   [key: string]: string;
// }

// interface Colors {
//   [key: string]: string;
// }

const CadViewer = ({ navData, actionsState }) => {
  const webvisComponent = useRef<any>(null);
  const initilize = useRef<boolean>(false);

  // const icons: Icons = {
  //   cursor: 'webvis-desktop:cursor',
  //   measurementSingle: 'webvis:measurement-single',
  //   measurementDouble: 'webvis:measurement-double',
  //   snapshot: 'webvis:snapshot',
  //   brush: 'webvis:brush',
  // };
  
  // const colors: Colors = {
  //   green: '#408e4a',
  //   white: 'white',
  //   grey: 'grey',
  // };

  useEffect(() => {
    const fetchData = async () => {

      while (!window.webvis) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); 
      }

      try {
        if (!webvisComponent.current) return;

        // const viewer = await webvisComponent.current.requestViewer();
        const context: webvis.ContextAPI | undefined = webvis.getContext();
        context.remove();

        const res = await request<any, any>(true, 'GET', 'services/webvis-key');

        if(res?.apiKey){
          context.changeSetting(webvis.SettingStrings.ADDITIONAL_REQUEST_HEADERS, {'Authorization' : res.apiKey});
        }

        // context.registerListener([webvis.EventType.NODE_CLICKED], async event => {
        //   switch (event.type) {
        //     case webvis.EventType.NODE_CLICKED:
        //       const metaData = await context.getMetadata(event.targetNodeID);
        //       console.log('data:', { event, metaData });
        //       break;
        //     default:
        //       break;
        //   }
        // }, 0, true);

        // webvisComponent.current.hideTree();
        // webvisComponent.current.hideBottomBar();
        webvisComponent.current.hideAddButton();
        // webvisComponent.current.hideSearch();
        // webvisComponent.current.hideToolbar();
        // webvisComponent.current.hideGizmo();

        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);

        if (!response || !context || Object.keys(response).length === 0) return;

        response.files.forEach((file) => {
          const modelURI = `${addTrailingSlash(process.env.REACT_APP_3DHUB_REVERSE_BACKEND_URL)}services/file/download?uuid=${file.uuid}`;

          context.add({
            dataURI: modelURI,
            initialProperties: {
              enabled: true,
            }
          });
        });

        if (!webvis && !webvisUI) return;

        // customizeToolBar(context);
        // customizeContextMenu(context, viewer);
  
      } catch (error) {
        log('error fetch cad data: ', error.message, 'Error');
      }
      initilize.current = true;
    };
    
    if (!initilize.current) fetchData();

    initilize.current = true;
    
  }, [navData.widgetData.dataID, actionsState?.key, actionsState.timestamp]); 
  

  // function customizeContextMenu(myContext: any, myViewer: any) {
  //   webvisUI.setSetting(
  //     webvisUI.UISetting.CONTEXT_MENU_FUNCTION,
  //     (entries: any) => {
  //       const newEntries: any[] = [];
  //       newEntries.push({
  //         label: 'Spare part order',
  //         command: async (nodeId: any, event: any) => {
  //           console.log('nodeId', nodeId);
  //           const label = await myContext.getProperty(nodeId, webvis.Property.LABEL);
  //           const node = await myContext.query({
  //             select: ['label'],
  //             conditions: [
  //               { nodeType: 'structure' },
  //               {
  //                 metadata: 'nodeId',
  //                 equals: nodeId
  //               }
  //             ]
  //           });
  //           console.log('Label by using getProperty', label);
  //           console.log('Label by using Query', node);
  //         },
  //       });
  //       return newEntries;
  //     },
  //   );
  // }
  
  // function customizeToolBar(myContext: any) {
  //   const toolBarShadowRoot = webvisComponent.current!.shadowRoot!.querySelector('webvis-toolbar');
  //   const toolBarContainer = toolBarShadowRoot!.shadowRoot!.querySelector('webvis-toolbar-container');
  //   console.log(toolBarContainer);
  //   webvisUI.setSetting(webvisUI.UISetting.CUSTOM_TOOLBAR_ICON_MAP,  [{
  //     icon: `${icons.cursor}`,
  //     name: 'cursor',
  //     id: 'cursor',
  //     callback: () => {
  //       myContext.setInteractionMode(webvis.InteractionMode.DEFAULT);
  //     }
  //   }, {
  //     icon: `${icons.measurementSingle}`,
  //     name: 'measurement-single',
  //     id: 'measurement-single',
  //     callback: (e: any) => {
  //       myContext.setInteractionMode(webvis.InteractionMode.MEASUREMENT_SINGLE);
  //     }
  //   }, {
  //     icon: `${icons.measurementDouble}`,
  //     name: 'measurement-double',
  //     id: 'measurement-double',
  //     callback: () => {
  //       myContext.setInteractionMode(webvis.InteractionMode.MEASUREMENT_DOUBLE);
  //     }
  //   }, {
  //     icon: `${icons.brush}`,
  //     name: 'Drawing',
  //     label: 'Drawing',
  //     id: 'Drawing',
  //     callback: (nodeId: any) => {
  //       myContext.setInteractionMode(webvis.InteractionMode.PAINT);
  //       const element = toolBarShadowRoot!.querySelector(`webvis-fab[icon="${icons.brush}"]`);
  //       if (element) {
  //         (element as HTMLElement).style.backgroundColor = colors.green;
  //       }
  //       let drawingDialog = webvisComponent.current!.querySelector('webvis-drawing-control');
  //       if (!drawingDialog) {
  //         drawingDialog = document.createElement('webvis-drawing-control');
  //         document.body.appendChild(drawingDialog);
  //       }
  //     }
  //   }]);
  //   console.log(toolBarShadowRoot);
  // }


  const widgetContext = {
    widgetData: navData.widgetData
  };
 
  return (
    <WidgetProvider value={widgetContext}>
      {
        navData.widgetData.menu
          ? <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
          : null
      }
      <AttachWebvis />
      <webvis-full ref={webvisComponent} context="default_context" viewer="default_viewer"></webvis-full>
    </WidgetProvider>
  );
};

CadViewer.propTypes = {
  navData: PropTypes.object.isRequired,
  actionsState: PropTypes.object.isRequired
};

export default CadViewer;
