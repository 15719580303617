import React, { useEffect, useState } from 'react';
import { CenterLoaderCover, CenterLoaderSpinner } from './styles';

const CenterLoader = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000);
  }, []);

  return (
    visible && (
      <CenterLoaderCover>
        <CenterLoaderSpinner />
      </CenterLoaderCover>
    )
  );
};

export default CenterLoader;
