import CryptoJS from 'crypto-js';

export const decrypt = (params: any) => {
  try {
    const bytes  = CryptoJS.AES.decrypt(params.code, params.key);
    return {text: bytes.toString(CryptoJS.enc.Utf8)};
  }
  catch (error: any) {
    return {text: null};
  }
};