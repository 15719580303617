import { setDropdownButtonToolbar } from '../toolbar/Toolbar.dropdown';
import { setDisableLinkClass } from './disableLinks';

export const setPreview = (instanceEdit, markdownConversion, field, fullPreviewMarkdown, setTextSelection, params, renderData) => {
  if (field.type === 'markdown') {
    const textArea = instanceEdit.contentModule.getEditPanel() as HTMLTextAreaElement;
    const mdsource = document.getElementById('preview-code');
    const toolbarItems = [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      'ClearFormat',
      // 'Print',
      'SourceCode',
      'FullScreen',
      'CreateTable',
      '|',
      'Undo',
      'Redo'
    ];

    const toggleToolbarItems = (enable) => {
      instanceEdit.toolbarModule.baseToolbar.toolbarObj.enableItems(18, enable);
      instanceEdit.toolbarModule.baseToolbar.toolbarObj.enableItems(19, enable);
      enable ? instanceEdit.enableToolbarItem(toolbarItems) : instanceEdit.disableToolbarItem(toolbarItems);
    };

    const handleMdSourceClick = (e) => {
      fullPreviewMarkdown({ mode: true, type: 'preview' }, instanceEdit);
      const target = e.currentTarget as HTMLElement;
      const isActive = target.classList.contains('e-active');
      toggleToolbarItems(!isActive);
      target.parentElement?.nextElementSibling?.classList.toggle('e-overlay', isActive);
    };

    textArea.addEventListener('keyup', markdownConversion);
    mdsource?.addEventListener('click', handleMdSourceClick);

    const mdSplit = document.getElementById('MD_Preview');
    mdSplit?.addEventListener('click', () => null);
  }

  setDropdownButtonToolbar(instanceEdit, setTextSelection, params, field, setDisableLinkClass, renderData)
    .then((response) => {
      response.dropDownButtonIntern?.appendTo(`#dropdown-intern-${field.attribute.key}`);
      response.dropDownButtonExtern?.appendTo(`#dropdown-extern-${field.attribute.key}`);
    });
};
