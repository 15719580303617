import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInputContainerStyled, FormInputLabelContainerStyled } from './styles';

const DefaultField: FC<any> = ({ field }) => {
  // TODO value type anzeigen
  const { t } = useTranslation();
    
  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>{t(field.label)}</FormInputLabelContainerStyled>
      <div>Field Type nicht vorhanden</div>
    </FormInputContainerStyled>
  );
};
export default DefaultField;