import React, { useEffect } from 'react';
import ChatMessage from './message/Chat.message';
import PropTypes from 'prop-types';
import { scrollDownChatBody } from '../services/handleScroll';
import './styles.css';


const ChatBody = ({ posts, isReply, setIsReply, setReplyTarget, lastMessageRef, targetDivRef, handleScroll, filterMode, navData, workspaceActions }) => {

  useEffect(() => {
    targetDivRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      targetDivRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [posts]);

  useEffect(() => {
    scrollDownChatBody();
  }, [workspaceActions]);


  return (
    <div
      ref={targetDivRef}
      className="body-container"
      key={'chat-body'}
      style={{ height: `calc(100vh - ${isReply ? '400px' : '250px' })`, overflowY: 'scroll' }}
    >
      {posts &&
        posts.map((post, index) => (
          <ChatMessage
            navData={navData}
            workspaceActions={workspaceActions}
            post={post}
            setIsReply={setIsReply}
            setReplyTarget={setReplyTarget}
            filterMode={filterMode}
            key={'message-' + post?.id + '-' + index}
          />
        ))}
      <div 
        ref={lastMessageRef}
        id='chat-body-last-message'
      />
    </div>
  );
};

ChatBody.propTypes = {
  posts: PropTypes.array,
  isReply: PropTypes.bool,
  setIsReply: PropTypes.func,
  setReplyTarget: PropTypes.func,
  lastMessageRef: PropTypes.any,
  targetDivRef: PropTypes.any,
  handleScroll: PropTypes.func,
  filterMode: PropTypes.bool,
  navData: PropTypes.any,
  workspaceActions: PropTypes.any,
};

export default ChatBody;
