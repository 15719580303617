import request from '../../../request/request';
import { log } from '../../../utils/notification';


export const sendPost = async (message: any) => {
  try {
    return await request<any, any>(false, 'POST', 'services/messenger/send-post', { message: message } );
  } catch (error) {
    log('Request (type: sending message)', { response: error.message }, 'Error');
  }
};
