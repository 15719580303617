import { log, toast } from '../../utils/notification';
import request from '../../request/request';

export const runDynamicQuery = async (params: any, widgetData: any) => {

  const res = await request<Request.Query, any>(true, 'POST', 'services/widgets/data', { data: JSON.stringify({params}) });

  if (res.data.error && res.data.message) {
    toast.error('dynamic query', res.data.message);
  }

  // ToDo: check response in API
  const getParams = () => {
    const {action, key, log, widgetKey, timestamp, ..._params } = params;
    _params.params = {action, key, log, widgetKey, timestamp};
    delete _params.params;
    return _params;
  };
  

  log(
    `${widgetData.widget} (key: ${widgetData.key})`,
    {
      params: getParams(),
      response: res.data
    },
    res.data.error ? 'Error' : 'Widget'
  );
  
  return res.data;

};