import { toast } from '../../../utils/notification';

export const copyPath = async (params) => {

  if(!params?.copy){
    return toast.error('Failed to copy path');
  }

  if (typeof navigator.clipboard == 'undefined') {
    const textArea = document.createElement('textarea');
    textArea.value = params.copy;
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      toast.error(`Failed to copy: ${params.copy}`, err);
      throw err;
    }

    document.body.removeChild(textArea);
    return;
  }
  navigator.clipboard.writeText(params.copy);
  toast.info('Path copied');
};