import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import MenuBarButton from './MenuBar.button';
import MenuBarDropdownButton from './MenuBar.dropdown';
import { WidgetConsumer } from '../../components/widgets/WidgetContext';
import { MenubarContainerStyled } from './styles';

const MenuBar = ({ menu }) => {
  const toolbarRef = useRef(null); 
  const [toolbarItems, setToolbarItems] = useState(null); // State for toolbar items
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const handleNewTab = (eventKey, currentDataID, args, e, modifiers) => {
    window.dispatchEvent(
      new CustomEvent('custom-mousedown', {
        detail: {
          key: eventKey,
          id: currentDataID,
          modifiers: modifiers,
          event: e,
          context: 'menu',
        },
      })
    );
  };

  const fetchToolbarItems = async () => {
    if (!menu?.default?.elements) return [];
    return menu.default.elements.map((element, menuIndex) => {
      const renderElement = (widget) => {
        const commonProps = { element, widget };
        if (element.type === 'menu') {
          return (
            <MenuBarDropdownButton
              {...commonProps}
              key={`menu-${element.type}-${element.key}`}
              menuIndex={menuIndex}
            />
          );
        }
        if (element.type === 'button') {
          return (
            <MenuBarButton
              {...commonProps}
              key={`menu-${element.type}-${element.key}`}
              handleNewTab={handleNewTab}
            />
          );
        }
        return null;
      };

      return {
        prefixIcon: element.icon?.name || '',
        text: element.label,
        template: () => (
          <WidgetConsumer key={`menu-element-${element.key}`}>
            {(widget) => renderElement(widget)}
          </WidgetConsumer>
        ),
      };
    });
  };

  useEffect(() => {
    const loadToolbarItems = async () => {
      setIsLoading(true);
      const items = await fetchToolbarItems();
      setToolbarItems(items);
      setIsLoading(false);
    };
    loadToolbarItems();
  }, [menu]); // Re-fetch if menu changes

  const created = () => {
    if (toolbarRef.current) toolbarRef.current.refreshOverflow();
  };

  const clicked = (args) => {
    args.cancel = true;
  };

  if (!menu.default || isLoading || !toolbarItems) return null; // Render nothing while loading

  return (
    <MenubarContainerStyled id={`menu-${menu.key}`} className='gd-ui-widget-menu' css={menu?.style?.code ?? ''}>
      <ToolbarComponent
        ref={toolbarRef}
        enableCollision={true}
        id={`toolbar-${menu.key}`}
        items={toolbarItems}
        overflowMode="Extended"
        created={created}
        clicked={clicked}
        className={'gd-ui-widget-menu-toolbar'}
      />
    </MenubarContainerStyled>
  );
};

MenuBar.propTypes = {
  menu: PropTypes.object,
};

export default MenuBar;
