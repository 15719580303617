import store from '../../../../store/store';
import { addIncomingPosts } from '../../../../store/workspaces/chat/actions';
import { toast } from '../../../../utils/notification';
import { scrollDownChatBody } from '../../../../components/widgets/chat/services/handleScroll';
import { setEventSourceHeartBeat } from '../heartbeat';

export const handleSendPost = (event) => {
  try {
    const data = JSON.parse(event.data);
    store.dispatch(addIncomingPosts({ ...data.response, chatId: data.chatId }));
    scrollDownChatBody();
  } catch (error) {
    toast.error('heartbeat (sendPost)', error.message);
    setTimeout(setEventSourceHeartBeat, 5000);
  }
};