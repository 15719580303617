import { run as runActions } from '../../../../../store/workspaces/workspaces/run';
import { service as linkService } from '../service/linkService';

export const setClickEventLink = (navData) => {
  const regexExpUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  let uuid;

  const eventKey = navData.widgetData.events?.find((event) => event?.type === 'onClick')?.key;
  setTimeout(() => {
    document.querySelectorAll('a').forEach((element) => {
      if (element.classList.contains('disable-link')) {
        element.classList.remove('disable-link');
      }

      if (element.href) {
        element.addEventListener('click', (event) => {
          event.preventDefault();
          const url = new URL(element.href);
          uuid = url.pathname.slice(1);
          if (regexExpUUID.test(uuid)) {
            event.preventDefault();
            const params = {
              uuid: uuid
            };
            linkService('system', params)
              .then((response) => {
                runActions(eventKey, response.id, null);
              })
              .catch((error) => console.log(error));
          } else {
            window.open(url.href, '_blank');
            return null;
          }
        });
      }
    });
  }, 500);
};
