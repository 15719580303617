import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const ChartContext = createContext(null);

export const ChartProvider = ({ children, commonProps }) => {

  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [currentWorkspaceUuid, setCurrentWorkspaceUuid] = useState(null);
  const workspaces = useSelector((state: any) => state.workspaces.instances);
  
  return (
    <ChartContext.Provider
      value={{
        chartInstance,
        chartData,
        setChartData,
        currentWorkspaceUuid,
        setCurrentWorkspaceUuid,
        workspaces,
        ...commonProps
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};

ChartProvider.propTypes = {
  children: PropTypes.node.isRequired,
  commonProps: PropTypes.object
};
