export  const onMultiSelectSearch= (args, filter, setFilter) => {
  const value = args.itemData.id;
  
  setFilter(prevFilter => {
    const newFilter = {
      params: { value, fields: filter.fields.map(field => field.name), fieldType: filter.type },
      fieldId: filter.id
    };
    return [...prevFilter, newFilter];
  });
};