import React, { FC } from 'react';
import { ItemDirective, ItemsDirective, ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { useTranslation } from 'react-i18next';


const SchedulerToolbar: FC<any> = ({ scheduleObj }) => {

  const { t } = useTranslation();

  const exportItems = [
    { text: 'iCalendar', iconCss: 'e-icons e-export' }
    // { text: 'Excel', iconCss: 'e-icons e-export-excel' }
  ];

  const getEventData = () => {
    const date = scheduleObj.selectedDate;
    return {
      id: scheduleObj.getEventMaxID(),
      name: '',
      start: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        new Date().getHours(),
        0,
        0
      ),
      end: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        new Date().getHours() + 1,
        0,
        0
      ),
      location: '',
      description: '',
      IsAllDay: false,
      CalendarId: 1,
    };
  };



  const onNewEvent = () => {
    const eventData = getEventData();
    scheduleObj.openEditor(eventData, 'Add', true);
  };

  const onNewReccuringEvent = () => {
    const eventData = getEventData();
    scheduleObj.openEditor(eventData, 'Add', true, 1);
  };

  const onPrint = () => {
    scheduleObj.print();
  };

  const onExportClick = (args, scheduleObj) => {
    if (args.item.text === 'Excel') {
      let exportDatas = [];
      const eventCollection = scheduleObj.getEvents();
      const resourceCollection = scheduleObj.getResourceCollections();
      const resourceData = resourceCollection[0].dataSource;
      for (const resource of resourceData) {
        const data = eventCollection.filter((e) => e.CalendarId === resource.CalendarId);
        exportDatas = exportDatas.concat(data);
      }
      scheduleObj.exportToExcel({ exportType: 'xlsx', customData: exportDatas, fields: ['Id', 'Subject', 'StartTime', 'EndTime', 'CalendarId'] });
    }
    else {
      scheduleObj.exportToICalendar();
    }
  };

  if (!scheduleObj) {
    return null;
  }

  return (
    <ToolbarComponent>
      <ItemsDirective >
        <ItemDirective
          prefixIcon='e-icons e-plus'
          click={onNewEvent}
        />
        <ItemDirective
          prefixIcon='e-icons e-repeat'
          click={onNewReccuringEvent}
        />
        <ItemDirective
          align='Right'
          prefixIcon='e-icons e-print'
          text={t('Print')}
          click={onPrint}
        />
        <ItemDirective align='Right' template={() => {
          return (
            <DropDownButtonComponent
              iconCss='e-icons e-export'
              content='Export'
              items={exportItems}
              select={(args) => onExportClick(args, scheduleObj)}
            />
          );
        }}
        />
      </ItemsDirective>
    </ToolbarComponent>
  );
};

export default SchedulerToolbar;
