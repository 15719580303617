import { ChangeEventArgs, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormInputContainerStyled, FormInputLabelContainerStyled, ValidationLabelStyled } from './styles';
import { setFieldLabel } from './utilis/setFieldLabel';

const BooleanFieldSync = ({ field, value, isLocked, setNewValue, renderData}) => {
  const checkBoxInstance = useRef(null);
  const { t } = useTranslation();

  const onChange = (args: ChangeEventArgs) => {
    setNewValue(args.checked);
  };

  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>
        {setFieldLabel(field, renderData, value, t)}
      </FormInputLabelContainerStyled>
      <ValidationLabelStyled id={`validation-label-${field.attribute.key}`}></ValidationLabelStyled>
      <div className='form-input-element boolean-field'>
        <CheckBoxComponent
          checked={value === ('false' || 'undefined') ? false : value}
          ref={checkBoxInstance}
          change={onChange}
          disabled={isLocked}
        />
      </div>
    </FormInputContainerStyled>
  );
};

BooleanFieldSync.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any, 
  renderData: PropTypes.object
};

export default BooleanFieldSync;
