export const rowSelecting = (gridData, setGridEditData, navData, args: any, gridLinkInstance: any) => {
  if (!gridLinkInstance || !args.isInteracted || navData.widgetData.rowSelection !== 'single') return null;

  gridLinkInstance.clearSelection();
  setGridEditData([{ ...args.data, selected: true }]);

  gridData.map((element: any) => {
    element.selected = false;
  });


  const row: any = (args.target.closest('tr'));
  const cell = row.querySelector('td.id-cell');
  const id = Number.parseFloat(cell.innerHTML);
  const index = gridData.findIndex((element) => element.id === id);
  const element = gridData[index];
  element.selected = true;
  gridData[index] = element;

};