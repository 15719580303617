import { runStaticQuery } from 'src/api/query/run.staticQuery';

export  const actionComplete = async (args, kanbanData) => {
  if (args.requestType === 'cardChanged') {
    args.changedRecords.map(async (record) => {
      const params = {
        nodeId: record[kanbanData.options.nodeId],
        stateId: record[kanbanData.options.keyField]
      };
      await runStaticQuery('StateMachine.SetState', params);
    });
  }
};