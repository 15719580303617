import request from '../../../request/request';
import { log } from '../../../utils/notification';

export const getPosts = async (params) => {
  try {
    const response = await request<Request.Post, any>(true, 'POST', 'services/messenger/get-posts',  { params });

    return response;
  } catch (error) {
    log('getPosts', { response: error.message }, 'Error');
  }
};
