import styled from 'styled-components';



export const TreeGridToolbarContainerStyled = styled.div`
    margin-top: 0 !important;
    display: flex !important;

    .e-toolbar .e-tbar-btn {
      color: var(--basic) !important;
      margin-bottom: 6px !important;
    }

    .e-tbar-btn-text {
        color: var(--basic) !important;
        font-size: 13px !important;
    }

    .e-toolbar .e-tbar-btn:hover {
        background-color: #F5F5F5 !important;
        border-radius: 12px !important;
    }

    .e-toolbar-items,
    .e-control,
    .e-toolbar-pop,
    .e-hor-nav {
        background-color: white !important;
    }
  `;



