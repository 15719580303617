import styled from 'styled-components';

interface ContextMenuItemStyledProps {
  count: number; 
}

export const ContextMenuItemStyled = styled.div<ContextMenuItemStyledProps>`
  .menu-item-counter {
    background-color: ${({ count }) =>
    count > 0 ? 'var(--primaryColor)' : '#f0f0f0'} !important;
    color: var(--primaryContrast) !important;
    padding: 5px 0 0 0 !important;
    margin: 0px 0px 0px 3px !important;
    height: 21px;
    min-width: 21px;
    font-size: 13px;
    font-weight: normal;
  }

  .menu-item-counter.empty {
    color: var(--basic) !important;
  }
`;
