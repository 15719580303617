import React from 'react';
import { commandClick } from './commandClick';

export const setColumnTemplateInline = (row, navData) => {
  const { elements = [] } = navData.widgetData.menu?.inline ?? {};

  const commandItems = elements.map((button) => {
    const name = button.name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
    const disabled = row.buttons?.filter(obj => obj.name === name)[0]?.disabled ?? false;
    const eventApi = button.events.filter(obj => obj.type === 'onClick')[0] ?? null;

    const params = { key: eventApi?.key, id: row.id, modifiers: eventApi?.modifiers };

    return (
      <button
        key={`${name}-${button.id}`}
        className={`e-control e-btn ${disabled ? 'e-disabled' : ''}`}
        onClick={(e) => commandClick(params, e)}
        disabled={disabled}
      >
        <span className={`e-icons e-${button.icon.name}`}>{button.label}</span>
      </button>
    );
  });
  return commandItems;
};
  