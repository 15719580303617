import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';


const ChatReply = ({ setIsReply, replyTarget }) => {

  return (
    <div className='message-reply-container' >
      <div className='message-reply-close'>
        <span
          className="e-icons e-close message-reply-close-icon "
          onClick={() => setIsReply(false)}
        />
      </div>
      <div className='message-replay-separator'></div>
      <div
        className='message-reply-target'
      >
        <div className='message-reply-target-sender'>{replyTarget?.sender}</div>
        <div className='message-reply-target-text'>{replyTarget?.text}</div>
      </div>
    </div>
  );
};

ChatReply.propTypes = {
  setIsReply: PropTypes.func,
  replyTarget: PropTypes.any,
};

export default ChatReply;
