import { runDynamicQuery } from '../../../../api/query/run.dynamicQuery';
import { toast } from '../../../../utils/notification';

const fetchTreeGridData = async (setTreeGridData, params, widgetData) => {
  try {
    const response = await runDynamicQuery(params, widgetData);
    setTreeGridData(response);
    return response;
  } catch (error) {
    toast.error('error fetch treegrid data: ', error.message);
  }
};

export default fetchTreeGridData;