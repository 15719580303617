import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const LogoContainer = styled.div`
  margin-left: 8px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImage = styled.div`
  & img {
    margin-top: 3px;
    height: 26px;
  }
`;

const Logo: React.FC<any> = () => {
  const themes = useSelector((state: any) => state.getThemes);

  if (!themes.success) {
    return (
      <LogoContainer />
    );
  }

  const logo = ((value) => ![null, undefined, ''].includes(value))(themes?.data?.logo) ? themes.data.logo : '/img/logo.svg';
  const filter = themes?.data?.colors?.primary?.filter;

  return (
    <LogoContainer className='gd-ui-header-logo-container'>
      <LogoImage className='gd-ui-header-logo-container-image'>
        <img
          src={logo}
          alt="Logo"
          style={{ filter }}
        />
      </LogoImage>
    </LogoContainer>
  );
};

export default Logo;