import React, { createContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import packageJson from '../../../../package.json';

export const LoginContext = createContext(null);

export const LoginProvider = ({ children }) => {
  const userRef = useRef(null);

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [authMode, setAuthMode] = useState(null);
  const [dbConnected, setDbConnected] = useState(true);
  const [policyDialogOpen, setPolicyDialogOpen] = useState(false);
  const [policyContent, setPolicyContent] = useState(null);
  const [cookiesDialogOpen, setCookiesDialogOpen] = useState(false);
  const [cookiesContent, setCookiesContent] = useState(null);
  const [policyReady, setPolicyReady] = useState(false);

  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [resetTokenAPI, setResetTokenAPI] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPasswordDialogOpen, setNewPasswordDialogOpen] = useState('');

  const resetState = (e) => {
    e.preventDefault();
    setUser('');
    setPassword('');
  };

  const version = `v${packageJson.version}`;

  return (
    <LoginContext.Provider
      value={{
        user,
        setUser,
        password,
        setPassword,
        isDialogOpen,
        setDialogOpen,
        authMode,
        setAuthMode,
        dbConnected,
        setDbConnected,
        policyDialogOpen,
        setPolicyDialogOpen,
        policyContent,
        setPolicyContent,
        cookiesDialogOpen,
        setCookiesDialogOpen,
        cookiesContent,
        setCookiesContent,
        policyReady,
        setPolicyReady,
        resetState,
        userRef,
        version,
        resetDialogOpen,
        setResetDialogOpen,
        resetToken,
        setResetToken,
        newPassword,
        setNewPassword,
        confirmNewPassword,
        setConfirmNewPassword,
        newPasswordDialogOpen,
        setNewPasswordDialogOpen,
        setResetTokenAPI,
        resetTokenAPI
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

// Define prop types
LoginProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
