import { runStaticQuery } from '../../../../../../../../api/query/run.staticQuery';
import { toast } from '../../../../../../../../utils/notification';

export const getData = async (setGridData, params, tabType) => {
  try {


    const response = await runStaticQuery(
      tabType === 'existing' ? 'existingLinkedDiagram' : 'linkedDiagram',
      { ...params }
    );

    console.log(
      `%c StoredProcedure (${tabType === 'existing' ? 'getExistingLinkedDiagrams' : 'getLinkedDiagrams'}) %c`,
      'background: #F9E79F; color: #000',
      'background: transparent; color: black;',
      {
        params: params,
        response: response
      }
    );

    const arr = Object.values(response);
    const results = arr.filter(element => element !== null);
    setGridData(results);
  } catch (error) {
    toast.error('getData:', error.message);
  }
};
