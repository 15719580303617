import React, { FC} from 'react';
import { useTranslation } from 'react-i18next';
import { Input} from 'semantic-ui-react';
import { FormInputContainerStyled, FormInputLabelContainerStyled } from './styles';

const PasswordField: FC<any> = ({ field, isLocked }) => {
  const isPasswordHidden = true;
  const { t } = useTranslation();
  
  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>{t(field.label)}</FormInputLabelContainerStyled>
      <div className='form-input-element password-field'>
        <span className="e-input-group e-control-wrapper e-valid-input e-disabled">
          <Input
            fluid
            name="password"
            value='*************'
            type={isPasswordHidden ? 'password' : 'text'}
            style={{ minHeight: '28px', padding: '3px', border: 'none' }}
            disabled={isLocked}
          />
        </span>
      </div>        
    </FormInputContainerStyled>
  );
};

export default PasswordField;