import { runDynamicQuery } from '../run.dynamicQuery';
import { toast } from '../../../utils/notification';

export const getGraphData = async (params: any, widgetData: any) => {
  try {
    const response = await runDynamicQuery({ ...params, widget: widgetData.widget }, widgetData);

    if (Object.keys(response).length === 0) {
      return null;
    }
    
    return response;
  } catch (error: any) {
    toast.error(`getGraphData error: ${error.message}`);
    throw error;
  }
};
