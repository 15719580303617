import React from 'react';
import PropTypes from 'prop-types';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

const PolicyDialog = ({ policyDialogOpen, setPolicyDialogOpen, policyContent }) => {

  if (!policyContent ||!policyDialogOpen) return null;

  return (
    <DialogComponent
      visible={policyDialogOpen}
      isModal={true}
      height={'calc(100% - 100px)'}
      width={'80%'}
      enableResize={false}
      resizeHandles={[]}
      allowDragging={false}
      showCloseIcon={true}
      close={() => setPolicyDialogOpen(false)}
      content={policyContent}
    >
    </DialogComponent>
  );
};

PolicyDialog.propTypes = {
  policyDialogOpen: PropTypes.bool,
  setPolicyDialogOpen: PropTypes.func,
  policyContent: PropTypes.any
};

export default PolicyDialog;
