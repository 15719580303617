import styled from 'styled-components';

interface ViewContainerProps {
    css: string;
}

export const ViewContainerStyled = styled.div<ViewContainerProps>`
    ${props => props.css}
    height: 100% !important;

    .e-rte-content {
        height: 100% !important;
        margin: 0px;
        padding: 10px;
        overflow-y: auto !important;
    }

    .e-richtexteditor{
        height: 99% !important;
    }

`;