import styled from 'styled-components';

export const BoundaryContainer = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 3px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: rgb(242, 242, 242);
`;


export const BoundaryContainerError = styled(BoundaryContainer)`
  background-color: rgb(250, 233, 233);
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
`;

export const ErrorWidget = styled.p`
  color: rgb(176, 0, 0);
  font-size: 16px;
`;

export const ErrorStack = styled.p`
  font-size: 10px;
  margin: 20px;
`;
