import store from '../../store/store';
import { setActiveWorkspace, removeWorkspace, setIsNewWorkspace } from '../../store/workspaces/workspaces/actions';
import { run as runActions } from '../../store/workspaces/workspaces/run';


export  const closeTab = async () => {
  const { workspaces } = store.getState();
  const { activeWorkspace } = workspaces;

  if (workspaces.instances.length === 1) {
    return null;
  }

  await store.dispatch(removeWorkspace(activeWorkspace.index));
  await store.dispatch(setActiveWorkspace(activeWorkspace.index + (activeWorkspace.index === 0 ? 0 : -1)));
  const newActiveWorkspace = workspaces.instances[activeWorkspace.index + (activeWorkspace.index === 0 ? 0 : -1)];

  if (newActiveWorkspace.isNewTab) {
    await runActions(newActiveWorkspace.data.key, newActiveWorkspace.data.id, newActiveWorkspace.data);
    await store.dispatch(setIsNewWorkspace(newActiveWorkspace.index, false));
  }
};