import { toast } from '../../../../utils/notification';
import { fileDownload } from './function.download';
import request from '../../../../request/request';

export const fileView = async (params: any) => {
  const absolutePath = params.absolutePath ?? params.params.absolutePath;
  const originalName = params.originalName ?? params.params.originalName;

  const response: any = await request(
    true,
    'GET',
    'services/file/view',
    {
      absolutePath: absolutePath
    },
    {
      'Accept': 'application/pdf'
    },
    'json'
  );
  
  if (response.error || !response.type) {
    toast.error('', response.message);
    return null;
  }

  const window_img = window.open('about:blank');
  const container = document.createElement('div');
  container.style.cssText = 'height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;';

  switch (response.type) {
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg':
    case 'image/webp':
    case 'image/tiff':
      setTimeout(() => {
        const img = document.createElement('img');
        img.src = `data:${response.type};base64,${response.data}`;
        img.style.maxHeight = '100%';
        container.appendChild(img);
        window_img.document.body.appendChild(container);
      }, 100);
      break;
    case 'application/pdf':
      setTimeout(() => {
        const iframe = document.createElement('iframe');
        iframe.src = `data:application/pdf;base64,${response.data}`;
        iframe.style.cssText = 'width: 100%; height: 100%; border: none;';
        container.appendChild(iframe);
        window_img.document.body.appendChild(container);
      }, 100);
      break;
    case 'video/mp4':
      setTimeout(() => {
        const video = document.createElement('video');
        video.src = `data:video/mp4;base64,${response.data}`;
        video.style.cssText = 'max-width: 100%; max-height: 100%;';
        video.setAttribute('controls', 'true');
        video.setAttribute('autoplay', 'true');
        container.appendChild(video);
        window_img.document.body.appendChild(container);
      }, 100);
      break;
    default:
      if (!absolutePath) {
        return toast.warning('Absolute path is missing');
      }
      if (!originalName) {
        return toast.warning('Original name is missing');
      }
      await fileDownload({ ...response, absolutePath, originalName });
  }
};
