import store from '../../store/store';
import { setActiveWorkspace, setIsNewWorkspace } from '../../store/workspaces/workspaces/actions';
import { run as runActions } from '../../store/workspaces/workspaces/run';


export  const focusTab = async (index) => {
  const { workspaces } = store.getState();
  const workspace = workspaces.instances[index];
  if (workspace) {
    await store.dispatch(setActiveWorkspace(index));

    if (workspace.isNewTab) {
      await runActions(workspace.data.key, workspace.data.id, workspace.data);
      await store.dispatch(setIsNewWorkspace(index, false));
    }
  }
};