import React, { useEffect, useState } from 'react';
import WorkspaceComponent from './workspace';
import { useDispatch, useSelector } from 'react-redux';
import { addPinnedWorkspaces, updateWorkspacesPlacement } from '../../../store/workspaces/workspaces/actions';
import { WorkspaceButtonBarStyled, WorkspaceContentContainerStyled } from './styles';
import { getWorkspacesPinnedAPI } from '../../../api/services/workspaces/getWorkspacesPinned';
import { toast } from '../../../utils/notification';
import store from '../../../store/store';
import { setWorkspacesSequenceAPI } from '../../../api/services/workspaces/setPinnedWorkspaceSequence';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import WorkspacesButtonBar from './workspace.buttonBar';
import './styles.css';
import BreadcrumbCustom from 'src/components/Header/breadcrumb/Breadcrumb';


const WorkspacesMainstage = () => {
  const workspaces = useSelector((state: any) => state.workspaces);
  const dispatch = useDispatch<any>();
  const [dragEndKey, setDragEndKey] = useState(0);


  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const response = await getWorkspacesPinnedAPI();
        const pinnedWorkspaces = response.data.map(JSON.parse);
        dispatch(addPinnedWorkspaces(pinnedWorkspaces));
      } catch (error) {
        toast.error('Error fetching workspaces:', error.message);
        throw error;
      }
    };
    fetchWorkspaces();
  }, []);
  


  const onDragEnd = async(result) => {

    if (!result.destination) return;
    
    const draggedWorkspace = workspaces.instances.splice(result.source.index, 1)[0];
    workspaces.instances.splice(result.destination.index, 0, draggedWorkspace);

    const pinnedWorkspaceUUIDs = workspaces.instances
      .filter(instance => instance.isPinned) 
      .map(instance => instance.uuid); 

    await setWorkspacesSequenceAPI(pinnedWorkspaceUUIDs);
    store.dispatch(updateWorkspacesPlacement(workspaces.instances));
    setDragEndKey((prevKey) => prevKey + 1);
  };


  return (
    <>
      <DragDropContext key={dragEndKey} onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <WorkspaceButtonBarStyled
              ref={provided.innerRef}
            >
              <WorkspacesButtonBar
                workspaces={workspaces}
              />
            </WorkspaceButtonBarStyled>
          )}
        </Droppable>
      </DragDropContext>
      <BreadcrumbCustom />
      {workspaces.instances.map((workspace, index) => (
        <WorkspaceContentContainerStyled
          key={index}
          active={+workspace.isActive}
        >
          <WorkspaceComponent workspace={workspace} />
        </WorkspaceContentContainerStyled>
      ))}
    </>
  );
};


export default WorkspacesMainstage;