import React, { useState, useRef } from 'react';
import { marked } from 'marked';
import ChatReply from '../body/message/reply/Chat.message.reply';
import PropTypes from 'prop-types';
import { sendPost } from 'src/api/services/chat/post.send';
import { toast } from 'src/utils/notification';
import './styles.css';

const ChatFooter = ({ posts, isReply, setIsReply, replyTarget, workspaceActions }) => {
  const [text, setText] = useState('');
  const textareaRef = useRef(null);
  const footerRef = useRef(null);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (text === '') return;

    const replacedText = text.replace(/\n/g, '<br>');
    try {
      const message = {
        id: workspaceActions.id,
        text: await marked(replacedText),
        answerTo: posts[posts.length - 1]?.id
      };
      setText('');
      await sendPost(message);

      const textarea = textareaRef.current;
      const footer = footerRef.current;
  
      textarea.style.height = '80px'; 
      footer.style.height = '80px';


    } catch (error) {
      toast.error('Error sending message:', error.message);
      throw error;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    } else if (e.key === 'Enter' && e.shiftKey) {
      setText((prevState) => prevState + '\n');
    }
  };

  const handleTextChange = (e) => {
    setText(e.target.value);

    const textarea = textareaRef.current;
    const footer = footerRef.current;

    textarea.style.height = 'auto';
    footer.style.height = 'auto';

    const newHeight = `${Math.min(textarea.scrollHeight, 200)}px`; 
    textarea.style.height = newHeight;
    footer.style.height = newHeight; 
  };

  return (
    <div className='footer-container' ref={footerRef}>
      {isReply ? (
        <ChatReply setIsReply={setIsReply} replyTarget={replyTarget} />
      ) : null}
      <form className='footer-form'>
        <textarea
          ref={textareaRef}
          placeholder='Write message'
          className='footer-message'
          value={text}
          onChange={handleTextChange}
          onKeyDown={handleKeyPress}
        />
        <button
          className={text !== '' ? 'footer-button' : 'footer-button-deactivated'}
          onClick={(e) => handleSendMessage(e)}
        >
          <span className='e-icons e-large e-send-1' />
        </button>
      </form>
    </div>
  );
};

ChatFooter.propTypes = {
  posts: PropTypes.array,
  isReply: PropTypes.bool,
  setIsReply: PropTypes.func,
  replyTarget: PropTypes.any,
  workspaceActions: PropTypes.any,
};
export default ChatFooter;
