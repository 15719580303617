import { NodeSelection } from '@syncfusion/ej2-react-richtexteditor';
import { run as runActions } from '../../../../../../store/workspaces/workspaces/run';

export const setInlineMenuEvents = (renderData, setSaveSelection ) => {

  const elements: any = renderData.menu?.inline?.elements;

  if(!elements) return null;

  elements.forEach((element) => {
    const button = document.getElementById(`menu-inline-button-${element.key}`);
    if (button) {
      button.addEventListener('click', (e) => {
        e.preventDefault();

        const selection: NodeSelection = new NodeSelection();
        const range = selection.getRange(document);
        setSaveSelection(selection.save(range, document));
  
        const eventKey = element.events?.find(event => event.type === 'onClick')?.key;
        const selectedText = window.getSelection().toString();
        const link = selectedText.length > 0  ? selectedText : '*Placeholder Link*';
        runActions(eventKey,renderData?.dataID, { link: {name: link}});
      });
    }
  });
};