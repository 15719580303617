import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const ViewTextContext = createContext(null);

export const ViewTextProvider = ({ children, commonProps }) => {

  const viewInstance = useRef(null);

  const [content, setContent] = useState('');
  const [currentWorkspaceUuid, setCurrentWorkspaceUuid] = useState(null);
  const workspaces = useSelector((state: any) => state.workspaces.instances);
  
  return (
    <ViewTextContext.Provider
      value={{
        viewInstance,
        content,
        setContent,
        currentWorkspaceUuid,
        setCurrentWorkspaceUuid,
        workspaces,
        ...commonProps
      }}
    >
      {children}
    </ViewTextContext.Provider>
  );
};

ViewTextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  commonProps: PropTypes.object
};
