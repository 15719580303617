import { log } from '../../../utils/notification';
import request from '../../../request/request';

export const jwtDecrypt = async (text) => {
  try {
    const response = await request<Request.JWT, any>(false, 'POST', 'public/services/dec', { data: text });
    return response;
  } catch (error) {
    log('Request (type: JWT dec) ', error.message, 'Error');
  }
};
