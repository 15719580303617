import store from '../../store/store';
import { runWorkflow } from '../../api/workflow/runWorkflow';
import { openNewWorkspace} from '../../store/workspaces/workspaces/actions';
import { runStaticQuery } from '../../api/query/run.staticQuery';


export const newTab = async () => {
  const response = await runStaticQuery('workspace', null);
  const onLoadEvent = response.workspace.events.find((event) => event?.type === 'onLoad');

  if (onLoadEvent) {
    const key = onLoadEvent.key;
    const response = await runWorkflow(key, null, {}, null, true);
    await store.dispatch(openNewWorkspace(response, 'modules'));
  }
};
