import getAuthenticationMode from 'src/api/query/user/getAuthenticationMode';
import request from 'src/request/request';
import { toast } from 'src/utils/notification';

export  const checkDBConnection = async (setAuthenticationMode, setIsDBConnected) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await request<any, any>(false, 'POST', 'public/check-db-connection', {});
  if (!response.error) {
    const mode = await getAuthenticationMode();
    setAuthenticationMode(mode);
  } else {
    setIsDBConnected(false);
    toast.error('', response.message);
  }
};