import styled from 'styled-components';

interface Props {
  css: string;
}

interface ElementProps {
  disabled: boolean;
}

export const MenubarContainerStyled = styled.div<Props>`
  ${(props) => props.css}

  & {
    padding: 0 !important;
    margin: 0 0 3px 0 !important;
    background-color: var(--backgroundColor) !important;
  }

  /* Button Styles */
  .e-btn {
    background-color: #c0c0c0 !important;
  }

  .e-icons {
    color: var(--primaryContrast) !important;
  }

  .e-control.e-btn {
    background-color: white !important;
    border: 1px solid #d3d3d3 !important;
    border-radius: 12px;
    box-shadow: none !important;
  }

  .e-control.e-btn:hover,
  .e-control.e-btn:focus {
    background-color: #f5f5f5 !important;
    border: 1px solid #d3d3d3 !important;
    border-radius: 12px !important;
  }

  /* Toolbar Styles */
  .e-toolbar,
  .e-toolbar.e-toolbar-item {
    min-height: 30px;
  }

  .e-toolbar .e-tbar-btn,
  .e-toolbar-pop .e-tbar-btn {
    background-color: white !important;
    border: 1px solid #d3d3d3 !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    color: var(--basic) !important;
    margin-right: 10px !important;
  }

  .e-toolbar-item:first-child {
    margin-left: 0px !important;
  }

  .e-toolbar-item:not(.e-separator):not(.e-spacer) {
    padding: 0 !important;
  }

  .e-toolbar-pop {
    padding: 0px !important;
    margin-left: 0px !important;
    border-bottom: 1px solid rgb(225, 220, 220) !important;
  }

  .e-toolbar-items,
  .e-control,
  .e-toolbar-pop,
  .e-hor-nav {
    background-color: white !important;
  }

  /* Horizontal Navigation */
  .e-hor-nav {
    min-height: 30px !important;
    border: none !important;
  }

  /* Custom Elements */
  .e-custom {
    background-color: rgb(228, 228, 228) !important;
    font-size: 13px !important;
    min-width: 60px;
    margin-right: 10px !important;

    &:hover,
    &:focus,
    &:active {
      background-color: #c0c0c0 !important;
    }
  }

  .e-custom-dropdown {
    font-size: 13px !important;
    margin-right: 10px !important;

    .e-caret {
      display: none !important;
    }
  }

  .menu-bar-separator {
    margin-right: 10px !important;
  }

  /* Upload Styles */
  .e-upload .e-file-select-wrap .e-btn,
  .e-file-drop {
    display: none !important;
  }

  /* Miscellaneous */
  .dropdown-icon {
    margin-left: 8px;
  }

  .e-tbar-btn-text {
    color: var(--primaryContrast) !important;
    font-size: 13px !important;
    margin: 0 3px;
  }
`;


export const MenubarElementStyled = styled.div<ElementProps>`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;
