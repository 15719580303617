import { setGridSortValuesToBreadcrumb } from '../../../../store/workspaces/breadcrumb/actions';
import store from '../../../../store/store';

export const setSortValues = async ({ columnName, direction }, key) => {
  await store.dispatch(setGridSortValuesToBreadcrumb({ columnName, direction }, key));
};

export const insertSortValues = async (instance, navData) => {
  try {
    const currentPageGrid = store.getState().workspaces.instances.find(inst => inst.isActive)?.breadcrumb?.currentPage?.grid;

    if (!currentPageGrid) return;

    const { sortValues, key } = currentPageGrid;

    if (key === navData.widgetData.key) {
      instance.sortColumn(sortValues.columnName, sortValues.direction, false);
    }
  } catch (error) {
    console.log('Grid: Error setting sort value:', error);
  }
};
