import download from 'js-file-download';
import { log } from '../../../../utils/notification';
import request from '../../../../request/request';

const fileDownload = async (params: any) => {
  try {
    const response: any = await request(
      true,
      'GET',
      'services/file/download',
      {
        absolutePath: params.absolutePath ?? params.params?.absolutePath
      },
      { Accept: 'application/pdf' },
      'blob'
    );

    download(response, params.originalName ?? params.params?.originalName);
  } catch (error) {
    log('Function (alias: Download)', { params: params, response: { message: error.message } }, 'Error');
  }
};

const base64Download = async (params: any) => {
  try {
    const {
      data,
      name
    } = params.file;

    const buffer = await Buffer.from(data, 'base64');
    await download(buffer, name);

  } catch {
    (error) => {
      log('Function (alias: file/base64Download)', { params: params, response: { message: error.message } }, 'Error');
    };
  }
};

export { fileDownload, base64Download };
