import request from 'src/request/request';
import { toast } from 'src/utils/notification';

export const handleSubmit = async (user, setResetTokenAPI, resetTokenAPI, resetToken, setResetDialogOpen, setNewPasswordDialogOpen) => {
  try {
    if(resetTokenAPI && resetTokenAPI === resetToken){
      setResetDialogOpen(false);
      setTimeout(() => {
        setNewPasswordDialogOpen(true);
      }, 1000); 
      return;
    }

    if(resetTokenAPI && resetTokenAPI !== resetToken){
      return toast.warning('Token does not match');
    }

    const res =  await request<Request.GenerateToken, any>(false, 'POST', 'public/auth/generate-reset-token', { name: user});

    if (!res.data) {
      toast.error('', 'User does not exist');
      return null;
    }
    setResetTokenAPI(res.data.token);
  } catch (error) {
    console.log('Error validateUserAnswer', error);
  } 
};
