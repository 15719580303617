/* eslint-disable no-case-declarations */
import request from 'src/request/request';
import { cookieDeclineToast } from '../components/cookies/cookie.Decline.toast';
import { toast } from 'src/utils/notification';
import { isCookieValid } from 'src/utils/cookies';

let isPending = false;

export const handleSubmit = async (e, authenticationMode, username, password, version, resetState, setDialogVisible) => {
  e.preventDefault();

  if (isPending) {
    return; 
  }

  if (username === '' || password === '') return toast.warning('name or password is missing');

  const cookieAccepted = JSON.parse(localStorage.getItem('cookieAccepted'));

  if (!cookieAccepted) {
    return cookieDeclineToast();
  }

  const extended = JSON.parse(localStorage.getItem('extended'));

  try {
    isPending = true; 

    switch (authenticationMode) {
      case 'password-only':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = await request<Request.Login, any>(false, 'POST', 'public/auth/signin', { extended, authenticationMode: authenticationMode, username, password, version, token: '' });
        Object.keys(response.data).forEach(key => localStorage.setItem(key, JSON.stringify(response.data[key])));
        resetState(e);
        window.location.href = '/app';
        break;
      case 'two-factor':
        if (isCookieValid('sessionCookie')) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const response = await request<Request.Login, any>(false, 'POST', 'public/auth/signin', { extended, authenticationMode: 'password-only', username, password, version, token: '' });
          Object.keys(response.data).forEach(key => localStorage.setItem(key, JSON.stringify(response.data[key])));
          resetState(e);
          window.location.href = '/app';
          break;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const response = await request<Request.Login, any>(false, 'POST', 'public/auth/signin', { extended, authenticationMode: 'password-only', username, password, version, token: '' });
          if (response.data) {
            setDialogVisible(true);
          }
        }
        break;
      default:
        return null;
    }
  } catch (error) {
    if (error.response) {
      await request(true, 'POST', 'public/user/logout');
      toast.error('', error.response.data.message);
    }
  } finally {
    isPending = false; 
  }
};