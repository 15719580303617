import { run as runActions } from '../../../../../store/workspaces/workspaces/run';

export const commandClick = (params: any, e: any): void => {
  const {
    key,
    id,
    modifiers
  } = params;

  if (!key) return null;

  window.dispatchEvent(
    new CustomEvent(
      'custom-mousedown',
      {
        detail: {
          key: key,
          id: id,
          modifiers: modifiers,
          event: e
        }
      }
    )
  );
  if(!e.ctrlKey && !modifiers?.ctrl && !modifiers?.alt && !modifiers?.shift){
    runActions(key, id, null);
  }
};