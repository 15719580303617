import styled from 'styled-components';

export const HeaderBarStyled = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 0 5px; 
  background-color: var(--primaryColor) !important; 
  height: 45px; 
  `;

