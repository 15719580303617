import request from '../../../request/request';

export const renderPdf = async (params, id) => {
  try {
  
    const response = await request<Request.RenderPDF, any>(true, 'POST', 'services/render-pdf',  {
      params: params,
      id: id
    });

    console.log(
      `%c Report (key: ${id}) %c `,
      'background: #FF8800; color: #000',
      'background: transparent; color: black',
      {
        params: params,
        response: response.data
      }
    );
    return response;
  } catch (error) {
    console.log('getPosts error:', error);
  }
};
