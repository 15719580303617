export const getEditType = (column: any) => {
  if (Array.from(column.dropDownData).length > 0) {
    return 'dropdownedit';
  } else {
    switch (column.type) {
      case 'integer':
        return 'numericedit';
      case 'textarea':
        return null;
      default:
        return null;
    }
  }
};