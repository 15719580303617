import { log } from '../../../utils/notification';
import { runStaticQuery } from '../run.staticQuery';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getState = async (storeName: string, key: any, id: any) => {

  try {
    const parameters = { id: id, key: key };
    return await runStaticQuery('getState', parameters);
  } catch (error) {
    log('StoredProcedure (alias getState)', { params: { id: id, key: key }, response: { message: error.message } }, 'Error');
  }
};