import { useContext, useEffect } from 'react';
import { KanbanContext } from '../../../context/Kanban.context';
import { DataManager, Query } from '@syncfusion/ej2-data';

export const useFilterAction = () => {

  const {
    filter,
    kanbanRef,
    kanbanData
  } = useContext(KanbanContext);


  useEffect(() => {
    if (!filter || filter.length === 0) {
      kanbanRef.current.dataSource = kanbanData.data;
      return;
    }

    const dataManager = new DataManager({ json: kanbanData.data });
    let filteredData = kanbanData.data; 

    filter.forEach((currentFilter) => {
      const { value } = currentFilter.params;
      const firstObject = kanbanData.data[0];
      const keys = Object.keys(firstObject);
      const query = new Query().search(value, keys, 'contains', true);

      filteredData = dataManager.executeLocal(query).filter((item) => filteredData.includes(item));
    });

    kanbanRef.current.dataSource = filteredData;
  }, [filter]);
};
