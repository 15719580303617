import styled from 'styled-components';

interface Props {
  css: string;
}


export const MenubarContainerStyled = styled.div<Props>`
  ${props => props.css}

& {
  padding: 0 !important;
  margin: 0 0 3px 0 !important;
}


.e-toolbar,
.e-toolbar.e-toolbar-item {
  min-height: 30px;
}
.e-toolbar-item:first-child {
  margin-left: 0px !important;
}

.e-toolbar-item:not(.e-separator):not(.e-spacer) {
  padding: 0 !important;
}
  
.e-toolbar-pop  {
  padding: 0px !important;
  margin-left: 0px !important;
  border-bottom: 1px solid rgb(225, 220, 220)!important;
}

.e-hor-nav {
  min-height: 30px !important;
  border: none !important;
}

.e-custom {
  background-color: rgb(228, 228, 228) !important;
  font-size: 13px !important;
  min-width: 60px;
  margin-right: 10px !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #c0c0c0 !important;
  }
}

.e-custom-dropdown {
  font-size: 13px !important;

  .e-caret {
    display: none !important;
  }
}

.e-custom-dropdown {
  margin-right: 10px !important;
}

.e-upload .e-file-select-wrap .e-btn {
  display: none !important;
}

.e-file-drop {
  display: none !important;
}

.dropdown-icon {
  margin-left: 8px;
}

.e-toolbar-items,
.e-control,
.e-toolbar-pop,
.e-hor-nav {
  background-color: white !important;
}
`;
