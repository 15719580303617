import types from '../types';


export const openNewWorkspace = (params: object, currentWidgets: object, widgetContext: string) => {
  return {
    type: types.WORKSPACE_OPEN,
    params: params,
    currentWidgets: currentWidgets,
    widgetContext: widgetContext
  };
};

export const removeWorkspace = (index: number) => {
  return {
    type: types.WORKSPACE_CLOSE,
    index: index
  };
};

export const setActiveWorkspace = (index: number) => {
  return {
    type: types.WORKSPACE_SET_ACTIVE_INDEX,
    index: index
  };
};

export const setIsNewWorkspace = (index: number, isNewTab: boolean) => {
  return {
    type: types.WORKSPACE_SET_IS_NEW,
    index: index,
    isNewTab: isNewTab
  };
};

export const updateWorkspaceActions = (newWorkspaceAction: object) => {
  return {
    type: types.WORKSPACE_UPDATE_ACTIONS,
    newWorkspaceAction: newWorkspaceAction
  };
};

export const renameWorkspace = (newName: string) => {
  return {
    type: types.WORKSPACE_RENAME,
    newName: newName
  };
};

export const setWorkspacePinned = (workspaceIndex: number) => {
  return {
    type: types.WORKSPACE_ACTIONS_PINNED,
    workspaceIndex: workspaceIndex
  };
};

export const addPinnedWorkspaces = (pinnedWorkspaces: number) => {
  return {
    type: types.ADD_PINNED_WORKSPACES,
    pinnedWorkspaces: pinnedWorkspaces
  };
};


export const setWorkspacePending = (pending: boolean) => {
  return {
    type: types.WORKSPACE_ACTIONS_PENDING,
    pending: pending
  };
};

export const updateWorkspacesPlacement = (workspaces) => {
  return {
    type: types.WORKSPACE_UPDATE_PLACEMENT,
    workspaces: workspaces
  };
};