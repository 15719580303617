import { toast } from '../../../../../utils/notification';
import { run as runActions } from '../../../../../store/workspaces/workspaces/run';

export const cellSelecting = async (args, navData, globalKeyEvent, workspaceActions) => {

  if(!args.currentCell) return null;

  const rowData: any = args;
  if (rowData.currentCell.classList.contains('code-cell')) return null;
  const eventApi = navData.widgetData?.events?.find((event) => event.type === 'onClick');
  const { modifiers, key } = eventApi;

  window.dispatchEvent(
    new CustomEvent(
      'custom-mousedown',
      {
        detail: { 
          key: key,
          id: rowData?.data?.id, 
          modifiers: modifiers,
          event: { ctrlKey: args.isCtrlPressed, shiftKey: args.isShiftPressed  },
          context: 'grid'
        }
      }
    )
  );

  // await interactionContext.handleMouseDown(
  //   { ctrlKey: true, button: 0 },
  //   { id: rowData?.data?.id }
  // );

  if (!eventApi) {
    toast.error('', 'an event is missing');
    return null;
  }

  if (args.isShiftPressed) {
    const currentCell: any = rowData.currentCell;
    navigator.clipboard.writeText(currentCell.innerText);
    return null;
  }

  if (!args.isCtrlPressed && !modifiers?.ctrl && !modifiers?.alt && !modifiers?.shift) {
    runActions(eventApi?.key, rowData?.data.id, { ...workspaceActions, ...rowData?.data ?? {} });
  }
  args.cancel = true;
};