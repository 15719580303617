import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInputContainerStyled, FormInputLabelContainerStyled, ValidationLabelStyled } from './styles';

const HTMLField: FC<any> = ({ field, value}) => {

  const { t } = useTranslation();

  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>{t(field.label)}</FormInputLabelContainerStyled>
      <ValidationLabelStyled id={`validation-label-${field.attribute.key}`}></ValidationLabelStyled>
      <div
        className='form-input-element'
      >
        <div className='htmlContent' dangerouslySetInnerHTML={{ __html: value }}></div>
      </div>
    </FormInputContainerStyled>
  );
};
export default HTMLField;