import { setAggregationHeight } from '../../services/setAggregationHeight';
import { insertFilterValues } from '../../services/setFilterValues';
import { setKeyDownRemoveFilter } from '../../services/setKeydownEvent';
import { insertSortValues } from '../../services/setSortValues';

export  const created = (gridViewInstance, navData) => {
  insertFilterValues(gridViewInstance.current, navData);
  insertSortValues(gridViewInstance.current, navData); 
  setAggregationHeight();
  window.addEventListener('keydown', (event) => setKeyDownRemoveFilter(event, gridViewInstance.current));
};