import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { callFunctions } from '../../api/functions/call.functions';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { toast } from '../../utils/notification';
import PropTypes from 'prop-types';
import store from '../../store/store';

const MenuBarDropdownButton: any = ({ element, menuIndex, widget }) => {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpenIconClass, setIsOpenIconClass] = useState('e-chevron-right');

  const handleDropdownButtonClick = async (args) => {
    const key = args.item?.key;

    if (!key) {
      toast.error('', 'Event is missing');
      return null;
    }

    let data;
    let files;

    switch (widget.widgetData.widget) {
      case 'Diagram':
        data = await widget.getContent();
        break;
      case 'View':
        break;
      case 'Gantt':
        data = await widget.getCurrentGanttData();
        break;
      case 'Grid':
        data = widget.state.gridEditData;
        break;
      case 'TreeGrid':
      case 'Graph':
        runActions(key, widget.widgetData.dataID, { ...widget.actionsState })
          .then(async () => {
            const functions = store.getState().workspaces.instances.find(instance => instance.isActive).data?.functions;
            if (functions) {
              await callFunctions(functions, { reloadLayout: widget.reloadLayout }, false);
            }
          });
        break;
      case 'Form':
        if (widget.validationFormObject?.validate() === false) {
          return null;
        }
        data = widget.formEditData;
        files = widget.formEditFiles;
        break;
      default:
        return null;
    }

    return runActions(key, widget.widgetData.dataID, { ...widget.actionsState, data: data }, files);
  };
  const icon = () => {
    if (element?.icon?.image != null) {
      return <img src={element?.icon?.image ?? ''} alt="Icon" style={{ height: '13px', padding: '0px 7px 0px 0px', margin: '0px' }} />;
    }
  };
  

  return (
    <DropDownButtonComponent
      ref={dropdownRef}
      items={element.elements.sort((a, b) => a.sequence - b.sequence).map((subMenuItem) => ({
        text: t(subMenuItem.label),
        iconCss: '',
        id: subMenuItem.key,
        key: subMenuItem.events.find((event) => event.type === 'onClick')?.key,
      }))}
      iconPosition="Left"
      cssClass="e-custom-dropdown"
      select={handleDropdownButtonClick}
      key={`Dropdown${menuIndex}`}
    >
      {icon()}
      {t(element.label)}
      <span className={`e-icons e-small ${isOpenIconClass} dropdown-icon`}></span>
    </DropDownButtonComponent>
  );
};

MenuBarDropdownButton.propTypes = {
  element: PropTypes.object,
  menuIndex: PropTypes.number,
  widget: PropTypes.object,
};

export default MenuBarDropdownButton;
