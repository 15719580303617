import { log } from '../../../utils/notification';
import { runStaticQuery } from '../run.staticQuery';

export const getThemes = async () => {

  try {
    const parameters = { };
    return runStaticQuery('theme', parameters);
  } catch (error) {
    log('StoredProcedure (alias getThemes)', { params: {}, response: { message: error.message } }, 'Error');
  }

};

