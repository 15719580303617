
export const setNavigationReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          navigation: {
            ...instance.navigation, 
            treeData: action.data
          },
        };
      }
      return instance;
    }),
  };
};
      