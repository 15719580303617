import { toast } from '../../../utils/notification';
import store from '../../../store/store';
import { jwtEncrypt } from '../../services/tools/service.jwtEncrypt';

export const copyURL = async () => {
  const currentPage = store.getState().workspaces.instances.find(instance => instance.isActive)?.breadcrumb?.currentPage ?? {};
  
  const urlParam = {
    key: currentPage.key ?? null,
    id: currentPage.id ?? null,
    source: currentPage.source ?? null,
    relation: currentPage.relation ?? null,
    target: currentPage.target ?? null
  };

  jwtEncrypt(urlParam)
    .then((response) => {
      const copyUrl = window.location.href + '/' + response.data;

      if (typeof navigator.clipboard == 'undefined') {
        const textArea = document.createElement('textarea');
        textArea.value = copyUrl;
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          toast.error(`Failed to copy: ${copyUrl}`, err);
          throw err;
        }

        document.body.removeChild(textArea);
        return;
      }
      navigator.clipboard.writeText(copyUrl);
      toast.info('URL copied');
    });
};