import request from '../../../request/request';
import { log } from '../../../utils/notification';

export const getWorkspacesPinnedAPI = async () => {
  try {

    const response = await request<any, any>(true, 'POST', 'services/workspaces/get-pinned',   { });

    return response;
  } catch (error) {
    log('services/workspaces/get-pinned', { response: error.message }, 'Error');
  }
};
