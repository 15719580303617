import types from '../types';
import { getModules as getModulesNew_API } from '../../../api/query/static/run.modules';
import { log } from '../../../utils/notification';

export const getModules = () => async (dispatch) => {
  try {
    const response = await getModulesNew_API();
    dispatch({ 
      type:  types.MODULES_SET, 
      data: response 
    });
  } catch (error) {
    log('StoredProcedure (alias getModules)', { params: { type:  types.MODULES_SET}, response: { message: error.message } }, error);
  }
};

export const setSelectedModule = (key) => ({
  type: types.MODULES_SET_SELECTED,
  key
});

