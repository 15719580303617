import { log } from '../../../utils/notification';
import request from '../../../request/request';

export const jwtEncrypt = async (text) => {
  try {
    const response = await request<Request.JWT, any>(false, 'POST', 'services/enc', { data: text });

    return response;
  } catch (error) {
    log('Request (type: JWT enc) ', error.message, 'Error');
  }
};
