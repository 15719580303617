import notAvailablePic from '../../../../../../images/missing.png';
import { useEffect } from 'react';
import { serviceFilePreview } from 'src/api/services/tools/service.filePreview';
import { matchFileType } from '../../../carousel/services/matchFileType';

export const useServiceFilePreview = (setImageBase64, absolutePath) => {

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await serviceFilePreview(absolutePath);
        
        if (response === undefined || response.error) {
          setImageBase64(notAvailablePic);
        } else {
          const fileType = matchFileType(absolutePath);
          setImageBase64(`data:@file/${fileType};base64,${response?.data}`);
        }
      } catch (error) {
        console.log('Error in serviceFilePreview:', error);
        setImageBase64(notAvailablePic); 
      }
    };

    fetchData(); 
  }, [absolutePath]);
};
