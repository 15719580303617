import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { HtmlEditor, Inject, RichTextEditorComponent, Resize, MarkdownEditor } from '@syncfusion/ej2-react-richtexteditor';
import { useTranslation } from 'react-i18next';
import { marked } from 'marked';
import PropTypes from 'prop-types';
import { FormInputContainerStyled, FormInputLabelContainerStyled } from '../styles';
import { setDisableLinkClass } from './services/disableLinks';

const RichTextFieldView = ({ viewType, field, value, renderData }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelectorAll('.e-rte-image').forEach((element) => {
      element.classList.remove('e-rte-image');
    });

    // const Editable = ['create', 'edit'].includes(viewType);
    // setDisableLinkClass(Editable, renderData);
    setDisableLinkClass(true, renderData);
  }, [viewType, value]);

  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>
        {t(field.label)}
      </FormInputLabelContainerStyled>
      <div className='parsedHTML richtext-container' style={{ height: value !== null ? `${field.height}px` : '70px', width: '100%', overflowY: 'auto' }}>
        <div>
          {value ? (
            <RichTextEditorComponent
              readonly={true}
              value={field.type === 'markdown' ? marked(value) : value}
              editorMode={'HTML'}
              className={'richtext-view'}
            >
              <Inject services={[HtmlEditor, MarkdownEditor, Resize]} />
            </RichTextEditorComponent>
          ) : (
            ''
          )}
        </div>
      </div>
    </FormInputContainerStyled>
  );
};

RichTextFieldView.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any,
  params: PropTypes.object,
  viewType: PropTypes.string,
  renderData: PropTypes.any
};

export default connect()(RichTextFieldView);
