import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import styled from 'styled-components';

export const NavigationContainerStyled = styled.div`
  font-size: 15px;
  width: 80%;
  margin: 25px auto;

  .e-list-item {
    color: black !important;
  }

  cursor: pointer !important;

  .e-control.e-dropdownlist.e-lib.e-input,
  .e-input-group .e-control::placeholder {
    font-size: 18px !important;
    margin-left: 8px;
  }

  .e-input-group,
  .e-input-group.e-control-wrapper {
    border: 1px solid lightgray !important;
    border-radius: 10px !important;
    background-color: #fff !important;
    transition: background-color 0.2s, border-color 0.2s;
  }

  .e-input-group:hover,
  .e-input-group.e-control-wrapper:hover {
    background-color: #F5F5F5 !important;
  }
`;

export const DropDownListComponentStyled = styled(DropDownListComponent)`
  cursor: pointer !important;
`;
