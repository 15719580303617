import React, { useContext } from 'react';
import { FilterWrapperStyled, KanbanFieldStyled, KanbanFilterContainerStyled } from '../../styles';
import PropTypes from 'prop-types';
import { useFilterAction } from './hooks/useFilterAction';
import { KanbanContext } from '../../context/Kanban.context';
import FilterField from './components/field/Kanban.filter.field';

const KanbanSearchFilter = () => {

  const {
    navData,
    setFilter,
    setOperators
  } = useContext(KanbanContext);

  useFilterAction();


  return (
    <KanbanFilterContainerStyled>
      {navData.widgetData.filters.map((filter) => (
        <FilterWrapperStyled key={`filter-${filter.id}`} count={navData.widgetData.filters.length}>
          <KanbanFieldStyled key={`field-${filter.id}`}>
            <FilterField
              filter={filter}
              navData={navData}
              setFilter={setFilter}
              setOperators={setOperators}
            />
          </KanbanFieldStyled>
        </FilterWrapperStyled>
      ))}
    </KanbanFilterContainerStyled>
  );
};

KanbanSearchFilter.propTypes = {
  data: PropTypes.array,
  kanbanRef: PropTypes.any,
  navData: PropTypes.object
};

export default KanbanSearchFilter;
