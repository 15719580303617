export const setAggregationHeight = () => {

  const gridContentElement: HTMLDivElement = document.querySelector('.e-gridcontent');
  const gridPagerElement: HTMLDivElement = document.querySelector('.e-gridpager');
  const gridFooterElement: HTMLDivElement = document.querySelector('.e-gridfooter');

  const footerHeight = gridFooterElement?.offsetHeight ?? null;
  const pagerHeight = gridFooterElement?.offsetHeight ?? null;


  if (gridContentElement && gridFooterElement && footerHeight) {
    gridContentElement.style.height = `calc(100% - ${footerHeight + 68 }px)`;
  }

  if (gridContentElement && gridFooterElement && footerHeight && gridPagerElement) {
    gridContentElement.style.height = `calc(100% - ${footerHeight + pagerHeight + 88}px)`;
  }

};