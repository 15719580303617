// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* TODO: check why does not work in styles.ts */
.e-input-focus::before,
.e-input-focus::after {
    background: none !important;
    border: none !important;
}

.e-list-item {
    color: black !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/modules/dropdown/styles.css"],"names":[],"mappings":";AACA,+CAA+C;AAC/C;;IAEI,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["\n/* TODO: check why does not work in styles.ts */\n.e-input-focus::before,\n.e-input-focus::after {\n    background: none !important;\n    border: none !important;\n}\n\n.e-list-item {\n    color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
