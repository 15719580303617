import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
// import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';

interface ChatBadgeProps {
  empty?: any;
  }

export const HeaderMenuContainerStyled = styled.div`
  display: flex;
  align-items: center;

  .header-dropdown-item .e-caret {
    display: none !important;
  }
  
  .gd-ui-header-user-name {
    margin-top: 6px;
    color: var(--primaryContrast);
  }
  
  .link {
    display: flex;
    align-items: center;
    padding-right: 10px;
    margin: 3px 0;
  }

  .link i.icon {
    font-size: 1.2em;
    padding-left: 12px;
    padding-right: 12px;
    color: var(--secondaryContrast);
  }

  .link:last-child {
    padding-left: 5px;
  }

  .link:last-child i.icon {
    padding-left: 3px;
  }

  .e-btn,
  .e-btn:hover,
  .e-btn.e-active {
    font-size: 13px !important;
    background: none !important;
    background-color: transparent !important;
    color: var(--secondaryContrast) !important;
    border: none !important;
    box-shadow: none !important;
  }
  .ui.avatar.image,
  .ui.avatar.image img,
  .ui.avatar.image svg,
  .ui.avatar.images .image,
  .ui.avatar.images img,
  .ui.avatar.images svg {
    border: 1px solid var(--primaryContrast);
    background-color: white;
}

`;

export const ChatBadgeStyled = styled.div<ChatBadgeProps>`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-right: -6px;
  margin-top: -18px;
  background-color: ${(props) => (props.empty ? 'var(--primaryColor)' : '#4CAF50')};
  color: ${(props) => (props.empty ? 'var(--primaryColor)' : 'white')};
  border-radius: 50%;
  font-size: 12px;
  font-weight: bolder;
`;


export const HeaderAvatarStyled = styled(Image)`
  margin-right: 10px !important;
`;

// export const DropDownButtonStyled = styled(DropDownButtonComponent)`
//     padding: 0px 0px 0px 0px !important;
// `;

