import { useContext, useEffect } from 'react';
import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { toast } from 'src/utils/notification';
import { ChartContext } from '../context/Chart.context';

export const useFetchdata = (navData, actionsState) => {

  const {
    setChartData,
  } = useContext(ChartContext);
    

  useEffect(() => {
    const fetchData = async () => {
      try {      
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
        setChartData(response);
      } catch (error) {
        toast.error('Error fetching Gantt data:', error.message);
      }
    };
    fetchData();
  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);
};
