import { toast } from '../../../../utils/notification';
import { eventSource, setEventSourceHeartBeat } from '../heartbeat';

export const handleError = () => {
  try {
    eventSource.close();
  } catch (error) {
    toast.error('heartbeat (connection)', error.message);
    eventSource.close();
    setTimeout(setEventSourceHeartBeat, 5000);
  }
};