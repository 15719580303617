import { DateRangePicker } from '@syncfusion/ej2-react-calendars';

let dateTimeElement;

const handleFilterChange = (args, column, gridData, setGridEditData) => {
  const { startDate, endDate } = args;

  if (!startDate && !endDate) {
    return setGridEditData(gridData);
  }

  const filteredData = gridData
    .slice() 
    .sort((a, b) => new Date(a[column.name]).getTime() - new Date(b[column.name]).getTime())
    .filter(item => {
      const itemDate = new Date(item[column.name]);
      return itemDate >= startDate && itemDate <= endDate.getTime() + (24 * 60 * 60 * 1000);
    });

  setGridEditData(filteredData);
};

const onFocus = (args) => {
  args.model.element.disabled = true;
};


export const setFilterDateTimeTemplate = (userData, column, gridData, setGridEditData) => ({
  create: () => {
    const container = document.createElement('div');
    dateTimeElement = document.createElement('input');
    container.appendChild(dateTimeElement);
    
    return container;
  },
  write: () => {
    const startDatePickerObj = new DateRangePicker({
      format: userData.dateFormat,
      change: args => handleFilterChange(args, column, gridData, setGridEditData),
      focus: onFocus
    });
    
    startDatePickerObj.appendTo(dateTimeElement);
  },
});
