import { runStaticQuery } from '../../../../../../../../api/query/run.staticQuery';
import { toast } from '../../../../../../../../utils/notification';


export const getData = async (setGridData: any, params, tabType) => {
  try {
    const response = await runStaticQuery(
      tabType === 'existing' ? 'existingPages' : 'pages',
      params
    );

    console.log(
      `%c StoredProcedure (${tabType === 'existing' ? 'getExistingPages' : 'getPages'}) %c`,
      'background: #F9E79F; color: #000',
      'background: transparent; color: black;',
      {
        params: params,
        response: { uuid: response.uuid }
      }
    );

    const arr = Object.values(response.elements ?? []);
    const results = arr.filter(element => element !== null);
    setGridData(results);
  } catch (error) {
    toast.error('getData:', error.message);
  }
};
