const sendMail = (params) => {
  const {
    recipients,
    subject = '',
    body = ''
  } = params;

  const subjectEncoded = encodeURIComponent(subject);
  const bodyEncoded = encodeURIComponent(body);
  const recipientsEncoded = encodeURIComponent(recipients.join(','));

  const mailtoLink = `mailto:${recipientsEncoded}?subject=${subjectEncoded}&body=${bodyEncoded}&content-type=text/html`;

  window.location.href = mailtoLink;
};

export default sendMail;