import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { LoginContext } from '../../context/Login.context';
import { handleSubmit } from '../../services/handleSubmit';
import LoginTokenDialog from '../dialog/Dialog.login';

const LoginForm = () => {

  const {
    user,
    setUser,
    password,
    setPassword,
    isDialogOpen,
    setDialogOpen,
    authMode,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dbConnected,
    resetState,
    userRef,
    version,
  } = useContext(LoginContext);

  return (
    <><div className="login-form-title-container">
      <h1 className="login-form-title">Login</h1>
    </div><form
      id="form"
      className='login-form'
    >
      <div className='form-input'>
        <TextBoxComponent
          ref={userRef}
          name="username"
          autocomplete="username"
          value={user}
          input={(e) => {
            setUser(e.value);
            localStorage.setItem('user', e.value);
          } }
          placeholder="Name"
          floatLabelType="Always"
          data-msg-containerid="error-username" />
        <div id="error-username" />
      </div>
      <div className='form-input'>
        <TextBoxComponent
          type="password"
          name="password"
          autocomplete="current-password"
          value={password}
          input={(e) => { setPassword(e.value); } }
          placeholder="Password"
          floatLabelType="Always"
          data-msg-containerid="error-password" />
        <div id="error-password" />
      </div>
      <div className="login-buttons">
        <ButtonComponent
          cssClass="e-flat custom-login-button"
          iconCss='e-icons e-lock'
          content="Login"
          onClick={(e) =>
            handleSubmit(e, authMode, user, password, version, resetState, setDialogOpen)
          }
        />
        {/* <ButtonComponent
          cssClass='e-custom'
          style={{ textTransform: 'none', marginLeft: '10px' }}
          content={'Reset'}
          onClick={(e) => resetState(e)} /> */}
      </div>
      <LoginTokenDialog
        isDialogOpen={isDialogOpen}
        user={user}
        password={password}
        resetState={resetState}
        setDialogOpen={setDialogOpen}
        version={version}
        authMode={authMode} />
    </form></>

  );
};

LoginForm.propTypes = {
  user: PropTypes.string,
  password: PropTypes.string,
  setUser: PropTypes.func,
  setPassword: PropTypes.func,
  handleSubmit: PropTypes.func,
  resetState: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  authMode: PropTypes.string,
  version: PropTypes.string,
  dbConnected: PropTypes.bool,
  userRef: PropTypes.any
};

export default LoginForm;
