// eslint-disable-next-line @typescript-eslint/no-unused-vars
const boxSelect = async (cy: any, setSelectedElements: React.Dispatch<React.SetStateAction<any[]>>) => {
  return await cy.on('boxselect', 'node', async (event) => {
    const targetNode = event.target;
    const nodesData = targetNode.data();

    targetNode.addClass('selected');

    const selectedElements = JSON.parse(localStorage.getItem('selectedElements')) || [];

    const exists = selectedElements.some(item => item.id === nodesData.id);

    if (!exists) {
      selectedElements.push({ ...nodesData, position: targetNode.position() });
      localStorage.setItem('selectedElements', JSON.stringify(selectedElements));
    }

    // setSelectedElements((prevSelected) => {
    //   const exists = prevSelected.some(item => item.nodes?.id === nodesData?.id);
    //   if (!exists) {
    //     return [...prevSelected, nodesData];
    //   }
    //   return prevSelected;
    // });
  }); 
};

export default boxSelect;
