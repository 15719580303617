import {
  SET_LOADER
} from './types';
  
  
export const setLoaderVisible = (visible : boolean) => {
  return {
    type: SET_LOADER,
    visible
      
  };
};
  