import React, { useEffect, useState } from 'react';
import { MapsComponent, Inject, LayersDirective, LayerDirective, Marker } from '@syncfusion/ej2-react-maps';
import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import { WidgetProvider } from '../WidgetContext';
import MenuBar from '../../../components/MenuBar/MenuBar';
import PropTypes from 'prop-types';
import { WidgetContainerStyled } from '../styles';

const MapWidget = ({ navData, actionsState }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await runDynamicQuery({params: actionsState, widgetKey: navData.widgetData.key}, navData.widgetData);
  
      const shapes = {
        type: 'FeatureCollection',
        crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' }},
        features: response.targets,
      };
      setData(shapes);
    };
    fetchData();
  }, [ navData.widgetData.dataID, actionsState?.key, actionsState.timestamp ]);


  const onClick = (event) => {
    const { shapeData } = event;
    const { id } = shapeData;
    const key = navData.widgetData.events.find((event) => event.type === 'onClick').key;
    runActions(key, id, actionsState, null);
  };

  const onMapsLoad = () => {
    // Maps loaded event
  };

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={{actionsState: actionsState}}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        ) : null}
        <MapsComponent
          locale={JSON.parse(localStorage.getItem('language'))}
          id='maps'
          loaded={onMapsLoad}
          width='100%'
          height='100%'
          shapeSelected={onClick}>
          <Inject services={[Marker]} />
          <LayersDirective>
            <LayerDirective
              shapeData={data}
              shapePropertyPath='name'
              shapeDataPath='Country'
              shapeSettings={{
                fill: '#C3E6ED',
                border: {
                  color: 'black',
                  width: 0.3,
                },
              }}
            />
          </LayersDirective>
        </MapsComponent>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

MapWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default MapWidget;
