import styled from 'styled-components';

export const WidgetContainerStyled = styled.div`
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  `;


export const WidgetContentStyled = styled.div`
    flex: 1;
    margin-bottom: 1rem;
    background-color: white;
    border: 1px solid !important;
    border-color: rgb(225, 220, 220) !important;
    /* padding-top: 2em; */
    border-radius: 5px !important;
    overflow: hidden;
`;

export const WidgetLabelStyled = styled.h3`
  margin: 12px 0 10px 3px;
  color: var(--secondaryColor) !important;
`;
 