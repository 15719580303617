import { log } from './notification';
import request from '../request/request';

export const writeErrorToLogFile = async (message, stack) => {
  try {
    await request<Request.ErrorLog, any>(false, 'POST', 'services/error-log', { message, stack });
  } catch (error) {
    log('Write error to file failed:', { response: error.message }, 'Error');
  }
};
