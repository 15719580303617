import React from 'react';
import PropTypes from 'prop-types';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { cookieDeclineToast } from './cookie.Decline.toast';
import { logout } from '../../../../api/functions/func/function.logout';
import DOMPurify from 'dompurify';

const CookieConsentComponent = ({ 
  cookiesDialogOpen, 
  setCookiesDialogOpen, 
  cookiesContent,
  type
}) => {


  const handleDecline = () => {
    localStorage.setItem('cookieAccepted', JSON.stringify(false));
    setCookiesDialogOpen(false);
    cookieDeclineToast();
    if(type === 'system'){
      setTimeout(logout, 4000);
    }
  };

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', JSON.stringify(true));
    setCookiesDialogOpen(false);
  };

  const buttons = [
    {
      click: handleDecline,
      buttonModel: { content: navigator.language === 'de-DE' ? 'Ablehnen' : 'Decline', cssClass: 'e-decline-button' }
    },
    {
      click: handleAccept,
      buttonModel: { content: navigator.language === 'de-DE' ? 'Alles akzeptieren' : 'Accept all', cssClass: 'e-accept-button' }
    }
  ];

  if (!cookiesContent || !cookiesDialogOpen) return null;

  return (
    <DialogComponent
      visible={cookiesDialogOpen}
      closeOnEscape={false}
      showCloseIcon={true}
      isModal={true}
      width="30%"
      height="50%"
      content={() => (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cookiesContent) }} />
      )}
      buttons={buttons}
      overlayClick={() => setCookiesDialogOpen(false)}
      open={() => setCookiesDialogOpen(true)}
      close={() => setCookiesDialogOpen(false)}
    />
  );
};

CookieConsentComponent.propTypes = {
  cookiesDialogOpen: PropTypes.bool,
  setCookiesDialogOpen: PropTypes.func,
  cookiesContent: PropTypes.any,
  type: PropTypes.string
};

export default CookieConsentComponent;
