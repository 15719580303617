import store from '../../../../store/store';
import { setBadgeVisible } from '../../../../store/workspaces/chat/actions';
import { toast } from '../../../../utils/notification';
import { sendPost } from '../../chat/post.send';
import { setEventSourceHeartBeat } from '../heartbeat';

export const handleOpen = () => {
  try {
    sendPost('connected');
    store.dispatch(setBadgeVisible(false, null));
  } catch (error) {
    toast.error('heartbeat (not connected)', error.message);
    setTimeout(setEventSourceHeartBeat, 5000);
  }
};