import React from 'react';
import { Internationalization } from '@syncfusion/ej2-base';


export const getAggregations = (navData) => {
  const aggregatedColumns = navData.widgetData.columns.filter((column) => column.aggregation && column.aggregation?.length !== 0);
  const intl = new Internationalization();

  if (!aggregatedColumns.length) return null;

  const renderAggregationValues = (obj, type) => {
    let value;
    switch (type) {
      case 'Sum':
      case 'Average':

        if(!obj[type] || obj[type] === ' ') return;

        value = intl.formatNumber(obj[type], { format: 'N2', useGrouping: true }).replace(/[.,]/g, (match) => (match === '.' ? ',' : '.'));
        break;
      default:
        value = Number(obj[type]);
    }
    return (
      <span className='footer-template-aggregation' key={type}>
        {`${type}: ${value}`}
      </span>
    );
  };


  const aggregate = [{
    columns: aggregatedColumns.map((column) => ({
      type: column.aggregation,
      field: column.name,
      columnName: column.name,
      footerTemplate: (obj) => (
        <div className='footer-template'>
          {column.aggregation.map((type) => renderAggregationValues(obj, type))}
        </div>
      ),
    }))
  }];

  return aggregate;
};
