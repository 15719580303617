import React, { FC, useEffect, useRef } from 'react';
import LayoutWidget1 from '../../pages/module/page.layout';
// import { removeModalWidget } from '../../store/workspaces/widgets/actions';
import store from '../../store/store';
import { setModalOpen } from '../../store/workspaces/modals/actions';
import { useSelector } from 'react-redux';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import './styles.css';

const Modal: FC<any> = ({ widget }) => {
  const dialogRef = useRef<DialogComponent | null>(null);
  const modalStates = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive).modals);
  const workspaceActions = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive).data);

  useEffect(() => {
    store.dispatch(setModalOpen(true));
    return () => {
      store.dispatch(setModalOpen(false));
    };
  }, []);

  // const handleDialogClose = () => {
  //   store.dispatch(removeModalWidget(widget.key, widget.dataID));
  //   store.dispatch(setModalOpen(false));
  // };

  return (
    <DialogComponent
      ref={dialogRef}
      visible={modalStates.modal?.isOpen || false}
      width={`${widget.width}px`}
      height={`${widget.height}px`}
      showCloseIcon={true}
      closeOnEscape={true}
      // close={() => handleDialogClose()}
      target="#root" // Ensure dialog targets the main root div or a parent container
    >
      <div className="modal-content">
        <LayoutWidget1
          workspaceActions={workspaceActions}
          widget={widget}
        />
      </div>
    </DialogComponent>
  );
};

export default Modal;
