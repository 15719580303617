import { useContext, useEffect } from 'react';
import { setKeyDownRemoveFilter } from '../../services/setKeydownEvent';
import { setAggregationHeight } from '../../services/setAggregationHeight';
import { GridSortContext } from '../context/Grid.sort.context';

export const useEventListener = () => {

  const {
    gridViewInstance,
    navData
  } = useContext(GridSortContext);
    

  useEffect(() => {
    const handleKeyDown = (event) => setKeyDownRemoveFilter(event, gridViewInstance.current);

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('resize', setAggregationHeight);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', setAggregationHeight);
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);
};
