import styled from 'styled-components';
import { Breadcrumb } from 'semantic-ui-react';

interface BreadcrumbIconStyledProps {
  iconcolor?: any;
  }

export const StyledBreadcrumb = styled(Breadcrumb)<{ length?: number }>`
  display: flex;
  margin: 15px 0px 5px 8px!important;
  height: ${(props) => (props.length === 0 ? '0px' : '30px')} !important;
`;

export const BreadcrumbItemStyled = styled.div`
    color: var(--secondaryContrast) !important;
    cursor: pointer !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 5px !important;
`;

export const BreadcrumbIconStyled = styled.div<BreadcrumbIconStyledProps>`
    color: ${(props) => props.iconcolor || 'inherit'};
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 2px;
    padding: 3px 0 0 3px;
    border-radius: 50px !important;
`;

export const BreadcrumbLabelStyled = styled.div`
      justify-content: center;
      color: var(--basic) !important;
      font-size: 12px !important;
`;
