import { setFilterValues } from '../../services/setFilterValues';
import { setSortValues } from '../../services/setSortValues';

export   const actionComplete = async (args, navData, actionsState) => {
  if (actionsState && navData) {
    if(['filter', 'filtering', 'clearFilter'].includes(args.requestType)){
      await setFilterValues(args, navData.widgetData.key);
    }
    if(args.requestType === 'sorting'){  
      await setSortValues(args, navData.widgetData.key);
    }
  }
};