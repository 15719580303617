
export const setModulesReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          navigation: {
            ...instance.navigation, 
            modules: {...instance.navigation.modules, ...action.data}
          },
        };
      }
      return instance;
    }),
  };
};
  

export const setSelectedModuleReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          navigation: {
            ...instance.navigation, 
            modules: {...instance.navigation.modules, selectedModule: action.key}
          },
        };
      }
      return instance;
    }),
  };
};
    