/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import PageItem from './Breadcrumb.section';
import { BreadcrumbIconStyled, BreadcrumbItemStyled, BreadcrumbLabelStyled, StyledBreadcrumb } from './styles';

const BreadcrumbCustom = () => {
  const workspaces = useSelector((state: any) => state.workspaces);

  const goHome = () => { window.location.reload(); };

  const length = workspaces.instances.find(instance => instance.isActive)?.breadcrumb.historyPages.length;

  return (
    <StyledBreadcrumb length={length}>
      <Breadcrumb.Section
        link
        onClick={goHome}
      >
        <BreadcrumbItemStyled>
          {/* <BreadcrumbIconStyled className={'e-icons e-home'} />
          <BreadcrumbLabelStyled>Home</BreadcrumbLabelStyled> */}
          {/* <Breadcrumb.Divider icon="right chevron" /> */}
        </BreadcrumbItemStyled>
      </Breadcrumb.Section>
      {workspaces.instances.find(instance => instance.isActive)?.breadcrumb.shiftHelper?.length >= 1 ?
        <>
          <Breadcrumb.Section> ... </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
        </>
        : null
      }

      {
        workspaces.instances.find(instance => instance.isActive)?.breadcrumb.historyPages?.map((params, itemIndex) => {

          const isLastItem = itemIndex ===  workspaces.instances.find(instance => instance.isActive)?.breadcrumb.historyPages?.length - 1;

          return <PageItem
            key={`page-item-${params.key} + random():${Math.random()}`}
            itemIndex={itemIndex}
            params={params}
            isLast={isLastItem}
          />;
        })
      }
    </StyledBreadcrumb>
  );
};

export default BreadcrumbCustom;
