import { callFunctions } from './../functions/call.functions';
import { toast, log } from '../../utils/notification';
import request from '../../request/request';

export const runWorkflow = async (key: number, id: number, params: any, file: any, newTab: boolean) => {

  try {
    const formData = new FormData();
    formData.append('key', key?.toString());
    formData.append('id', id ? id?.toString(): null);
    formData.append('params', JSON.stringify(params));
    // formData.append('file', file);
    formData.append('file', null);
  
    // const response = await request<any, any>(true, 'POST', 'services/workflow', formData , { 'Content-Type': 'multipart/form-data'});
    const response = await request<any, any>(true, 'POST', 'services/workflow', formData , { 'Content-Type': 'application/json'});
    if (response.functions && !newTab) {
      callFunctions(response.functions, null, true);
    }
    const error = false;
    response?.debug?.forEach(
      debug => {
        log(
          debug.step,
          {
            params: debug.params,
            response: debug.response
          },
          debug.style
        );
      }
    );
    return !error ? response : null;
  } catch (error) {
    toast.error('runWorkflow error:',  error.message);
  }
};