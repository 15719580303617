import React, { createContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAggregations } from '../../services/aggregations';
import { getSettingsSort } from '../settings';

export const GridSortContext = createContext(null);

export const GridSortProvider = ({ children, commonProps }) => {

  const gridSortInstance = useRef(null);
  const { t } = useTranslation();

  const [gridEditData, setGridEditData] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [currentWorkspaceUuid, setCurrentWorkspaceUuid] = useState(null);

  const globalKeyEvent = useSelector((state: any) => state.globalKeyEvent);
  const workspaces = useSelector((state: any) => state.workspaces.instances);
    
  const timeFormat = JSON.parse(localStorage.getItem('timeFormat'));
  const dateFormat = JSON.parse(localStorage.getItem('dateFormat'));

  const settings = getSettingsSort(commonProps.navData);
  const aggregations = getAggregations(commonProps.navData);
  

  return (
    <GridSortContext.Provider
      value={{
        gridData,
        setGridData,
        gridSortInstance,
        gridEditData,
        setGridEditData,
        globalKeyEvent,
        currentWorkspaceUuid,
        setCurrentWorkspaceUuid,
        workspaces,
        t,
        timeFormat,
        dateFormat,
        settings,
        aggregations,
        ...commonProps
      }}
    >
      {children}
    </GridSortContext.Provider>
  );
};

GridSortProvider.propTypes = {
  children: PropTypes.node.isRequired,
  commonProps: PropTypes.object
};
