import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DatePickerComponent, DateTimePicker, DateTimePickerComponent, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { useTranslation } from 'react-i18next';
import { loadCldr } from '@syncfusion/ej2-base';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import { L10n } from '@syncfusion/ej2-base';
import PropTypes from 'prop-types';
import { FormInputContainerStyled, FormInputLabelContainerStyled, ValidationLabelStyled } from './styles';
import { toast } from '../../../../utils/notification';
import { setFieldLabel } from './utilis/setFieldLabel';

DateTimePickerComponent.Inject(MaskedDateTime);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
L10n.load({
  'de': {
    'datetimepicker': {
      placeholder: 'Wählen Sie ein Datum und eine Uhrzeit aus',
      today: 'heute'
    }
  }
});

const DateTimeField = ({ field, isLocked, value, setNewValue, renderData }) => {
  const [date, setDate] = useState(null);
  const dateTimepickerInstance = useRef(null);
  const datepickerInstance = useRef(null);
  const { t } = useTranslation();

  const timeFormat = JSON.parse(localStorage.getItem('timeFormat'));
  const dateFormat = JSON.parse(localStorage.getItem('dateFormat'));

  const maxDate = new Date('1/1/2100 0:00 AM');

  useEffect(() => {
    setDate(setDateTimeValue(value));
  }, []);


  const setDateTimeValue: any = (value: any) => {
    let date: Date;
    if (value) {
      switch (typeof (value)) {
        case 'string':
        case 'number':
          date = new Date(value);
          break;
        case 'object':
          date = new Date(
            Date.UTC(
              value.year,
              value.month - 1,
              value.day, value.hour,
              value.minute,
              value.second
            )
          );
          break;
        default:
          date = null;
      }
      return date;
    } else {
      return null;
    }
  };


  useEffect(() => {
    try {
      setNewValue(new Date(date).toISOString() ?? new Date().toISOString());
    } catch (error) {
      toast.warning(field.attribute.name, `${error.message}: ${value}`);
    }
  }, [date]);


  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>
        {setFieldLabel(field, renderData, value, t)}
      </FormInputLabelContainerStyled>
      <ValidationLabelStyled id={`validation-label-${field.attribute.key}`}></ValidationLabelStyled>
      <div className='form-input-element'>
        {field.type === 'datetime' ?
          <DateTimePickerComponent
            ref={(scope) => { dateTimepickerInstance.current = scope; }}
            firstDayOfWeek={1}
            openOnFocus={true}
            className='form-input-element'
            name="dateTime"
            placeholder={value ? setDateTimeValue(value) : ''}
            change={(args) => setDate(args.value)}
            format={`${dateFormat} ${timeFormat}`}
            value={date}
            enabled={!isLocked}
            timeFormat={timeFormat}
            enableMask={true}
            max={maxDate}
          /> :
          <DatePickerComponent
            ref={datepickerInstance}
            className='form-input-element'
            name="date"
            value={date}
            change={(args) => setDate(args.value)}
            enabled={!isLocked}
            format={dateFormat}
            max={maxDate}
          />
        }
      </div>
    </FormInputContainerStyled>
  );
};

DateTimeField.propTypes = {
  field: PropTypes.any,
  isLocked: PropTypes.bool,
  value: PropTypes.any,
  hiddenClass: PropTypes.string,
  setNewValue: PropTypes.func,
  renderData: PropTypes.object
};

export default DateTimeField;
