import { run as runActions } from '../../../../../store/workspaces/workspaces/run';


export  const handleIconClick = (element: any, e: any, currentDataID) => {
  const eventApi = element.events?.find(event => event?.type === 'onClick');
  const parameters = { id: currentDataID };
  const { modifiers, key } = eventApi;

  if (!eventApi) return null;


  window.dispatchEvent(
    new CustomEvent(
      'custom-mousedown',
      {
        detail: {
          key: key,
          id: currentDataID,
          modifiers: modifiers,
          event: e,
          context: 'context'
        }
      }
    )
  );

  if (!e.ctrlKey && !modifiers?.ctrl && !modifiers?.alt && !modifiers?.shift) {
    runActions(eventApi?.key, currentDataID, parameters);
  }
};