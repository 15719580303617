import { FormValidator } from '@syncfusion/ej2-inputs';
import { useContext, useEffect } from 'react';
import { LoginContext } from 'src/pages/login/context/Login.context';

export const useSetFormValidator = () => {
  const {
    setUser,
    setResetToken,
    setNewPasswordDialogOpen,
    setResetTokenAPI
  } = useContext(LoginContext);

  useEffect(() => {

    const user = localStorage.getItem('user');
    setUser(user);

    const options = {
      rules: {
        username: {
          required: [true, '* Please enter your name'],
        }
      },
    };
    new FormValidator('#form', options);

    return () => {
      setNewPasswordDialogOpen(false);
      setResetToken('');
      setResetTokenAPI('');
    };

  }, []);
};

