/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PolicyButtonBar from '../../../module/workspaces/policy/Policy.buttonBar';
import PolicyDialog from '../../../module/workspaces/policy/Policy.dialog';
import CookieConsentComponent from '../cookies/Cookie.consent';
import { LoginContext } from '../../context/Login.context';

const LoginFooter = () => {

  const {
    dbConnected,
    policyDialogOpen,
    setPolicyDialogOpen,
    policyContent,
    setPolicyContent,
    cookiesDialogOpen,
    setCookiesDialogOpen,
    cookiesContent,
    setCookiesContent,
    policyReady,
  } = useContext(LoginContext);

  return (
    <div className={dbConnected ? 'login-footer' : 'login-footer-deactivated'}>
      <footer className="login-footer">
        <div className="login-policy-container">
          {policyReady && (
            <PolicyButtonBar
              setPolicyDialogOpen={setPolicyDialogOpen}
              setPolicyContent={setPolicyContent}
              setCookiesDialogOpen={setCookiesDialogOpen}
              setCookiesContent={setCookiesContent}
            />
          )}
          <PolicyDialog
            policyDialogOpen={policyDialogOpen}
            setPolicyDialogOpen={setPolicyDialogOpen}
            policyContent={policyContent}
          />
          <CookieConsentComponent
            cookiesDialogOpen={cookiesDialogOpen}
            setCookiesDialogOpen={setCookiesDialogOpen}
            cookiesContent={cookiesContent}
            type="login"
          />
        </div>
      </footer>
    </div>
  );
};

LoginFooter.propTypes = {
  policyReady: PropTypes.bool,
  policyDialogOpen: PropTypes.bool,
  setPolicyDialogOpen: PropTypes.func,
  policyContent: PropTypes.any,
  cookiesDialogOpen: PropTypes.bool,
  setCookiesDialogOpen: PropTypes.func,
  cookiesContent: PropTypes.any,
  setPolicyContent: PropTypes.func,
  setCookiesContent: PropTypes.func,
  dbConnected: PropTypes.bool,
};

export default LoginFooter;
