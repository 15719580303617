import Cookies from 'js-cookie';

export const setCookie = (name, value, expiredDays) => {
  return Cookies.set(name, value, { expired: expiredDays });
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const isCookieValid = (name) => {

  const cookie = getCookie(name);

  if (cookie) {
    const cookieData = Cookies.getJSON(name);
    const expirationTime = new Date(cookieData.expires);

    if (expirationTime < new Date()) {
      return true;
    } else {
      Cookies.remove(name);
      return false;
    }
  } else {
    return false;
  }
};