import { useContext, useEffect } from 'react';
import { GridViewContext } from '../context/Grid.view.context';
import { setKeyDownRemoveFilter } from '../../services/setKeydownEvent';
import { setAggregationHeight } from '../../services/setAggregationHeight';

export const useEventListener = () => {

  const {
    gridViewInstance,
    navData
  } = useContext(GridViewContext);
    

  useEffect(() => {
    const handleKeyDown = (event) => setKeyDownRemoveFilter(event, gridViewInstance.current);

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('resize', setAggregationHeight);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', setAggregationHeight);
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);
};
