import { toast } from 'src/utils/notification';
import { run as runActions } from '../../../../store/workspaces/workspaces/run';

export const pointClick = (args,navData, chartData, actionsState) => {
  const eventKey = navData.widgetData.events.find(event => event.type === 'onClick')?.key;
  const id = chartData[chartData.options.datasource][args.pointIndex]?.id;
  
  if (!eventKey) {
    return toast.warning('Event is missing');
  }

  id && runActions(eventKey, id, actionsState);
};