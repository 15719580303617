import store from '../../../store/store';
import { removeModalWidget } from '../../../store/workspaces/widgets/actions';

export const closeDialog = () => {
  const { workspaces: { instances } } = store.getState();
  const activeWorkspace = instances.find(instance => instance?.isActive);
  
  const modalWidget = activeWorkspace?.widgets.widgets.find(widget => widget?.modal);
  
  if (modalWidget) {
    store.dispatch(removeModalWidget(modalWidget.key, modalWidget.dataID));
  }
};
