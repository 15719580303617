import * as React from 'react';
import { ColorPickerComponent, ColorPickerEventArgs } from '@syncfusion/ej2-react-inputs';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInputContainerStyled, FormInputLabelContainerStyled } from './styles';
import { setFieldLabel } from './utilis/setFieldLabel';

const ColorPickerField = ({ field, value, setNewValue, renderData}) => {
  const colorPickerRef = useRef(null);
  const { t } = useTranslation();

  const onChange = (args: ColorPickerEventArgs) => {
    setNewValue(args.currentValue.hex);
  };

  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>
        {setFieldLabel(field, renderData, value, t)}
      </FormInputLabelContainerStyled>
      <ColorPickerComponent
        ref={colorPickerRef}
        id='color-picker'
        value={value}
        change={onChange}
        style={{ marginTop: '20px' }}
      />
    </FormInputContainerStyled>
  );
};

ColorPickerField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  hiddenClass: PropTypes.any,
  renderData: PropTypes.object
};

export default ColorPickerField;
