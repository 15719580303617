import React from 'react';
import { ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import PropTypes from 'prop-types';
import { Separator, ZoomControlsButtonContainerStyled } from '../styles';
import MenuBar from 'src/components/MenuBar/MenuBar';

const ZoomControls = ({ setColumnWidth, navData }) => {
  const handleZoom = (value) => setColumnWidth((prevWidth) => prevWidth + value);

  const toolbarItems = [
    {
      cssClass: 'kanban-zoom-controls-btn',
      prefixIcon: 'e-icons e-zoom-in',
      text: 'Zoom Out',
      click: () => handleZoom(-10),
    },
    {
      prefixIcon: 'e-icons e-zoom-to-fit',
      text: 'Zoom Fit',
      click: () => {
        const kanbanDiv = document.getElementById(`kanban-${navData.widgetData.key}`);
        setColumnWidth(kanbanDiv.offsetWidth / (navData.widgetData.states.length + 20));
      },
    },
    {
      prefixIcon: 'e-icons e-zoom-to-fit',
      text: 'Zoom Standard',
      click: () => setColumnWidth(300),
    },
    {
      prefixIcon: 'e-icons e-zoom-out',
      text: 'Zoom In',
      click: () => handleZoom(10),
    },
  ];

  return (
    <ZoomControlsButtonContainerStyled>
      <ToolbarComponent
        items={toolbarItems}
        overflowMode="Extended"
      />
      <Separator>|</Separator>
      <MenuBar
        key={`menu-${navData.widgetData.menu.id}`}
        menu={navData?.widgetData.menu}
      />
    </ZoomControlsButtonContainerStyled>
  );
};

ZoomControls.propTypes = {
  setColumnWidth: PropTypes.func,
  navData: PropTypes.object,
};

export default ZoomControls;
