import cytoscape from 'cytoscape';
import $ from 'jquery';
import contextMenus from 'cytoscape-context-menus';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import store from '../../../store/store';
import { callFunctions } from '../../../api/functions/call.functions';

cytoscape.use(contextMenus, $);

export const contextMenu = (cy, contextData, addNodesCytoscape, removeNode, translate) => {
  const menuItems = contextData.elements.map(({ key, label, events }) => ({
    id: key,
    content: translate(label),
    selector: 'node',
    show: true,
    onClickFunction: async (item) => {
      if (!item) return;
      const eventKey = events?.find(e => e.type === 'onClick')?.key;
      const selectedElements = JSON.parse(localStorage.getItem('selectedElements')) || [];
      const data = selectedElements.length ? selectedElements : item.target.data();

      try {
        await runActions(eventKey, +item.target.data().id, { data });
        const activeData = store.getState().workspaces.instances.find(i => i.isActive).data;
        callFunctions(activeData.functions, { addNodesCytoscape, removeNode, itemPosition: item.position }, false);
      } catch (error) {
        console.log('Graph context menu error:', error);
      }
    }
  }));

  return cy.contextMenus({ menuItems });
};
