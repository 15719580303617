import React from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { toast } from '../../utils/notification';
import { writeErrorToLogFile } from '../../utils/logErrorLogfile';
import { BoundaryContainer, BoundaryContainerError, ErrorMessage, ErrorStack, ErrorWidget } from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      reloadCount: 0,
      writeLog: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  handleReloadClick = () => {
    this.setState((prevState) => ({
      reloadCount: prevState.reloadCount + 1,
    }));
  };

  componentDidUpdate() {
    const { error, reloadCount, writeLog } = this.state;

    if (error && reloadCount === 0) {
      toast.error(this.props.currentPage?.widget, error.message);
      this.setState((prevState) => ({
        reloadCount: prevState.reloadCount + 1,
      }));
    }

    if (error && reloadCount === 3 && !writeLog) {
      writeErrorToLogFile(error.message, error.stack).then(() => {
        this.setState({ writeLog: true });
      });
    }
  }

  render() {
    const { error, reloadCount } = this.state;

    if (error && reloadCount < 3) {
      return (
        <BoundaryContainer>
          <ButtonComponent
            cssClass="e-custom"
            iconCss="e-icons e-large e-refresh"
            style={{ textTransform: 'none' }}
            onClick={this.handleReloadClick}
          />
        </BoundaryContainer>
      );
    } else if (error && reloadCount === 3) {
      return (
        <BoundaryContainerError>
          {this.props.currentPage && (
            <ErrorWidget>Error at {this.props.currentPage?.widget} (key: {this.props.currentPage?.renderID}): </ErrorWidget>
          )}
          <ErrorMessage>{error?.message}</ErrorMessage>
          <ErrorStack>{error?.stack}</ErrorStack>
        </BoundaryContainerError>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
