import React, { FC, useState } from 'react';
import { Image } from 'semantic-ui-react';
import notAvailablePic from '../../../../../images/missing.png';
import { useServiceFilePreview } from './hooks/useServiceFilesPreview';
import '@syncfusion/ej2-layouts/styles/material.css';

const ImageCard: FC<any> = ({ absolutePath, imageName }) => {
  const [imageBase64, setImageBase64] = useState(null);
  const isImage = ![null, undefined].includes(absolutePath) ? absolutePath.toLowerCase().split('.').pop().match(/^(png|jpg|jpeg|gif)$/) : false;

  useServiceFilePreview(setImageBase64, absolutePath);

  return (
    <div>
      <div className="e-card" id="basic">
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-header-title"> {imageName}</div>
          </div>
        </div>
        <div className="e-card-content">
          <Image src={isImage ? imageBase64 : notAvailablePic} size="large"></Image>
        </div>
      </div>
    </div>
  );
};


export default ImageCard;
