import React from 'react';
import PropTypes from 'prop-types';
import '../styles.css';

const ImageList = ({ setExternURL }) => {

  // const select = (args) => {
  //   setSelectedElement({ ...args.data });
  // };

  return (
    <div id="sample" className="list-content">
      <input
        className="e-input textbox-external-link"
        type="url"
        id="textbox"
        placeholder="External URL"
        onChange={(e) => {
          setExternURL({url: e.target.value, type: 'image'});
        }}
      />
    </div>
  );
};


ImageList.propTypes = {
  setExternURL: PropTypes.func
};


export default ImageList;
