import moment from 'moment-timezone';

function convertFormat(format) {
  const FormatTokens = {
    'yyyy': 'YYYY',
    'yy': 'YY',
    'MM': 'MM',
    'dd': 'DD',
    'hh': 'HH',
    'h': 'H',
    'mm': 'mm',
    'ss': 'ss',
    'S': 'SSS',
    'a': 'A',
    'EEE': 'ddd',
    'EEEE': 'dddd',
  };

  const regex = new RegExp(Object.keys(FormatTokens).join('|'), 'g');
  return format?.replace(regex, match => FormatTokens[match]);
}

const dateFromObject = (dateObj): Date => {
  const {
    year,
    month,
    day,
    hour,
    minute,
    second,
    nanosecond,
    timeZone
  } = dateObj;

  const dateTime = {
    year: year,
    month: month - 1,
    day: day,
    hour: hour,
    minute: minute,
    second: second,
    millisecond: Math.floor(nanosecond / 1000000)
  };

  const date = moment.tz(
    dateTime,
    timeZone
  ).toDate();

  return date;
};

const formatDate = (date, dateFormat): string => {
  return moment(date).format(convertFormat(dateFormat));
};

const formatTime = (date, timeFormat): string => {
  return moment(date).format(convertFormat(timeFormat));
};

const formatDateTime = (date, dateFormat, timeFormat): string => {
  return `${formatDate(date, dateFormat)} ${formatTime(date, timeFormat)}`;
};


export {
  dateFromObject,
  formatDate,
  formatTime,
  formatDateTime
};