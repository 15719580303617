import store from '../../store/store';
import { runWorkflow } from '../../api/workflow/runWorkflow';
import { openNewWorkspace } from '../../store/workspaces/workspaces/actions';

export const loadTab = async (params) => {
  const activeInstance = store.getState().workspaces.instances.find(instance => instance.isActive);
  const response = await runWorkflow(parseInt(params.key), params.id, { ...activeInstance.data }, null, true);
  const currentWidgets = activeInstance.widgets;

  await store.dispatch(openNewWorkspace({...response.params, key: parseInt(params.key)}, currentWidgets, params.context));
};
