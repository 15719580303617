export const updateWidgetsReduce = (state, action) => {
  const { instances } = state;
  const activeInstance = instances.find(instance => instance.isActive);
  activeInstance.widgets = {
    widgets: action.widgets,
    layout: action.layout || activeInstance.widgets.layout
  };

  return {
    ...state, 
    instances: [...instances]
  };
};


export const removeModalWidgetReduce = (state, action) => {
  const { instances } = state;
  const updatedWorkspaces = instances.map((instance) => {
    if (instance.isActive) {
      const updatedWidgets = instance.widgets.widgets.filter(widget => widget.key !== action.key);
      instance.widgets.widgets = updatedWidgets;
    }
    return instance;
  });
    
  return {
    ...state,
    instances: updatedWorkspaces,
  };
};