import { useContext, useEffect } from 'react';
import { KanbanContext } from '../context/Kanban.context';

export const useSetCurrentWorkspace = () => {
  const {
    setCurrentWorkspaceUuid,
    workspaces
  } = useContext(KanbanContext);

  useEffect(() => {
    const currentWorkspace = workspaces?.find((w) => w.isActive);
    if (currentWorkspace) {
      setCurrentWorkspaceUuid(currentWorkspace.uuid);
    }
  }, []);
};
