import { log } from '../../../../../utils/notification';
import { runStaticQuery } from '../../../../../api/query/run.staticQuery';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const service = async (storeName: string, params: any) => {

  try {
    return runStaticQuery('target', { ...params });
  } catch (error) {
    log('StoredProcedure (alias linkService)', { params: params, response: { message: error.message } }, 'Error');
  }
};
