export const setChatOpenReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          chat: {
            ...instance.chat, 
            isOpen: action.isOpen,
            id: action.id
          },
        };
      }
      return instance;
    }),
  };
};

export const setChatPostsReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          chat: {
            ...instance.chat, 
            posts: action.posts
          },
        };
      }
      return instance;
    }),
  };
};

export const setChatFilterMode = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          chat: {
            ...instance.chat, 
            filterMode: action.filterMode
          },
        };
      }
      return instance;
    }),
  };
};

export const addPostTopReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          chat: {
            ...instance.chat, 
            posts: [...action.loadedPosts, ...instance.chat.posts]
          },
        };
      }
      return instance;
    }),
  };
};

export const addPostBottomReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (action.loadedPosts.chatId === instance.chat.id) {
        return {
          ...instance,
          chat: {
            ...instance.chat, 
            posts: [...instance.chat.posts, action.loadedPosts]
          },
        };
      }
      return instance;
    }),
  };
};

export const setChatVisibleReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          chat: {
            ...instance.chat, 
            visible: action.isVisible
          },
        };
      }
      return instance;
    }),
  };
};

export const setBadgeVisibleReduce = (state, action) => {
  return {
    ...state,
    badge: {
      visible: action.isVisible,
      content: action.content
    }
  };
};