import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { langs } from '@uiw/codemirror-extensions-langs';
import { githubLight } from '@uiw/codemirror-theme-github';
import CodeMirror from '@uiw/react-codemirror';
import { FormInputLabelContainerStyled } from './styles';
import { toast } from '../../../../utils/notification';
import { setFieldLabel } from './utilis/setFieldLabel';

const CodeField: FC<any> = ({ viewType, field, value, setNewValue, renderData }) => {
  const Editable: boolean = ['create', 'edit'].includes(viewType);
  const { t } = useTranslation();
  const [extensions, setExtensions] = useState(null);
  const codeMirrorInstance = useRef(null);
  let lang: string = null;

  useEffect(() => {
    switch (field.type) {
      case 'diagran':
        lang = 'json';
        break;
      case 'code':
        lang = 'html';
        break;
      default:
        lang = field.type;
        break;
    }
    if (
      lang !== null &&
      // eslint-disable-next-line no-prototype-builtins
      langs.hasOwnProperty(lang) &&
      typeof langs[lang] === 'function'
    ) {
      setExtensions(langs[lang]());
    } else {
      toast.warning('code-field', `mode for ${lang} not found`);
    }

  }, [field.type]);

  return (
    <div className={'form-input-container-fullwidth'}>
      <FormInputLabelContainerStyled>
        {setFieldLabel(field, renderData, value, t)}
      </FormInputLabelContainerStyled>
      <div className={`code-field ${Editable === false ? 'readonly' : ''}`} style={{ height: field.height + 3 + 'px' }}>
        <CodeMirror
          ref={codeMirrorInstance}
          width={'100%'}
          onChange={(value: any) => {
            setNewValue(value);
          }}
          readOnly={Editable === true ? false : true}
          value={value ? value : ''}
          extensions={extensions}
          theme={githubLight}
          basicSetup={{
            foldGutter: true,
            dropCursor: true,
            allowMultipleSelections: true,
            indentOnInput: true,
          }}
        />
      </div>
    </div>
  );
};

export default CodeField;
