import { setPosts } from '../../../../store/workspaces/chat/actions';
import { setLoaderVisible } from '../../../../store/loader/actions';
import store from '../../../../store/store';
import { getPosts } from '../../../../api/services/chat/post.get';

export const getPostsData = async (workspaceActions, navData) => {
  const params = {
    id: workspaceActions?.id,
    limit: 20
  };

  try {
    await store.dispatch(setLoaderVisible(true));
    const response = await getPosts(params);
    await store.dispatch(setPosts(response?.message || []));
    console.log(
      `%c ${navData.widgetData.widget} (key: ${navData.widgetData.key}) %c `,
      'background:#FF8800; color: #000',
      'background: transparent; color: black',
      {
        params: workspaceActions,
        widgetData: navData.widgetData,
        posts: response?.message
      }
    );
    await store.dispatch(setLoaderVisible(false));
  } catch (error) {
    console.log('Error getting posts: ', error);
  }
};

