/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from '../utils/notification';
import store from '../store/store';
import { getThemes } from '../store/getThemes/action';
import { jwtDecrypt } from '../api/services/tools/service.jwtDecrypt';
import { run as runActions } from '../store/workspaces/workspaces/run';
import request from '../request/request';

const ProtectedRoute = ({ children }) => {
  const jwtString = window.location.pathname.split('app/')[1];
  const [ sessionValid, setSessionValid ] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (jwtString) {
        try {
          const response = await jwtDecrypt(jwtString);
          runActions(response.data.key, response.data.id, response.data);
        } catch (error) {
          toast.error('Error fetching data:', error.message);
          throw error;
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSessionValidity = async () => {
      const { valid } = await request(false, 'GET', 'public/check-session', {});
      if(!valid){
        setSessionValid(false);
        window.location.href = '/login';
        // return <Navigate to='/login' />;
      }else{
        setSessionValid(true);
      }
    };
    fetchSessionValidity();
  }, []);

  if(!sessionValid) return;
  
  store.dispatch<any>(getThemes());
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.any
};

export default ProtectedRoute;
