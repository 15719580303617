import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { goBackBreadcrumbAction } from '../../../store/workspaces/breadcrumb/actions';
import { useTranslation } from 'react-i18next';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import PropTypes from 'prop-types';
import { BreadcrumbIconStyled, BreadcrumbItemStyled, BreadcrumbLabelStyled } from './styles';
import { updatePinnedWorkspaceAPI } from '../../../api/services/workspaces/updateWorkspacePinned';

const PageItem = ({ itemIndex, params, isLast }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSectionClick = () => {
    runActions(params.key, params.id, params ?? {});
    dispatch(goBackBreadcrumbAction(itemIndex));
    updatePinnedWorkspaceAPI();
  };

  const setName = (name) => {
    const trimmedName = t(name)?.slice(0, 30);

    if (!trimmedName || trimmedName.length === 0) {
      return t('no title');
    }

    if (trimmedName.length < t(name)?.length) {
      return trimmedName + ' ...';
    }

    return trimmedName;
  };



  return (
    <Breadcrumb.Section
      link
      key={`key-${params.keyID}`}
      onClick={() => handleSectionClick()}
    >
      <BreadcrumbItemStyled>
        <BreadcrumbIconStyled 
          className={`e-icons e-${params.config?.icon?.name}`} 
          iconcolor={params.config?.icon?.color}
        />
        <BreadcrumbLabelStyled>{setName(params.config?.label?.name)}</BreadcrumbLabelStyled>
        {!isLast && <Breadcrumb.Divider icon="right chevron" />}
      </BreadcrumbItemStyled>
    </Breadcrumb.Section>
  );
};

PageItem.propTypes = {
  itemIndex: PropTypes.number,
  params: PropTypes.object,
  isLast: PropTypes.bool, 
};
export default PageItem;
