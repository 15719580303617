
import React, { useEffect, useRef, useState } from 'react';
import CryptoJS from 'crypto-js';
import { fileTypeFromBuffer } from 'file-type';
import { AsyncSettingsModel, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { useSelector } from 'react-redux';
import { closeMultiUpload } from '../../../store/workspaces/modals/actions';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useTranslation } from 'react-i18next';
import { log, toast } from '../../../utils/notification';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import { runStaticQuery } from '../../../api/query/run.staticQuery';
import store from '../../../store/store';
import './styles.css';
import { WidgetContainerStyled, WidgetLabelStyled } from '../styles';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
// import request from '../../request/request';

type FileType = {
  code: string;
  extensions: Array<string>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sanitizeFile = async (file: any, fileTypes: Array<FileType>) => {
  const rawFile: File = file.rawFile;
  const extension: string = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2);
  const buffer = await rawFile.arrayBuffer();
  const hash: string = await CryptoJS.MD5(CryptoJS.lib.WordArray.create(buffer)).toString();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let type: any = await fileTypeFromBuffer(buffer);

  if (
    type === undefined ||
    (
      type.mime === 'application/xml' && extension.toLowerCase() === 'svg'
    )
  ) {
    const fileType = fileTypes.find(fileType => fileType.extensions.includes(`.${extension}`));

    type = {
      ext: extension,
      mime: fileType.code
    };
  }

  const sanitizedName: string = await encodeURIComponent(rawFile.name);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sanitizedFile: any = new File([rawFile], sanitizedName, { type: type.mime });
  file.name = sanitizedName;
  file.mime = type.mime;
  file.hash = hash;
  file.rawFile = sanitizedFile;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hashExists = async (upload: any, hash: string) => {
  return await runStaticQuery('getFileByHash', { upload: upload, hash: hash })
    .then(
      fileNode => {
        return fileNode.exists;
      }
    )
    .catch((error) => {
      toast.error('', error.message);
    });
};

const getSettings = async (): Promise<AsyncSettingsModel> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const settings: AsyncSettingsModel = {
      saveUrl: `${process.env.REACT_APP_BACKEND_URL}services/file/upload`
    };
    return settings;
  } catch (error) {
    throw error;
  }
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UploaderWidget: React.FC<any> = () => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalStates = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive).modals);
  const { fileTypes } = modalStates.multiUpload.widgetData;
  const dialogRef = useRef<DialogComponent | null>(null);

  const buttons = [
    {
      id: 'uploader-browse',
      cssClass: 'e-custom upload-button',
      iconCss: 'e-icons e-file-new',
      key: 'browse',
      onClick: () => document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button')?.click(),
    },
    {
      id: 'uploader-clear-all',
      cssClass: 'e-custom uploader-button',
      content: 'clear all',
      style: { marginLeft: '10px' },
      key: 'clear all',
      onClick: () => {
        setFiles([]);
        uploaderInstance.clearAll();
      },
    },
    {
      id: 'uploader-upload',
      cssClass: 'e-custom uploader-button',
      content: 'upload',
      style: { marginLeft: '10px' },
      key: 'upload',
      onClick: () => uploaderInstance.upload(),
    },
  ];

  const getAllowedMimeTypes = () => {
    const allowedMimeTypes = fileTypes.map(fileType => fileType.code);
    return allowedMimeTypes;
  };

  const getAllowedExtensions = () => {
    const allowedExtensions = fileTypes.map(fileType => fileType.extensions).flat().join(',');
    return allowedExtensions;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const workspaceActions = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive)?.data);
  const [uploaderInstance, setUploaderInstance] = useState(null);
  const [files, setFiles] = useState([]);
  const [settings, setSettings] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    log(
      `${modalStates.multiUpload.widgetData.widget} (key: ${modalStates.multiUpload.widgetData.key})`,
      {
        params: { fileTypes: modalStates.multiUpload.widgetData.fileTypes },
        response: { upload: modalStates.multiUpload.widgetData.upload }
      },
      'Widget'
    );

    const getAsyncSettings = async () => {
      try {
        setSettings(await getSettings());
      } catch (error) {
        // Handle errors
      }
    };

    getAsyncSettings();
  }, []);

  let dropContainerRef;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectFile = (args: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args.filesData.forEach(async (file: any) => {
      await sanitizeFile(file, fileTypes)
        .then(async () => {
          await hashExists(modalStates.multiUpload.widgetData.upload, file.hash)
            .then((exists) => {
              let error = false;

              if (exists === true) {
                error = true;
                toast.warning('File already exists on server: ', file.name);
                args.cancel = true;
                uploaderInstance.remove(file);
              }
              if (files.some(element => file.rawFile.hash === element.hash)) {
                error = true;
                toast.warning('File already exists in the upload list: ', file.name);
                args.cancel = true;
                uploaderInstance.remove(file);
              }
              if (!getAllowedMimeTypes().includes(file.mime)) {
                error = true;
                toast.warning(`MIME type ${file.mime} is not allowed: `, file.name);
                args.cancel = true;
                uploaderInstance.remove(file);
              }
              if (file.statusCode === '0') {
                error = true;

                toast.warning(`File type ${file.type} is not allowed: `, file.name);
                args.cancel = true;
                uploaderInstance.remove(file);
              }
              if (error === false)
                setFiles(files => [...files, file?.rawFile]);
            });

        });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRemoveFile = (args: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const element: any = args.filesData[0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFiles(files.filter((elem: any) => {
      return !(elem?.hash === element?.rawFile.hash);
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFailure = (args: any) => {
    if (args.e.currentTarget.statusText !== '')
      toast.error('', args.e.currentTarget.statusText);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUploading = (args: any) => {
    const params = {
      directory: workspaceActions?.directory,
      upload: modalStates.multiUpload.widgetData.upload
    };
    args.customFormData.push({ 'params': JSON.stringify(params) });
  };


  const onActionComplete = () => {
    const eventKey = modalStates.multiUpload.widgetData?.events?.find((event) => event.type === 'onClick')?.key;
    runActions(eventKey, workspaceActions.id, { ...workspaceActions, upload: modalStates.multiUpload.widgetData.upload });
  };


  return (
    <WidgetContainerStyled>
      <DialogComponent
        ref={dialogRef}
        visible={modalStates.multiUpload.isOpen}
        width={`${modalStates.multiUpload.widgetData.width}px`}
        height={`${modalStates.multiUpload.widgetData.height}px`}
        showCloseIcon={true}
        closeOnEscape={true}
        close={() => {
          store.dispatch(closeMultiUpload({}));
        }}
        target="#root"
        className={'dialog-upload-container'}
      >
        <div className="modal-header">
          {modalStates.multiUpload.widgetData.label && (
            <WidgetLabelStyled>{t(modalStates.multiUpload.widgetData.label)}</WidgetLabelStyled>
          )}

        </div>
        <div style={{ display: 'flex' }}>
          {buttons.map((button) => (
            <ButtonComponent
              id={button.id}
              cssClass={button.cssClass}
              iconCss={button.iconCss}
              content={button.content}
              style={{ textTransform: 'none', ...button.style }}
              key={button.key}
              onClick={button.onClick}
            />
          ))}
        </div>
        <div className="dialog-upload-preview" ref={dropContainerRef}>
          <span className="e-icons e-plus e-medium" id="dropText" ref={dropContainerRef} />
          <UploaderComponent
            locale={JSON.parse(localStorage.getItem('language'))}
            id='file-upload'
            type='file'
            sequentialUpload={true}
            ref={(scope) => setUploaderInstance(scope)}
            autoUpload={false}
            asyncSettings={settings}
            allowedExtensions={getAllowedExtensions()}
            maxFileSize={50 * 1024 * 1024}
            removing={onRemoveFile}
            selected={onSelectFile}
            failure={onFailure}
            uploading={onUploading}
            actionComplete={onActionComplete}
          />
        </div>
      </DialogComponent>
    </WidgetContainerStyled>
  );
};
export default UploaderWidget;