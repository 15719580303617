export const addTrailingSlash = (url: string) => {
  if (!url.match(/\/$/)) {
    url += '/';
  }
  return url;
};

export const objectToString = (obj: object) => {
  const parts = [];
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number') {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }
  return `${parts.join('&')}`;
};