import types from '../types';

// Upload

export const openMultiUpload = (widgetData) => {
  return {
    type: types.OPEN_MULTIUPLOAD_MODAL,
    widgetData: widgetData
  };
};

export const closeMultiUpload = (widgetData) => {
  return {
    type: types.CLOSE_MULTIUPLOAD_MODAL,
    widgetData: widgetData
  };
};

// Richtext

export const closeDialogStore = () => {
  return {
    type: types.CLOSE_RICHTEXT_MODAL
  };
};

export const setTextSelection = (selectedText: any) => {
  return {
    type: types.SET_TEXT_SELECTION,
    selectedText: selectedText
  };
};

export const openRichtextDialog = (params: any, imageEvent: any, linkEvent: any, diagramEvent: any, eventType: any) => {
  return {
    type: types.OPEN_RICHTEXT_MODAL,
    params: params,
    imageEvent,
    linkEvent,
    diagramEvent,
    eventType
  };
};

// Dialog

export const setIsDialogOpen = (isOpen: boolean) => {
  return {
    type: types.SET_DIALOG_OPEN,
    isOpen: isOpen
  };
};


// Modal

export const setModalOpen = (isOpen: boolean) => {
  return {
    type: types.SET_MODAL_OPEN,
    isOpen: isOpen
  };
};
