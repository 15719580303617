import React from 'react';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { FilterFieldContainerStyled } from '../../../../styles';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { onTextSearch } from './services/onTextSearch';
import { onMultiSelectSearch } from './services/onMultiSelectSearch';
import { onRemoved } from './services/onRemoved';
import { useTranslation } from 'react-i18next';

const FilterField = ({ filter, navData, setFilter }) => {
  const { t } = useTranslation();
  let filterField;

  switch (filter.type) {
    case 'string':
      filterField = (
        <>
          <TextBoxComponent
            placeholder={t(filter.label)}
            showClearButton={true}
            input={(args) => onTextSearch(args, filter, setFilter)}
            cssClass="kanban-field-remove-line"
          />
        </>
      );
      break;
    case 'multiSelect':
      filterField = (
        <MultiSelectComponent
          key={`ms-${filter.id}`}
          placeholder={t(filter.label)}
          className='form-input-element'
          dataSource={filter.data.options}
          select={(args) => onMultiSelectSearch(args, filter, setFilter)}
          removed={(args) => onRemoved(args, setFilter)}
          fields={{ text: 'label', value: 'id' }}
          itemTemplate={(args) => <div className="multiselect-item" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(args.label) }} />}
          cssClass="kanban-field-remove-line"
          mode='Box'
        />
      );
      break;
    default:
      filterField = null;
      break;
  }

  return (
    <FilterFieldContainerStyled count={navData.widgetData.filters.length}>
      {filterField}
    </FilterFieldContainerStyled>
  );
};

FilterField.propTypes = {
  filter: PropTypes.object,
  navData: PropTypes.any,
  setFilter: PropTypes.func,
  setOperators: PropTypes.func
};


export default FilterField;
