import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { runStaticQuery } from '../api/query/run.staticQuery';
import { run as runActions } from '../store/workspaces/workspaces/run';
import ModulePage from '../pages/module/page.module';
import NotFoundPage from '../pages/notFound/page.notFound';
import ProtectedRoute from './Route.protected';
import request from '../request/request';
import { LoginProvider } from 'src/pages/login/context/Login.context';
import LoginPage from '../pages/login/Page.login';


const RoutesCustom = () => {
  const jwtString = window.location.pathname.split('app/')[1];
  const [isSessionValid, setIsSessionValid] = useState(true);
  const expires = new Date();
  expires.setTime(expires.getTime() + (60 * 1000));


  useEffect(() => {
    const fetchWorkspaceData = async () => {
      const { valid } = await request(false, 'GET', 'public/check-session', {});
      setIsSessionValid(valid);
      if (!valid) {
        return;
      }
      const response = await runStaticQuery('workspace', null);
      const onLoadEvent = response?.workspace?.events?.find((event) => event?.type === 'onLoad') ?? null;

      if (!jwtString && onLoadEvent) {
        await runActions(onLoadEvent.key, null, null);
      }
    };

    fetchWorkspaceData();
  }, [jwtString]);


  useEffect(() => {

    const logoutOn2kDialog = async () => {
      if (JSON.parse(localStorage.getItem('2k-dialog'))) {
        await request(true, 'POST', 'public/user/logout');
        localStorage.removeItem('2k-dialog');
      }
    };

    const fetchSessionValidity = async () => {
      const { valid } = await request(false, 'GET', 'public/check-session', {});
      setIsSessionValid(valid);
    };
    logoutOn2kDialog();
    fetchSessionValidity();
  }, []);



  return (
    <Routes>
      <Route path="/login" element={    <LoginProvider><LoginPage /> </LoginProvider>} />
      <Route path="/" element={<Navigate to='/app' />} />
      {isSessionValid ? <Route path="/app" element={<ProtectedRoute><ModulePage /></ProtectedRoute>} />: null }
      {isSessionValid ? <Route path="/app/:jwtString" element={<ProtectedRoute><Navigate to='/app' /></ProtectedRoute>} /> : null }
      <Route path="/404" element={<NotFoundPage />} />
    </Routes>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <RoutesCustom />
    </BrowserRouter>
  );
};

export default App;
