import React, { useContext } from 'react';
import { withRouterHooks } from '../../../routes/router.hooks';
import './styles.css';
import { ContextMenuContext } from './context/ContextMenu.context';
import ContextItem from './components/ContextMenu.item';
import { Menu } from 'semantic-ui-react';
import { ContextMenuItemStyled } from './styles';

const ContextWidget = () => {

  const {
    navData,
  } = useContext(ContextMenuContext);
  

  return (
    <Menu vertical style={{ width: '100%' }}>
      {
        navData.widgetData.elements.map((element, index) => (
          <ContextMenuItemStyled
            key={`key-${index}`}
            count={element.count ?? 0}
          >

            <ContextItem
              key={`key-${index}`}
              context={element}
              currentDataID={navData.widgetData.dataID}
              timestamp={navData.widgetData.timestamp}
            />
          </ContextMenuItemStyled>
        ))}
    </Menu>
  );
};

export default withRouterHooks(ContextWidget);
