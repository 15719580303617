import React, { useState, useEffect, useRef } from 'react';
import fetchTreeGridData from './services/fetchTreeGridData';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import {
  Edit,
  Group,
  Selection,
  CommandColumn,
} from '@syncfusion/ej2-react-grids';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  ExcelExport,
  Filter,
  Inject,
  Page,
  PageSettingsModel,
  PdfExport,
  Resize,
  RowDD,
  Sort,
  TreeGridComponent,
  Toolbar,
  Aggregate,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  VirtualScroll
} from '@syncfusion/ej2-react-treegrid';
import { WidgetProvider } from '../WidgetContext';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../styles';
import { handleExpandLevel } from './services/handleExpandLevel';
import { ToolbarTemplate } from './services/ToolbarTemplate';
import './styles.css';
import { useSelector } from 'react-redux';

const TreeGridWidget = ({ navData, actionsState}) => {
  const [treeGridData, setTreeGridData] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [treeGridEditData, setTreeGridEditData] = useState(null);
  const { t } = useTranslation();
  const [currentWorkspaceUuid, seCurrentWorkspaceUuid] = useState(null);
  const workspaces = useSelector((state: any) => state.workspaces.instances);
  let initial = true;

  const treegrid = useRef(null);

  const widgetContext = {
    instance: treegrid.current,
    widgetData: navData.widgetData,
    actionsState: actionsState,
    currentWorkspaceUuid: currentWorkspaceUuid
  };
  
  useEffect(() => {
    const currentWorkspace = workspaces?.find((w) => w.isActive);
    seCurrentWorkspaceUuid(currentWorkspace.uuid);
  }, []);

  const filter = { type: 'CheckBox', operator: 'contains' };
  const pageOptions: PageSettingsModel = {
    pageSize: 20,
    pageSizes: ['5', '10', '20', '50']
  };

  const filterSettings: any = {
    mode: 'Immediate',
    hierarchyMode: 'Both',
    immediateModeDelay: 400,
    type: 'FilterBar',
    operator: 'contains'
  };



  const toolbarOptions: any = [
    { template: () =>  ToolbarTemplate(navData, treegrid.current), align: 'Left' }
  ];

  useEffect(() => {
    setTreeGridData(null);
    fetchTreeGridData(setTreeGridData, { ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
    return () => {
      setTreeGridData(null);
    };
  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);



  const rowSelecting = (args) => {
    if (args.target.classList.contains('e-treegridcollapse') || args.target.classList.contains('e-treegridexpand')) {
      return null;
    }

    const rowInfo = treegrid.current.getRowInfo(args.target);
    const rowData = rowInfo.rowData;
    const eventApi = navData.widgetData.events.find((event) => event.type === 'onClick');
    const { modifiers, key } = eventApi;

    if (args.isShiftPressed) {
      treegrid.current.copy();
      return null;
    }

    window.dispatchEvent(
      new CustomEvent('custom-mousedown', {
        detail: {
          key: key,
          id: rowData?.id,
          modifiers: modifiers,
          event: { ctrlKey: args.isCtrlPressed, shiftKey: args.isShiftPressed },
          context: 'grid'
        }
      })
    );

    if (!rowData || Object.keys(rowData).length === 0) return null;
    if (!args.isCtrlPressed && !modifiers?.ctrl && !modifiers?.alt && !modifiers?.shift) {
      runActions(eventApi?.key, rowData?.id, actionsState);
    }
    args.cancel = true;
  };


  const toolbarClick = (args) => {
    const level = args.item.properties.id.toString();
    handleExpandLevel(treegrid.current, level);
  };


  const dataBound = () => {
    if(!initial) return;

    const level = navData.widgetData?.level?.toString();
    handleExpandLevel(treegrid.current, level);
    initial = false;
  };

  const footerItemCounter = (args) => {
    return <span>Items: {args.count}</span>;
  };

  if(!treeGridData){
    return null;
  }

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={widgetContext}>
        {navData.widgetData.label !== '' ? (
          <WidgetLabelStyled>{t(navData.widgetData.label)} </WidgetLabelStyled>
        ) : (
          ''
        )}
        <WidgetContentStyled>
          <TreeGridComponent
            locale={JSON.parse(localStorage.getItem('language'))}
            key={`treegrid-${navData.widgetData.id}`}
            height={'100%'}
            rowHeight={28}
            ref={treegrid}
            dataSource={treeGridData.data}
            dataBound={dataBound}
            idMapping={treeGridData?.options?.id}
            parentIdMapping={treeGridData?.options?.parentId}
            pageSettings={pageOptions}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            allowSorting={true}
            allowFiltering={true}
            filterSettings={filterSettings}
            allowResizing={true}
            allowSelection={true}           
            enableVirtualization={true}
            enableCollapseAll={navData.widgetData.level === 0 ? true : false}
            treeColumnIndex={1}
            toolbarClick={toolbarClick}
            rowSelecting={rowSelecting}
          >
            <ColumnsDirective>
              {navData.widgetData.columns.map((column) => {
                return (
                  <ColumnDirective
                    key={`field-${column.id}`}
                    field={column.name}
                    headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                    filter={filter}
                    visible={column.hide === false ? true : false}
                    disableHtmlEncode={false}
                    width={column.width}
                    minWidth={column.width}
                    // maxWidth={column.maxWidth}
                  />
                );
              })}
            </ColumnsDirective>
            <AggregatesDirective>
              <AggregateDirective showChildSummary={false}>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective columnName='ID' type='count' footerTemplate={footerItemCounter} />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject services={[Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Group, PdfExport, Selection, Resize, RowDD, Toolbar, CommandColumn, Aggregate, VirtualScroll]} />
          </TreeGridComponent>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

TreeGridWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default TreeGridWidget;
