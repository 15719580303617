import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { L10n } from '@syncfusion/ej2-base';

const translationJSON = localStorage.translations;
const translationJSONSyncfusion = localStorage.translationsSyncfusion;
const language = JSON.parse(localStorage.language ?? '{}');

try {
  L10n.load({ [language]: translationJSONSyncfusion ? JSON.parse(translationJSONSyncfusion) : null });

  const resources = {
    lng1: {
      translation: translationJSON ? JSON.parse(translationJSON) : null
    },
    lng2: {
      translation: translationJSON ? JSON.parse(translationJSON) : null
    }
  };

  i18n
    .use(backend)
    .use(detector)
    .use(initReactI18next)
    .init({
      resources,
      lng: localStorage.getItem('i18nextLng'),
      fallbackLng: 'lng1',
      detection: {
        order: ['localStorage', 'i18nextLng'],
        lookupQuerystring: 'i18nextLng',
        lookupLocalStorage: 'i18nextLng',
        caches: ['localStorage']
      },
      interpolation: {
        escapeValue: false
      },
      compatibilityJSON: 'v1',
      react: { useSuspense: false }
    });

} catch (error) {
  console.error('Error initializing i18n:', error);
}

export default i18n;
