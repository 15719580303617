import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';


export const GridStyled = styled(Grid)`
  margin: 10px !important;
  overflow-y: auto;
  overflow-x: hidden;
  display: block !important;

  .row,
  .column {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .form-input-container-fullwidth .form-input-element {
    width: 100%;
    padding: 10px;
  }

  .form-input-container-fullwidth label {
    margin-bottom: 10px;
    margin-top: 15px;
    width: 100%;
  }

  .form-input-container-fullwidth .parsedHTML {
    padding: 10px 15px 15px;
    background: white;
    opacity: 0.75;
  }

  code,
  .form-input-container-fullwidth .form-input-element {
    padding: 10px !important;
    border-radius: 3px !important;
  }

  .code-field,
  .structured-list-field {
    border: 1px solid !important;
    border-color: #949494 !important;
    resize: vertical !important;
    overflow: auto !important;
    padding: 1px !important;
  }

  .code-field.readonly > .cm-theme .cm-selectionLayer .cm-selectionBackground {
    background-color: lightgoldenrodyellow !important;
  }

  .code-field.readonly {
    border: none !important;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0),
      linear-gradient(rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0),
      linear-gradient(-90deg, rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0),
      linear-gradient(180deg, rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0);
    background-position: top, right, bottom, left !important;
    background-repeat: repeat-x, repeat-y !important;
    background-size: 4px 1px, 1px 4px !important;
  }

  .code-field.readonly,
  .code-field.readonly *,
  .e-disabled {
    cursor: default !important;
  }

  .code-field.readonly > .cm-theme,
  .code-field.readonly > .cm-theme * {
    color: #949494 !important;
  }

  .code-field.readonly > .cm-theme .cm-cursor {
    display: none !important;
  }

  .code-field.readonly > .cm-theme .cm-gutterElement.cm-activeLineGutter,
  .code-field.readonly > .cm-theme .cm-line.cm-activeLine {
    background-color: transparent !important;
  }

  .form-input-element {
    display: inline-flex;
    width: calc(100%);
  }

  textarea.form-input-element {
    padding: 10px;
  }

  .form-input-element div:not(.e-treeview div) {
    width: 100%;
  }
`;

export const FormWidgetContainerStyled = styled.div`
  background-color: white;
  border: 1px solid !important;
  border-color: rgb(225, 220, 220) !important;
  height: calc(100% - 95px) !important;
  max-height: calc(100% - 80px);
  border-radius: 5px !important;
  overflow-y: auto;

  .segment.pushable {
    border: 0;
    box-shadow: none;
  }

  .ui.tabular.menu .active.item {
    border: 0;
  }

  .modal .content {
    height: calc(100vh - 170px);
    overflow-x: hidden;
  }

  .form-input-element .htmlContent {
    margin: 2px 2px 2px 2px;
    padding: 2px 2px 5px 2px;
  }

  .password-field>span>.input>input,
  .email-field>span>.input>input,
  .email-field>span>a,
  .url-field>span>.input>input,
  .url-field>span>a {
    border: none !important;
    min-height: 28px !important;
    padding: 0px !important;
    margin: 6px 3px 0px 0px;
  }

  .password-strength-bar {
    width: 100%;
  }

  img {
    object-fit: contain;
  }

  .cm-gutterElement {
    padding: 0px 0px;
    margin-left: 10px;
    float: right !important;
  }

  .cm-gutters {
    background-color: rgb(239, 239, 239) !important;
    color: #949494 !important;
  }

  .dynamicColumn.tight .form-input-label {
    flex: 1 1 100%;
    display: inline-block;
    margin-bottom: 3px;
  }

  .tox-tinymce {
    width: 100%;
  }

  .hidden {
    display: none;
  }

  .ui.default.dropdown:not(.button)>.dropdown.icon,
  .ui.dropdown:not(.button)>.dropdown.icon {
    padding: 9px 0px 0px 0px;
  }

  .ui.default.dropdown:not(.button)>.text,
  .ui.dropdown:not(.button)>.default.text {
    color: rgba(0, 0, 0, 0.87);
  }

  .ui.selection.dropdown.isLocked,
  .ui.dropdown.isLocked {
    cursor: default;
  }

  .disabled *,
  .ui.form .disabled.field,
  .ui.form .disabled.fields .field,
  .ui.form .field :disabled {
    color: #000 !important;
    opacity: 1;
  }

  .e-input[disabled],
  .e-input-group .e-input[disabled],
  .e-input-group.e-control-wrapper .e-input[disabled],
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled,
  .e-float-input input[disabled],
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input textarea[disabled],
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .e-float-input.e-control-wrapper textarea:not(:valid),
  .e-float-input.e-control-wrapper textarea:valid {
    background-color: rgb(248, 248, 248);
    border: none;
  }

  .ui.selection.dropdown {
    min-height: 0px;
    transform: none !important;
  }

  .ui.multiple.dropdown {
    padding: 0px 5px 0px 0px;
  }

  .ui.multiple.dropdown>.text {
    padding: 6px 0px 5px 10px;
    margin: 0px;
    width: calc(100% - 20px);
  }

  .e-multi-select-wrapper .e-searcher {
    display: block;
    float: none;
    width: auto;
  }

  .Grid {
    overflow-y: auto !important;
  }

  .e-richtexteditor:not(.e-rte-readonly) {
    color: rgb(233, 231, 231) !important;
  }

  .module-container.navigation-container .e-richtexteditor.e-rte-full-screen {
    top: 50px !important;
    height: calc(100% - 50px) !important;
  }

  .module-container.navigation-container.opened .e-richtexteditor.e-rte-full-screen {
    left: 300px !important;
    width: calc(100% - 300px) !important;
  }

  .module-container.navigation-container.closed .e-richtexteditor.e-rte-full-screen {
    left: 30px !important;
    width: calc(100% - 30px) !important;
  }

  .richtext-container {
    border-bottom: 1px solid transparent !important;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0) !important;
    background-position: bottom -1px left 0 !important;
    background-repeat: repeat-x !important;
    background-size: 4px 1px !important;
    resize: both;
  }

  .e-richtexteditor .e-rte-content .e-content,
  .e-richtexteditor .e-source-content .e-content {
    margin-left: 10px;
  }

  .e-rte-content {
    /* overflow-y: hidden !important; */
    overflow-x: hidden !important;
    height: 100% !important;
  }

  .richtext-view .e-rte-content {
    /* overflow-y: hidden !important; */
    overflow-x: hidden !important;
    height: 100% !important;
    /* pointer-events: none !important; */
  }

  .richtext-view .e-rte-toolbar {
    text-align: right !important;
  }

  .ui.form input[type="text"] {
    padding: 3px !important;
    border: none !important;
  }

  .listfield>.form-input-element>span>select {
    display: none;
  }

  .file-upload-field>input {
    padding: 5px 0 0 0 !important;
    border: none !important;
    border-bottom: 1px solid #000 !important;
  }

  .e-datetime-wrapper {
    border-bottom-color: transparent !important;
  }

  .disable-link {
    pointer-events: none;
  }

  .ui.form .form-input-element:not(.structured-list-field) {
    padding: 3px !important;
    border: none !important;
  }

  .richtext-view {
    border: none !important;
    overflow: hidden !important;
  }

  .e-content {
    border: none !important;
    height: 100% !important;
    width: 100% !important;
  }

  .e-tbar-btn {
    background-color: rgb(236, 236, 236) !important;
    height: 25px !important;
  }

  .e-rte-dropdown-popup>ul>.e-item:not(.e-active) {
    background-color: white !important;
  }

  .e-frame.e-check {
    background-color: white !important;
    color: black !important;
    border: 2px solid !important;
    border-radius: 2px !important;
    border-color: #757575 !important;
  }

  .boolean-field {
    margin: 4px 0px 14px 6px;
  }

  .boolean-field>.e-checkbox-wrapper.e-wrapper.e-checkbox-disabled.e-checkbox-disabled>label>.e-icons.e-frame.e-check {
    color: #949494 !important;
  }

  .structured-list-field>.e-treeview .e-list-item.e-active>.e-text-content .e-list-text {
    color: black !important;
  }

  .e-treeview.structured-list-tree .e-list-item.e-hover>.e-fullrow {
    border: none;
    background-color: transparent !important;
  }

  .e-treeview.structured-list-tree .e-list-item.e-active>.e-fullrow {
    border: none;
    background-color: transparent !important;
  }

  .e-list-parent {
    width: 100% !important;
  }

  .link-container-disabled {
    border: none !important;
  }

  .grid-row-outer {
    float: left;
    width: 100%;
  }

  .grid-row-inner {
    /* padding-left: 25px; */
    width: 50%;
  }
`;
