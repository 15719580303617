import request from '../../../request/request';

async function getAuthenticationMode() {
  try {
    const response = await request<any, any>(false, 'POST', 'public/auth/get-mode', {});
    if (response.success) {
      return response.message;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    return { error: true, success: false, message: error.message };
  }
}

export default getAuthenticationMode;
