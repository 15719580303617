import { FilterSettingsModel, IFilter, PageSettingsModel } from '@syncfusion/ej2-react-grids';

export const getSettingsUpdate = (navData) => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editSettings: any = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Dialog'
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customAttributes: any = { class: 'customcss' };
  // infiniteOptions: InfiniteScrollSettingsModel = { enableCache: true };
  const filterMenu: IFilter = {
    type: 'CheckBox',
    operator: 'contains'
  };
  const pageSettings: PageSettingsModel = {
    pageSize: navData?.widgetData?.pageSize ?? '20',
    pageSizes: ['5', '10', '20', '50']
  };

  const pageSettingsInfinite: PageSettingsModel = {
    pageSize: 100
  };


  const filterSettingsMenu: FilterSettingsModel = {
    showFilterBarOperator: true,
    mode: 'Immediate',
    immediateModeDelay: 400
  };
  const filterSettingsExcel: FilterSettingsModel = {
    showFilterBarOperator: true,
    mode: 'Immediate',
    immediateModeDelay: 400,
    type: 'Excel'
  };

  return {
    pageSettingsInfinite,
    filterMenu,
    pageSettings,
    customAttributes,
    filterSettingsMenu,
    editSettings,
    filterSettingsExcel
  };
};