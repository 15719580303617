//import getModulesQuery from "./getModules.query";
import { log } from '../../../utils/notification';
import { runStaticQuery } from '../run.staticQuery';

export const getModules = async () => {
  try {
    return await runStaticQuery('modules', {});
  } catch (error) {
    log('StoredProcedure (alias getModules)', { params: {}, response: { message: error.message } }, 'Error');
  }
};