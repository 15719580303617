export  const onTextSearch = (args, filter, setFilter) => {
  const value = args.value;

  setFilter(prevFilter => {
    const existingFilterIndex = prevFilter.findIndex(element => element.fieldId === filter.id);

    if (value === '' || value.length === 0) {
      return existingFilterIndex !== -1 ? [...prevFilter.slice(0, existingFilterIndex), ...prevFilter.slice(existingFilterIndex + 1)] : prevFilter;
    } else {
      const namesArray = filter.fields.map(field => field.name);
      const updatedFilter = {
        params: { value, fields: namesArray },
        fieldId: filter.id
      };
      return existingFilterIndex !== -1 ? [...prevFilter.slice(0, existingFilterIndex), updatedFilter, ...prevFilter.slice(existingFilterIndex + 1)] : [...prevFilter, updatedFilter];
    }
  });
};
