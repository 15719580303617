export const getToolbarSetting = (field: any, inlineMenuElements: any, t: any) => {
  switch (field.type) {
    case 'markdown':
      return setToolbarSettingsMarkdown(field, inlineMenuElements || [], t);
    case 'richtext':
      return setToolBarSettingsRichtext(field, inlineMenuElements || [], t);
    default:
      return null;
  }

};

const setToolbarSettingsMarkdown = (field, inlineMenuElements, t) => {
  return {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      // {
      //   template: `<button style="width:70px" id="dropdown-extern-${field.attribute.key}">intern</button>`
      // },
      {
        template: `<button style="width:70px" id="dropdown-intern-${field.attribute.key}">extern</button>`
      },
      ...inlineMenuElements.map(element => ({
        template: `<button style="padding-right: 12px;" type="button" class="e-tbar-btn e-control e-btn e-icon-btn" id="menu-inline-button-${element.key}"><span style="padding: 0 6px"  class="e-icons e-${element?.icon?.name}"></span>${t(element.label)}</button>`
      })),
      {
        tooltipText: 'Preview',
        template:
          '<button type="button" class="e-tbar-btn e-btn e-filemanager-btn" id="preview-code" tabindex="-1" style="width:100%"><div class="e-icons e-eye"></div></button>'
      },
      // split editor button is hidden
      {
        tooltipText: 'Split Editor',
        template: '<button type="button" class="e-tbar-btn e-control e-btn e-icon-btn" id="MD_Preview" tabindex="-1" style="width:100%" hidden><div class="e-btn-icon e-view-side e-icons"></div></button>'
      },
      '|',
      'ClearFormat',
      // 'Print',
      'SourceCode',
      'FullScreen',
      'CreateTable',
      '|',
      'Undo',
      'Redo'
    ]
  };
};

export const setToolBarSettingsRichtext = (field, inlineMenuElements, t) => {
  return {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      {
        template: `<button style="width:70px" id="dropdown-extern-${field.attribute.key}">extern</button>`
      },
      // {
      //   template: `<button style="width:70px" id="dropdown-intern-${field.attribute.key}">intern</button>`
      // },
      ...inlineMenuElements.map(element => ({
        template: `<button style="padding-right: 12px;"  type="button" class="e-tbar-btn e-control e-btn e-icon-btn" id="menu-inline-button-${element.key}"><span style="padding: 0 6px"  class="e-icons e-${element?.icon?.name}"></span>${t(element.label)}</button>`
      })),
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      'CreateTable',
      '|',
      'Undo',
      'Redo'
    ]
  };
};
