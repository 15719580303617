export const formFieldAdd = (args, setFields) => {
  setFields((prevFields) => [...prevFields, args]);
};
    
export const formFieldResize = (args, setFields) => {
  setFields((prevFields) => updateArray(prevFields, args, 'field.id'));
};
    
export const formFieldMove = (args, setFields) => {
  setFields((prevFields) => updateArray(prevFields, args, 'field.id'));
};
    
export const formFieldPropertiesChange = (args, setFields) => {
  setFields((prevFields) => updateArray(prevFields, args, 'field.id'));
};
    
export const formFieldRemove = (args, setFields) => {
  setFields((prevFields) => prevFields.filter((element) => element.field.id !== args.field.id));
};
  
const updateArray = (prevArray, args, key) => {
  return prevArray.map((element) => (element[key] === args[key] ? args : element));
};
  