import React, { FC, useContext, useEffect, useRef } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { LoginContext } from '../../../context/Login.context';
import { useSetFormValidator } from './hooks/useSetFormValidator';
import { handleSubmit } from './services/handleSubmit';
import '../../../styles.css';

const ResetDialog: FC<any> = () => {
  const {
    user,
    setUser,
    resetDialogOpen,
    setResetDialogOpen,
    setResetTokenAPI,
    resetToken,
    setResetToken,
    resetTokenAPI,
    setNewPasswordDialogOpen,
  } = useContext(LoginContext);

  useSetFormValidator();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (resetDialogOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [resetDialogOpen]);

  const handleCancel = () => {
    setResetDialogOpen(false);
    setResetToken('');
    setResetTokenAPI('');
  };

  const dialogButtons = [
    {
      click: () =>
        handleSubmit(
          user,
          setResetTokenAPI,
          resetTokenAPI,
          resetToken,
          setResetDialogOpen,
          setNewPasswordDialogOpen
        ),
      buttonModel: {
        iconCss: resetTokenAPI === '' ? 'e-icons e-send-1' : null, 
        content: resetTokenAPI === '' ? 'Send' : 'Confirm',
        cssClass: 'custom-dialog-send-btn'
      },
    },
  ];

  return (
    <DialogComponent
      visible={resetDialogOpen}
      enablePersistence={false}
      width="350px"
      height={'350px'}
      header="Reset Password"
      buttons={dialogButtons}
      showCloseIcon={true}
      close={handleCancel}
    >
      <TextBoxComponent
        disabled={resetTokenAPI !== ''}
        className="login-form-input"
        value={user}
        input={(e) => {
          setUser(e.value);
        }}
        placeholder="Name"
        floatLabelType="Always"
        data-msg-containerid="error-username"
      />
      <div id="error-username" />
      <div
        className="token-input-container"
        style={{ display: resetTokenAPI === '' ? 'none' : 'flex' }}
      >
        <p className="token-input-text">
          A token has been sent to your email. Please enter it here:
        </p>
        <TextBoxComponent
          width={100}
          cssClass="token-input-field"
          value={resetToken}
          floatLabelType="Always"
          htmlAttributes={{ maxlength: '6' }}
          input={(e) => {
            setResetToken(e.value);
          }}
        />
      </div>
    </DialogComponent>
  );
};

export default ResetDialog;
