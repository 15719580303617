import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { log } from 'src/utils/notification';
import { setFormValidator } from '../services/setFormValidator';
import { checkSession } from '../services/checkSession';
import { checkDBConnection } from '../services/checkDBConnection';
import { loadSystem } from '../services/loadSystem';
import packageJson from '../../../../package.json';
import { LoginContext } from '../context/Login.context';

export const useInitializeSession = () => {

  const {
    setAuthMode,
    setCookiesDialogOpen,
    setCookiesContent,
    setPolicyReady,
    userRef,
    setDbConnected,
  } = useContext(LoginContext);


  useEffect(() => {
    const initialize = async () => {
      try {
        await setFormValidator(userRef);
        await checkSession();
        await checkDBConnection(setAuthMode, setDbConnected);
        await loadSystem(setPolicyReady, setCookiesContent, setCookiesDialogOpen);
      } catch (error) {
        log('Request', { response: error.message }, 'Error');
      }

      const expired = JSON.parse(localStorage.getItem('expired'));
      const logout = JSON.parse(localStorage.getItem('logout'));

      localStorage.setItem('version', JSON.stringify(`v${packageJson.version}` || ''));

      if (expired && !logout) {
        toast.warning('Session expired');
      }
      localStorage.removeItem('expired');
      localStorage.removeItem('logout');
    };

    initialize();

    return () => setDbConnected(true);
  }, []);
};
