const types = {
  BADGE_SET_VISIBLE: 'BADGE_SET_VISIBLE',
  BREADCRUMB_ADD_NEW_INSTANCE: 'BREADCRUMB_ADD_NEW_INSTANCE',
  BREADCRUMB_ADD_PAGE: 'BREADCRUMB_ADD_PAGE',
  BREADCRUMB_CLEAR: 'BREADCRUMB_CLEAR',
  BREADCRUMB_GO_BACK: 'BREADCRUMB_GO_BACK',
  BREADCRUMB_SET_GRID_FILTER: 'BREADCRUMB_SET_GRID_FILTER',
  BREADCRUMB_UPDATE_PAGES: 'BREADCRUMB_UPDATE_PAGES',
  CHAT_ADD_POSTS_BOTTOM: 'CHAT_ADD_POSTS_BOTTOM',
  CHAT_ADD_POSTS_TOP: 'CHAT_ADD_POSTS_TOP',
  CHAT_SET_FILTER_MODE: 'CHAT_SET_FILTER_MODE',
  CHAT_SET_OPEN: 'CHAT_SET_OPEN',
  CHAT_SET_POSTS: 'CHAT_SET_POSTS',
  CHAT_SET_VISIBLE: 'CHAT_SET_VISIBLE',
  CLOSE_MULTIUPLOAD_MODAL: 'CLOSE_MULTIUPLOAD_MODAL',
  CLOSE_RICHTEXT_MODAL: 'CLOSE_RICHTEXT_MODAL',
  GET_NAVIGATION: 'GET_NAVIGATION',
  MODULES_SET: 'MODULES_SET',
  MODULES_SET_SELECTED: 'MODULES_SET_SELECTED',
  OPEN_MULTIUPLOAD_MODAL: 'OPEN_MULTIUPLOAD_MODAL',
  OPEN_RICHTEXT_MODAL: 'OPEN_RICHTEXT_MODAL',
  SET_DIALOG_OPEN: 'SET_DIALOG_OPEN',
  SET_MODAL_OPEN: 'SET_MODAL_OPEN',
  SET_TEXT_SELECTION: 'SET_TEXT_SELECTION',
  WIDGETS_REMOVE_MODAL_WIDGET: 'WIDGETS_REMOVE_MODAL_WIDGET',
  WIDGETS_UPDATE: 'WIDGETS_UPDATE',
  WORKSPACE_CLOSE: 'WORKSPACE_CLOSE',
  WORKSPACE_OPEN: 'WORKSPACE_OPEN',
  WORKSPACE_RENAME: 'WORKSPACE_RENAME',
  WORKSPACE_SET_ACTIVE_INDEX: 'WORKSPACE_SET_ACTIVE_INDEX',
  WORKSPACE_SET_IS_NEW: 'WORKSPACE_SET_IS_NEW',
  WORKSPACE_UPDATE_ACTIONS: 'WORKSPACE_UPDATE_ACTIONS',
  WORKSPACE_ACTIONS_PENDING: 'WORKSPACE_ACTIONS_PENDING',
  WORKSPACE_ACTIONS_PINNED: 'WORKSPACE_ACTIONS_PINNED',
  ADD_PINNED_WORKSPACES: 'ADD_PINNED_WORKSPACES',
  WORKSPACE_UPDATE_PLACEMENT: 'WORKSPACE_UPDATE_PLACEMENT',
  BREADCRUMB_SET_SORT_FILTER: 'BREADCRUMB_SET_SORT_FILTER'
};

export default types;
