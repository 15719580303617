import store from '../../store';
import { runWorkflow } from '../../../api/workflow/runWorkflow';
import { setWorkspacePending, updateWorkspaceActions } from './actions';
import { fetchWidgets } from '../widgets/actions';
import { closeMultiUpload } from '../modals/actions';
import { toast } from '../../../utils/notification';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const run = async (key: number, id: number, params: any = {}, files: any = null) => {
  try {
    if (!key) {
      toast.warning('Event is missing');
      return null;
    }

    store.dispatch(setWorkspacePending(true));

    const response = await runWorkflow(key, id, params, files, false);

    store.dispatch(setWorkspacePending(false));

    if (!response) {
      return null;
    }

    store.dispatch(closeMultiUpload({}));

    fetchWidgets(
      response.key,
      response.id
    );
    await store.dispatch(updateWorkspaceActions({ ...response.params, functions: response.functions, timestamp: response.timestamp }));

    return response;
  } catch (error) {
    toast.error('run error:', error.message);
    throw error;
  }
};
