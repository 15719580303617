import { useContext, useEffect } from 'react';
import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { toast } from 'src/utils/notification';
import { GridSortContext } from '../context/Grid.sort.context';

export const useFetchdata = () => {

  const {
    setGridData,
    navData,
    actionsState,
    setGridEditData
  } = useContext(GridSortContext);
    

  useEffect(() => {

    const fetchData = async () => {
      setGridEditData(null);
      if (actionsState) {
        try {
          const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.widgetData.key },navData.widgetData);
          setGridData(response.targets);
        } catch (error) {
          toast.error('Error fetching grid data:', error.message);
          throw error;
        }
      }
    };
  
    fetchData();

    return () => {
      setGridEditData(null);
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);
};
