import { getBreadcrumbItem } from '../../../api/query/static/run.breadcrumb';
import { runStaticQuery } from '../../../api/query/run.staticQuery';
import store from '../../store';
import types from '../types';
import { updatePinnedWorkspaceAPI } from '../../../api/services/workspaces/updateWorkspacePinned';
import { toast } from '../../../utils/notification';

export const addPageBreadcrumbAction = (pageLabel, pageParams, config) => ({
  type: types.BREADCRUMB_ADD_PAGE,
  data: {
    label: pageLabel,
    ...pageParams,
    config,
  }
});
  
export const updateBreadcrumbItems = (historyPagesData, currentPageData) => ({
  type: types.BREADCRUMB_UPDATE_PAGES,
  historyPagesData,
  currentPageData
});


export const setBreadcrumbItems = async (params) => {
  try {
    const response = await getBreadcrumbItem('system', params.key, params.id);
    // const { breadcrumb } = store.getState().workspaces.instances.find(instance => instance.isActive);
    if (
      store.getState().workspaces.instances.find(instance => instance.isActive).breadcrumb?.currentPage?.key !== params.key 
      && (params.key || store.getState().workspaces.instances.find(instance => instance.isActive).breadcrumb?.currentPage?.id !== params.id)
    ) {     
      await store.dispatch(addPageBreadcrumbAction(response.label.name, params, response));

      const nodesIDs = store.getState().workspaces.instances.find(instance => instance.isActive).breadcrumb?.historyPages?.map((element) => element.id) || [];
      const responseNodes = await runStaticQuery('validateNodeIds', { ids: nodesIDs }); 
      const filteredPages = store.getState().workspaces.instances.find(instance => instance.isActive).breadcrumb.historyPages?.filter((x) => !responseNodes?.miss?.includes(x.id)) || [];
      const currentPageData = filteredPages[filteredPages?.length - 1] || []; 
      
      await store.dispatch(updateBreadcrumbItems(filteredPages, currentPageData));
      await updatePinnedWorkspaceAPI();
    }
  } catch (error) {
    toast.error('setBreadcrumbItems:', error.message);
    throw error;
  }
};


export const goBackBreadcrumbAction = (pageIndex) => ({
  type: types.BREADCRUMB_GO_BACK,
  pageIndex
});

  
export const clearBreadcrumb = () => ({
  type: types.BREADCRUMB_CLEAR,
  historyPagesData: [],
  currentPageData: []
});

export const setGridFilterValuesToBreadcrumb = (filterValues, key) => ({
  type: types.BREADCRUMB_SET_GRID_FILTER,
  filterValues,
  key,
});

export const setGridSortValuesToBreadcrumb = (sortValues, key) => ({
  type: types.BREADCRUMB_SET_SORT_FILTER,
  sortValues,
  key,
});