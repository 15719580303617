import styled from 'styled-components';
interface Props {
  css: string;
  height: string;
}

export const GridContainerStyled = styled.div<Props>`
  ${props => props.css}

  height: ${props => props.height ? props.height : '100%'} !important;

  /* .e-grid .e-content {
    height: 100% !important;
  } */

  .e-filterbarcell {
    padding: 0 10px !important;
    height: 32px !important;
  }

  .e-filterbarcell > .e-filterdiv > .e-input-group {
    height: 26px !important;
  }

  .e-grid .e-rowcell.customcss {
    background-color: white;
    cursor: pointer;
  }

  .e-grid .e-headercell.customcss {
    padding: 0 10px !important;
    background-color: hsl(0, 0%, 97%);
    /* height: 35px !important; */
    cursor: pointer;
  }

  .e-grid * {
    font-size: 14px !important;
  }

  .e-pager .e-pagercontainer {
    margin: 5px 6px 5px 7px !important;
    justify-content: center;
  }

  .e-pager .e-currentitem,
  .e-pager .e-currentitem:hover {
    background-color: #f0f0f0 !important;
    color: #000000 !important;
  }

  .e-pager {
    padding-top: 2px !important;
  }

  .e-pager .e-icon-first,
  .e-grid-menu .e-icon-first,
  .e-pager .e-icon-prev,
  .e-grid-menu .e-icon-prev,
  .e-pager .e-icon-next,
  .e-grid-menu .e-icon-next,
  .e-pager .e-icon-last,
  .e-grid-menu .e-icon-last {
    padding-top: 0px !important;
  }

  .e-pager .e-icon-first::before,
  .e-grid-menu .e-icon-first::before {
    content: \ec05 !important;
    vertical-align: -2px !important;
  }

  .e-pager .e-icon-prev::before,
  .e-grid-menu .e-icon-prev::before {
    content: \ec06 !important;
    vertical-align: -2px !important;
  }

  .e-pager .e-icon-next::before,
  .e-grid-menu .e-icon-next::before {
    content: \ec07 !important;
    vertical-align: -2px !important;
  }

  .e-pager .e-icon-last::before,
  .e-grid-menu .e-icon-last::before {
    content: \ec08 !important;
    vertical-align: -2px !important;
  }

  .e-pager .e-pagerdropdown {
    margin-top: -36px !important;
    width: 50px !important;
    height: 25px !important;
  }

  .e-pager .e-pagerdropdown .e-alldrop {
    width: 50px !important;
    height: 23px !important;
  }

  .e-pager .e-pagerconstant {
    margin: 0px 0 10px 7px !important;
  }

  .e-row:hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    background-color: #f0f0f0 !important;
    color: #000000 !important;
  }

  .disablecheckbox {
    pointer-events: none;
    opacity: 0.5;
  }

  .customcss .e-checkbox-wrapper .e-frame.e-icons.e-check {
    background-color: white !important;
    color: black !important;
    border: solid 1px;
  }

  .e-grid td.e-cellselectionbackground {
    background-color: #f0f0f0 !important;
  }

  .e-pager .e-pagercontainer .e-firstpage,
  .e-pager .e-pagercontainer .e-prevpage,
  .e-pager .e-pagercontainer .e-firstpagedisabled,
  .e-pager .e-pagercontainer .e-prevpagedisabled,
  .e-pager .e-pagercontainer .e-nextpage,
  .e-pager .e-pagercontainer .e-lastpage,
  .e-pager .e-pagercontainer .e-nextpagedisabled,
  .e-pager .e-pagercontainer .e-lastpagedisabled {
    margin-right: 0px !important;
  }

  .e-pager div.e-parentmsgbar {
    padding-top: 10px !important;
  }

  .e-grid.e-link-grid .e-gridheader tr th:first-child {
    pointer-events: none;
  }

  .e-grid.e-link-grid .e-gridheader tr th:first-child div {
    display: none;
  }

  .grid-item-counter {
    margin-top: -16px;
  }

  .grid-item-counter > .e-gridpager.e-control.e-pager.e-lib {
    opacity: unset;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: solid 2px #e0e0e0;
    border-bottom: solid 2px #e0e0e0;
    border-right: solid 2px #e0e0e0;
  }

  .grid-item-counter > .e-gridpager.e-control.e-pager.e-lib > .e-parentmsgbar {
    font-size: 15px;
    padding-bottom: 15px;
  }

  .footer-template {
    display: flex;
    flex-direction: column; 
  }

  .footer-template-aggregation {
    margin-top: 5px;
    font-weight: bold;
  }

  .e-checkbox-wrapper {
    opacity: 0.2 !important;
  }

  .e-rowcell > .e-checkbox-wrapper {
    opacity: 1 !important;
  }

  .e-checkbox-wrapper > .e-check {
    background-color: white !important;
    color: black !important;
    border: 2px solid !important;
    border-radius: 2px !important;
    border-color: #757575 !important;
  }  
`;
