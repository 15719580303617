/* eslint-disable @typescript-eslint/no-unused-vars */
const contextTap = async (cy: any, selectedElements: any) => {
  return await cy.on('cxttap', 'node', async function (e) {
    const element = e.target;

    if (!element.hasClass('selected')) {
      localStorage.setItem('selectedElements', JSON.stringify([]));
      cy.nodes('.selected').removeClass('selected');
      cy.edges('.selected').removeClass('selected');
    }
    
    const selectedElements = JSON.parse(localStorage.getItem('selectedElements')) || [];

    if(selectedElements.length !== 0) return;

    element.addClass('selected');
    
    cy.elements()
      .difference(element)
      .removeClass('selected');
  });
};

export default contextTap;
