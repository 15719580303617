import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const KanbanContext = createContext(null);

export const KanbanProvider = ({ children, commonProps }) => {

  const kanbanRef = useRef(null); 

  const [kanbanData, setKanbanData] = useState(null);
  const [columnWidth, setColumnWidth] = useState(300);
  const [filter, setFilter] = useState([]);

  const [currentWorkspaceUuid, setCurrentWorkspaceUuid] = useState(null);

  
    
  return (
    <KanbanContext.Provider
      value={{
        kanbanRef,
        kanbanData,
        setKanbanData,
        columnWidth,
        setColumnWidth,
        filter,
        setFilter,
        currentWorkspaceUuid,
        setCurrentWorkspaceUuid,
        ...commonProps
      }}
    >
      {children}
    </KanbanContext.Provider>
  );
};

KanbanProvider.propTypes = {
  children: PropTypes.node.isRequired,
  commonProps: PropTypes.object
};
