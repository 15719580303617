import { FormValidator } from '@syncfusion/ej2-inputs';

export const setFormValidator = async(userNameRef) => {
  userNameRef.current.focusIn();
  const options = {
    rules: {
      username: {
        required: [true, '* Please enter your name'],
      },
      password: {
        required: [true, '* Please enter your password'],
      },
    },
  };
  new FormValidator('#form', options);
};