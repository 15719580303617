import { log } from '../../../utils/notification';
import { runStaticQuery } from '../run.staticQuery';

export const getBreadcrumbItem = async (storeName: string, key: number, id: number) => {

  try {
    const parameters = { key: key, id: id };
    return runStaticQuery('breadcrumb', parameters);
  } catch (error) {
    log('StoredProcedure (alias: getBreadcrumbItem)', { params: { key: key, id: id }, response: { message: error.message } }, 'Error');
  }
};